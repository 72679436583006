import MuiSnackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";

import { SnackbarProps } from "./type";
import { useSnackbar } from "./useSnackbar";

export const Snackbar: React.FC<SnackbarProps> = (props) => {
  const { severity, message, onClose = () => {} } = props;
  const [open, setOpen] = useState(false);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  const handleOnClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <MuiSnackbar
      className={"mb-16 md:mb-0"}
      style={{ zIndex: 99999, display: !!message ? "flex" : "none" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={open}
      autoHideDuration={5000}
      onClose={handleOnClose}
      TransitionProps={{ onExited: () => openSnackbar({}) }}
    >
      <Alert
        variant="filled"
        severity={severity}
        elevation={6}
        onClose={handleOnClose}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
