export const LoadingSpinner = ({ svgClassName }: { svgClassName?: string }) => {
  return (
    <svg
      width="66"
      height="65"
      viewBox="0 0 66 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={svgClassName}
    >
      <g id="Group 403">
        <g id="Group 337">
          <circle
            id="Ellipse 36"
            cx="33"
            cy="32.5"
            r="30"
            stroke="#E5E7EB"
            strokeWidth="5"
          />
        </g>
        <g id="loading-indicator" className="animate-spin origin-center">
          <path
            id="Ellipse 36_2"
            d="M63 32.5C63 15.9315 49.5685 2.5 33 2.5"
            stroke="#0AB4B1"
            strokeWidth="5"
          />
        </g>
      </g>
    </svg>
  );
};
