export enum RouteName {
  Home = "/home",
  ContentSelection = "/content-selection",
  ApplicationCreator = "/application/creator",
  Landing = "/",
  MySettingsAccount = "/settings/account",
  MySettingsProfile = "/settings/profile",
  MySettingsCreatorType = "/settings/creatorType",
  MySettingsChannel = "/settings/channel",
  SubscriptionPlanRoute = "/subscription-plans/[page]",
  SubscriptionPlansSetting = "/subscription-plans/setting",
  SubscriptionPlansSubscribers = "/subscription-plans/subscribers",
  PayoutMethods = "/payout/methods",
  PayoutRecords = "/payout/records",
  CollectibleMe = "/collectible/me",
  CollectiblePurchased = "/collectible/purchased",
  CollectibleFavourites = "/collectible/favourites",
  CollectibleRoyalty = "/collectible/royalty-payments",
  PaymentMethods = "/payment/methods",
  PaymentRecords = "/payment/records",
  MySubscription = "/my-subscription",
  MyFollowingUser = "/my-following/user",
  MyAudienceUser = "/my-audience/user",
  ProfileUser = "/@",
  NewPost = "/new-post",
  NewPostRoute = "/new-post/[slug]",
  Post = "/post",
  Tag = "/tag",
  Tags = "/tags",
  PrivacyPolicy = "/privacy-policy",
  TermsOfUse = "/terms-of-use",
  PostDraft = "/draft",
  Channel = "/channel",
  Message = "/message",
  PostRoute = "/post/[slug]",
  ProfileUserRoute = "/[accountName]",
  ChannelRoute = "/[accountName]/channel/[slug]",
  ProfileCollectiblesRoute = "/[accountName]/collectibles",
  MySubscriptionRoute = "/my-subscription/[page]",
  PaymentRoute = "/payment/[page]",
  PayoutRoute = "/payout/[page]",
  CollectibleRoute = "/collectible/[page]",
  Search = "/search",
  Faq = "/faq",
  OfflinePayment = "/offline-payment",
  TipsInvoice = "/tips/invoices",
  TipsRecords = "/tips/records",
}
