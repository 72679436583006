import * as Sentry from "@sentry/nextjs";
import { httpBatchLink, TRPCClientError } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";

import type { AppRouter } from "@/server/trpc/routers/_app";

function getBaseUrl() {
  if (typeof window !== "undefined") return "";
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;

  return `http://localhost:3080`;
}

export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    return {
      links: [
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            onError: (error: TRPCClientError<AppRouter>) => {
              const { code, path } = error.data ?? {};

              Sentry.captureException(error, {
                tags: {
                  fn: "trpc.queries.error",
                  errorCode: code,
                  queryPath: path,
                  i18nKey: error.message,
                },
              });
            },
          },
        },
      },
    };
  },
  ssr: true,
});
