import { FieldMergeFunction } from "@apollo/client";

import { ChatPage } from "@/generated/generated-hooks";

export const chatPageMergeFn: FieldMergeFunction<ChatPage> = (
  existing,
  incoming,
  { args, readField },
) => {
  const existingItems = existing?.items ?? [];
  const incomingItems = incoming?.items ?? [];

  const existingItemIds = existingItems.map((item) => readField("_id", item));

  const distinctItems = incomingItems.filter(
    (item) => !existingItemIds.includes(item._id),
  );

  const mergedItems = [...existingItems, ...distinctItems];

  return {
    ...incoming,
    items: mergedItems,
  };
};
