import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import debounce from "lodash.debounce";
import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { RouteName } from "@/consts/RouteName";

import { isMdDown, useBreakpoint } from "../useBreakpoint";

type MasonryMode = {
  mode: "grid" | "column" | "desktop";
  scrollToIndex?: { index: number; align: "top" | "center" | "bottom" };
};

type RenderIndex = {
  startIndex: number;
  stopIndex: number;
};

type MasonryContextProps = MasonryMode & { renderIndex?: RenderIndex } & {
  setMasonry?: React.Dispatch<React.SetStateAction<MasonryMode>>;
  settings?: Record<string, number>;
  onRender?: (startIndex: number, stopIndex: number, items: any[]) => void;
  onClickGridItem?: (index: number) => () => void;
  gridModeRoute?: RouteName[];
};

const MasonryContext = createContext<MasonryContextProps>({ mode: "desktop" });

export function MasonryProvider(props) {
  const [masonry, setMasonry] = useState<MasonryMode>({ mode: "desktop" });
  const [renderIndex, setRenderIndex] = useState<RenderIndex>(undefined);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();
  const isRenderingOnProfilePage = router.route === RouteName.CollectibleRoute;

  const breakpoint = useBreakpoint();
  const mdDown = isMdDown(breakpoint);

  const gridModeRoute = useMemo(
    () => [
      RouteName.ProfileUserRoute,
      RouteName.ChannelRoute,
      RouteName.ProfileCollectiblesRoute,
    ],
    [],
  );

  useEffect(() => {
    if (mdUp) {
      return setMasonry({ mode: "desktop", scrollToIndex: undefined });
    }
    if (smDown) {
      if (gridModeRoute.includes(router.route as RouteName))
        return setMasonry({ mode: "grid", scrollToIndex: undefined });
      return setMasonry({ mode: "column", scrollToIndex: undefined });
    }
  }, [mdUp, smDown, router.route, gridModeRoute]);

  const settings = useMemo(() => {
    if (masonry.mode === "grid") {
      return {
        columnCount: 3,
        columnGutter: 1,
        itemHeightEstimate: 130,
        overscanBy: 2,
        scrollFps: 10,
      };
    }

    return {
      columnCount: mdDown ? 1 : 4,
      columnGutter: 12,
      columnWidth:
        !(masonry.mode === "desktop") || isRenderingOnProfilePage ? 250 : 350,
      itemHeightEstimate: 400,
      overscanBy: 2,
      scrollFps: 24,
    };
  }, [mdDown, masonry.mode, isRenderingOnProfilePage]);

  const onRender = useCallback(
    () =>
      debounce((startIndex: number, stopIndex: number, items: any[]) => {
        if (
          startIndex !== renderIndex?.startIndex ||
          stopIndex !== renderIndex?.stopIndex
        ) {
          setRenderIndex({ startIndex, stopIndex });
        }
      }, 200),
    [renderIndex?.startIndex, renderIndex?.stopIndex],
  );

  const onClickGridItem = useCallback(
    (index: number) => () => {
      setRenderIndex(undefined);
      setMasonry({
        mode: "column",
        scrollToIndex: { index, align: "center" },
      });
    },
    [],
  );

  return (
    <MasonryContext.Provider
      value={{
        ...masonry,
        setMasonry,
        renderIndex,
        settings,
        onRender: onRender(),
        onClickGridItem,
        gridModeRoute,
      }}
      {...props}
    />
  );
}
export function useMasonry() {
  return useContext(MasonryContext);
}
