import algoliasearch, { SearchClient } from "algoliasearch/lite";
import { createContext, FC, useContext } from "react";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const {
  service: { algolia },
} = getPublicConfig();

const algoliaClient = algoliasearch(algolia.appId, algolia.searchApiKey);

/* https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/ */
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (
      requests.every(({ query }) => !query) ||
      typeof window === "undefined"
    ) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
} as SearchClient;

const AlgoliaContext = createContext<SearchClient>(searchClient);

export const AlgoliaProvider: FC = (props) => {
  return (
    <AlgoliaContext.Provider value={searchClient}>
      {props.children}
    </AlgoliaContext.Provider>
  );
};

export const useAlgoliaClient = () => useContext(AlgoliaContext);
