import { useTranslation } from "next-i18next";
import { useEffect } from "react";

import { useSnackbar } from "@/features/common/hooks/snackbar/useSnackbar";
import { useFeatureEnabled } from "@/features/common/hooks/useFeatureEnabled";
import { useHandleGraphqlError } from "@/features/common/hooks/useHandleGraphqlError";
import { useNftContext } from "@/features/nft/hooks/useNft";
import { useUserContext } from "@/features/user/contexts/useUserContext";
import {
  FeatureNameEnum,
  useLinkWalletAccountMutation,
} from "@/generated/generated-hooks";

export const LinkWalletAccount = () => {
  const { activeAccount } = useNftContext();
  const { user } = useUserContext();
  const [linkAccount] = useLinkWalletAccountMutation();
  const { isEnabled } = useFeatureEnabled();
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation("common");
  const isNftEnabled = isEnabled(FeatureNameEnum.Nft);
  const handleGraphqlError = useHandleGraphqlError();

  useEffect(() => {
    if (!isNftEnabled) return;

    if (!activeAccount || !user) return;

    /* same wallet account */
    if (!!user.walletAccount && user.walletAccount === activeAccount) return;

    /* different wallet account detected */
    if (!!user.walletAccount && user.walletAccount !== activeAccount)
      return openSnackbar({
        message: t("common:linkWallet.walletMismatch"),
        severity: "error",
      });

    /* no any wallet account is linked to user */

    const link = async () => {
      try {
        await linkAccount({ variables: { walletAccount: activeAccount } });
      } catch (e) {
        handleGraphqlError({
          e,
          t,
          defaultErrorTranslationKey: "common:linkWallet.error.fail",
        });
      }
    };

    link();
  }, [
    activeAccount,
    user,
    linkAccount,
    openSnackbar,
    t,
    isNftEnabled,
    handleGraphqlError,
  ]);

  return null;
};
