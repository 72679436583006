import { FieldMergeFunction } from "@apollo/client";

import {
  FollowChannelDocDocument,
  FollowChannelPage,
} from "@/generated/generated-hooks";

export const followChannelPageMergeFn: FieldMergeFunction<FollowChannelPage> = (
  existing,
  incoming,
  { cache, readField, args },
) => {
  const existingItems = existing?.items ?? [];
  const incomingItems = incoming?.items ?? [];

  const mergedItems = [...existingItems, ...incomingItems];

  if (!!args?.input?.userId) {
    incomingItems.forEach((element) => {
      const channelId = readField("channelId", element);
      const userId = readField("userId", element);
      cache.writeQuery({
        query: FollowChannelDocDocument,
        variables: { input: channelId },
        data: {
          followChannelDoc: {
            channelId,
            userId,
            __typename: "FollowChannel",
          },
        },
      });
    });
  }

  return { ...incoming, items: mergedItems };
};
