import { NextSeoProps } from "next-seo";
import getConfig from "next/config";

type FirebaseInitialization = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  appId: string;
  storageBucket: string;
};

type Price = Record<"hkd" | "usd", { max: number; min: number }>;
type CryptoPrice = Record<"eth", { max: number; min?: number }>;

type SEO = {
  seo: { defaultConfig: NextSeoProps };
};

type SNS = Record<"facebook" | "instagram", string>;

type Payout = Price;

type Channel = {
  limit: number;
};

type PaymentMethod = {
  limit: number;
  fps: string;
  fpsAccount: string;
  payme: string;
  paymeAccount: string;
};

type VideoPost = Record<"maxDurationInSecond" | "maxVideoSizeInByte", number>;

type Image = Record<
  | "compressionRatio"
  | "convertSize"
  | "maxHeight"
  | "maxWidth"
  | "defaultMaxDropzoneFiles"
  | "defaultMaxUploadFiles"
  | "singleMaxUploadFile",
  number
>;

type Charge = Record<
  | "tipsFee"
  | "stripe"
  | "stripeUsdBase"
  | "custom"
  | "proCreatorFee"
  | "royalty",
  number
>;

type MetaMask = Record<"chainId", string>;

type Etherscan = Record<"mainnet" | "rinkeby", string>;

type EthereumContractAddressMap = Record<"eth721" | "marketplace", string>;

type LogFlareInitialization = Record<"apiKey" | "sourceToken", string>;

type AlgoliaIndices = Record<"post" | "user" | "channel" | "tag", string>;
type AlgoliaSearchParams = Record<"menuHitsPerPage" | "maxFacetHits", number>;
type Algolia = Record<"appId" | "searchApiKey", string> & {
  indices: AlgoliaIndices;
  searchParams: AlgoliaSearchParams;
};

type ENV = Record<"isLocal" | "isPreview" | "isProduction" | "isCI", boolean> &
  Record<"domain" | "version" | "vercelEnv" | "vercelUrl", string>;

type Project = Record<
  "siteName" | "projectSlug" | "version" | "domain",
  string
>;

export type Config = {
  customerService: {
    email: string;
  };
  timezone: string;
  charge: Charge;
  sns: SNS;
  payout: Payout;
  enabledPasswordProtection: boolean;

  /** @deprecated use siteName in project */
  siteName: string;
  /** @deprecated use projectSlug in project */
  projectSlug: string;
  /** @deprecated use domain in env */
  domain: string;
  /** @deprecated use vercelEnv in env */
  vercelEnv: string;
  /** @deprecated use vercelUrl in env */
  vercelUrl: string;
  /** @deprecated use version in project */
  version: string;
  /** @deprecated use isPreview in env */
  isPreview: boolean;
  /** @deprecated use isLocal in env */
  isLocal: boolean;
  /** @deprecated use isPreview in env */
  isFeatureBranch: boolean;
  /** @deprecated use isPreview in env */
  isPreviewBranch: boolean;
  /** @deprecated use isProduction in env */
  isProductionBranch: boolean;
  isPublished: boolean;
  /** @deprecated use isProduction in env */
  isProduction: boolean;
  /** @deprecated use isCI in env */
  isCI: boolean;
  defaultPageSize: number;
  defaultDateFormat: string;
  fileExpireTimeInMin: number;
  image: Image;
  blurIndices: number[];
  channel: Channel;
  paymentMethod: PaymentMethod;
  toFixedDigits: number;
  dateFormat: string;

  env: ENV;
  project: Project;

  tags: {
    limit: number;
    wordsLimit: number;
  };

  features: {
    fetchInterval: number;

    notionPage: {
      faq: { en: string; zh: string };
      privacyPolicy: { en: string; zh: string };
      termsOfUse: { en: string; zh: string };
    };

    log: {
      level: string;
    };
    post: {
      editableInDays: number;
      image: {
        convertSize: number;
        maxHeight: number;
        maxWidth: number;
      };
      video: { free: VideoPost; paid: VideoPost };
    };
    offlinePayment: {
      creditPriceInHkd: number;
    };
  };

  service: {
    cloudinary: {
      cloudName: string;
      apiKey: string;
    };
    algolia: Algolia;
    datadog: { clientToken: string };
    logFlare: LogFlareInitialization;
    etherscan: Etherscan;
    metaMask: MetaMask;
    infuraNetwork: string;
    ethereum: {
      contractAddress: EthereumContractAddressMap;
      priceLimit: CryptoPrice;
    };
    openSea: {
      contractAddress: string;
      priceLimit: CryptoPrice;
    };

    firebase: {
      initialization: FirebaseInitialization;
    };
    sentry: {
      dsn: string;
    };
    stripe: {
      priceLimit: Price;
    };
    stripe2: {
      publishableKey: string;
    };
    vimeo: {
      clientId: string;
      secretKey: string;
      accessToken: string;
    };
  };
} & SEO;

const { publicRuntimeConfig } = getConfig() ?? {};

export const getPublicConfig = (): Config => publicRuntimeConfig ?? {};
