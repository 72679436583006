import { createBreakpoint } from "react-use";

export const useBreakpoint = createBreakpoint({
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
});

export const isMdDown = (breakpoint: string) => {
  return breakpoint === "xs" || breakpoint === "sm";
};
