import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import add from "date-fns/add";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import endOfMonth from "date-fns/endOfMonth";
import isThisMonth from "date-fns/isThisMonth";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfMonth from "date-fns/startOfMonth";
import subMonths from "date-fns/subMonths";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const { timezone } = getPublicConfig();

export const utcToTargetZonedTime = (date: Date) => {
  return utcToZonedTime(date, timezone);
};

export const differenceInMonthsTz = (firstDate: Date, secondDate: Date) => {
  const zonedFirstDate = utcToZonedTime(firstDate, timezone);
  const zonedSecondDate = utcToZonedTime(secondDate, timezone);
  return differenceInMonths(zonedFirstDate, zonedSecondDate);
};

export const isThisMonthTz = (date: Date) => {
  const zonedTime = utcToZonedTime(date, timezone);
  return isThisMonth(zonedTime);
};

export const addMonthsTz = (date: Date, amount: number) => {
  const zonedTime = utcToZonedTime(date, timezone);
  const lastMonthTime = addMonths(zonedTime, amount);
  const utcTime = zonedTimeToUtc(lastMonthTime, timezone);
  return utcTime;
};

export const subMonthsTz = (date: Date, amount: number) => {
  const zonedTime = utcToZonedTime(date, timezone);
  const lastMonthTime = subMonths(zonedTime, amount);
  const utcTime = zonedTimeToUtc(lastMonthTime, timezone);
  return utcTime;
};

export const isWithInIntervalDays = (start: string, days: number) => {
  const startDate = new Date(start);
  const endDate = add(new Date(start), {
    days,
  });

  return isWithinInterval(new Date(), {
    start: startDate,
    end: endDate,
  });
};

export const getStartAndEndOfMonth = (date: Date) => {
  const zonedTime = utcToZonedTime(date, timezone);
  const zonedTimeStartOfMonth = startOfMonth(zonedTime);
  const zonedTimeEndOfMonth = endOfMonth(zonedTime);
  const startAt = zonedTimeToUtc(zonedTimeStartOfMonth, timezone);
  const endAt = zonedTimeToUtc(zonedTimeEndOfMonth, timezone);

  return {
    startAt,
    endAt,
  };
};

export const convertDateToString = (date: string | Date) => {
  if (date instanceof Date) return date.toISOString();
  return date;
};
