import React from "react";

export const Square: React.FC = (props) => {
  return (
    <div
      className="relative w-full h-0 overflow-hidden"
      style={{ paddingTop: "100%" }}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0">
        {props.children}
      </div>
    </div>
  );
};
