import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import firebase from "firebase/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { removeLocaleCookie } from "@/helpers/user/locale/localeCookies";
import { mapUserData } from "@/services/auth/mapUserData";
import { removeUserCookie, setUserCookie } from "@/services/auth/userCookies";

// Handle user in Firebase and Cookies
const useUser = () => {
  const [user, setUser] = useState<any>();
  const apolloClient = useApolloClient();
  const [onIdTokenChanged, setOnIdTokenChanged] = useState(false);
  const router = useRouter();

  const removeCachedData = async () => {
    removeUserCookie();
    removeLocaleCookie();
    setUser(undefined);
    setOnIdTokenChanged(true);
  };

  const signOut = async () => {
    setOnIdTokenChanged(false);
    return firebase
      .auth()
      .signOut()
      .then(async () => {
        // Sign-out successful.
        await apolloClient.cache.reset();
        router.push("/");
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };

  useEffect(() => {
    // Firebase updates the id token every hour, this
    // makes sure the react state and the cookie are
    // both kept up to date

    firebase.auth().onIdTokenChanged(
      async (user) => {
        if (user) {
          const userData = await mapUserData(user);
          if (!userData) return;

          setUserCookie(userData);
          setUser(userData);
          setOnIdTokenChanged(true);
        } else {
          removeCachedData();
        }
      },
      (e) => {
        Sentry.captureException(e);
      },
    );
  }, []);

  return {
    user,
    signOut,
    onIdTokenChanged,
  };
};

export { useUser };
