import { FieldMergeFunction } from "@apollo/client";

import { NotificationPage } from "@/generated/generated-hooks";

export const notificationPageMergeFn: FieldMergeFunction<NotificationPage> = (
  existing,
  incoming,
  { args },
) => {
  const existingItems = existing?.items ?? [];
  const incomingItems = incoming?.items ?? [];

  const mergedItems = [...existingItems, ...incomingItems];

  return {
    count: incoming.count ?? existing.count,
    ...incoming,
    items: mergedItems,
  };
};
