import { FieldMergeFunction } from "@apollo/client";

import {
  FollowUserDocDocument,
  FollowUserPage,
} from "@/generated/generated-hooks";

export const followUserPageMergeFn: FieldMergeFunction<FollowUserPage> = (
  existing,
  incoming,
  { cache, readField, args },
) => {
  const existingItems = existing?.items ?? [];
  const incomingItems = incoming?.items ?? [];

  const mergedItems = [...existingItems, ...incomingItems];

  if (!!args?.userId) {
    incomingItems.forEach((element) => {
      const userId = readField("userId", element);
      const writerId = readField("writerId", element);
      cache.writeQuery({
        query: FollowUserDocDocument,
        variables: { input: writerId },
        data: {
          followUserDoc: {
            writerId,
            userId,
            __typename: "FollowUser",
          },
        },
      });
    });
  }

  return { ...incoming, items: mergedItems };
};
