import {
  EnabledFeaturesQuery,
  FeatureNameEnum,
  useEnabledFeaturesQuery,
} from "@/generated/generated-hooks";
import { getPublicConfig } from "@/helpers/getPublicConfig";

export const useFeatureEnabled = () => {
  const { data, loading } = useEnabledFeaturesQuery();

  const isEnabled = (featureName: FeatureNameEnum) =>
    data?.enabledFeatures.includes(featureName);

  return { isEnabled, loading };
};

/* used in SSR */
export const isFeatureEnabled = (
  data: EnabledFeaturesQuery,
  featureName: FeatureNameEnum,
) => data?.enabledFeatures.includes(featureName);
