import MetaMaskOnboarding from "@metamask/onboarding";
import * as Sentry from "@sentry/nextjs";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useRef, useState } from "react";

import { useSnackbar } from "@/features/common/hooks/snackbar/useSnackbar";
import { getPublicConfig } from "@/helpers/getPublicConfig";

interface ConnectInfo {
  chainId: string;
}

export const useMetaMaskWallet = () => {
  const isMetaMaskInstalled =
    typeof window === "undefined"
      ? false
      : MetaMaskOnboarding.isMetaMaskInstalled();

  const { activeAccount, isAccountRequested, handleAccountsChanged } =
    useMetaMaskAccount(isMetaMaskInstalled);

  const { installMetaMask, connectMetaMask, loading } = useMetaMaskOnboarding({
    isMetaMaskInstalled,
    activeAccount,
    handleAccountsChanged,
  });

  const isSameNetwork = useMetaMaskNetwork(isMetaMaskInstalled);

  return {
    activeAccount,
    isSameNetwork,
    isAccountRequested,
    loading,
    installMetaMask,
    connectMetaMask,
    isMetaMaskInstalled,
  };
};

const useMetaMaskAccount = (isMetaMaskInstalled: boolean) => {
  const [activeAccount, setActiveAccount] = useState<string>(undefined);
  const [isAccountRequested, setIsAccountRequested] = useState(false);

  const handleAccountsChanged = useCallback((accounts: string[]) => {
    /* if not connected, accounts will be an empty array */
    setActiveAccount(accounts[0]);
    setIsAccountRequested(true);
  }, []);
  /* accounts related */
  useEffect(() => {
    if (!isMetaMaskInstalled) return;

    const { ethereum } = window as any;

    /* getAccounts */
    ethereum
      .request({ method: "eth_accounts" })
      .then(handleAccountsChanged)
      .catch((err) => {
        Sentry.captureException(err, { tags: { metaMask: "eth_accounts" } });
      });

    /* listen to account change */
    ethereum.on("accountsChanged", handleAccountsChanged);
    return () => {
      ethereum.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, [isMetaMaskInstalled, handleAccountsChanged]);

  return { activeAccount, isAccountRequested, handleAccountsChanged };
};

const useMetaMaskOnboarding = ({
  isMetaMaskInstalled,
  activeAccount,
  handleAccountsChanged,
}: {
  isMetaMaskInstalled: boolean;
  activeAccount: string;
  handleAccountsChanged: (accounts: string[]) => void;
}) => {
  const { t } = useTranslation("common");
  const onboarding = useRef<MetaMaskOnboarding>();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();

  const installMetaMask = () => {
    if (isMetaMaskInstalled) return;
    setLoading(true);
    onboarding.current.startOnboarding();
  };

  const connectMetaMask = (onFinally: () => void) => async () => {
    setLoading(true);
    if (!isMetaMaskInstalled) return;
    const { ethereum } = window as any;
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      handleAccountsChanged(accounts);
    } catch (err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        openSnackbar({
          message: t("common:nft.rejectConnect"),
          severity: "error",
        });
      } else {
        Sentry.captureException(err, { tags: { metaMask: "connect" } });
      }
    } finally {
      setLoading(false);
      onFinally();
    }
  };

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  /* onboarding related */
  useEffect(() => {
    if (!isMetaMaskInstalled) return;
    if (activeAccount) onboarding.current.stopOnboarding();
  }, [activeAccount, isMetaMaskInstalled]);

  return { installMetaMask, connectMetaMask, loading };
};

const useMetaMaskNetwork = (isMetaMaskInstalled: boolean) => {
  const {
    service: { metaMask },
  } = getPublicConfig();

  const [isSameNetwork, setIsSameNetwork] = useState(true);

  useEffect(() => {
    if (!isMetaMaskInstalled) return;
    const { ethereum } = window as any;

    if (ethereum.isConnected() && ethereum.chainId !== metaMask.chainId)
      setIsSameNetwork(false);

    const onChainChanged = (chainId) => {
      window.location.reload();
    };

    const onConnect = (connectInfo: ConnectInfo) => {
      if (connectInfo.chainId !== metaMask.chainId) setIsSameNetwork(false);
    };

    ethereum.on("chainChanged", onChainChanged);
    ethereum.on("connect", onConnect);
    return () => {
      ethereum.removeListener("chainChanged", onChainChanged);
      ethereum.removeListener("connect", onConnect);
    };
  }, [metaMask.chainId, isMetaMaskInstalled]);

  return isSameNetwork;
};
