import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types";
import { TFunction, useTranslation } from "next-i18next";
import { useCallback } from "react";

import { useSnackbar } from "@/features/common/hooks/snackbar/useSnackbar";

export const useHandleGraphqlError = () => {
  const { t: tBase } = useTranslation("common");
  const { openSnackbar } = useSnackbar();

  const handleGraphqlError = useCallback(
    ({
      e,
      t,
      sentryCaptureContext,
      defaultErrorTranslationKey,
    }: {
      e: ApolloError;
      t?: TFunction;
      sentryCaptureContext?: CaptureContext;
      defaultErrorTranslationKey?: string;
    }) => {
      const graphqlErrorExtensions = (e?.graphQLErrors ?? [])[0]?.extensions;

      Sentry.captureException(e, sentryCaptureContext);
      openSnackbar({
        message: (t ?? tBase)(
          graphqlErrorExtensions?.i18nKey ??
            defaultErrorTranslationKey ??
            "common:errors.fail",
          { ...(graphqlErrorExtensions ?? {}) },
        ),
        severity: "error",
      });
    },
    [openSnackbar, tBase],
  );

  return handleGraphqlError;
};
