import XIcon from "@heroicons/react/outline/XIcon";
import clsx from "clsx";

type DialogCloseButtonProps = {
  iconClassName?: string;
  loading?: boolean;
} & React.HTMLProps<HTMLButtonElement>;

export const DialogCloseButton = ({
  onClick,
  loading,
  disabled,
  iconClassName = "w-5 h-5",
  className,
}: DialogCloseButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        className,
        "focus:outline-none disabled:cursor-not-allowed touchable",
      )}
      disabled={loading || disabled}
    >
      <XIcon className={iconClassName} />
    </button>
  );
};
