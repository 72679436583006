import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const config = getPublicConfig();

if (!firebase.apps.length) {
  firebase.initializeApp(config.service.firebase.initialization);
  if (typeof window !== "undefined") {
    firebase.analytics();
  }
}
