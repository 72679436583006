import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "next-i18next";
import React, { Fragment, ReactNode, useRef } from "react";

import { LoadingSpinner } from "@/features/common/components/animation/LoadingSpinner";

/* TODO: Can remove this component together with opensea related components, replaced by StepsDialogView */
type StepProps = {
  title: string | ReactNode;
  description: string | ReactNode;
  status: "inProgress" | "done" | "pending";
};

export type StepsDialogProps = {
  open: boolean;
  onClose: () => void;
  steps: Omit<StepProps, "status">[];
  currentStep: number;
};

/**
 * @param currentStep currentStep start from 0
 */
export const StepsDialog = ({
  open,
  onClose,
  steps,
  currentStep,
}: StepsDialogProps) => {
  const { t } = useTranslation("common");
  let refDiv = useRef(null);

  const getStepStatus = (stepIndex): StepProps["status"] => {
    if (stepIndex === currentStep) return "inProgress";
    if (stepIndex > currentStep) return "pending";
    return "done";
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={refDiv}
        className="fixed inset-0 z-modal overflow-y-auto"
        onClose={() => {}}
      >
        <div className="min-h-screen px-4 text-center" ref={refDiv}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-80 z-[-1]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-md">
              <Dialog.Title
                as="h3"
                className="flex justify-between text-xl text-white leading-7 bg-primary px-6 py-2"
              >
                <>{t("common:followSteps")}</>
              </Dialog.Title>
              <div className="px-6 divide-y divide-gray-100">
                {steps.map((props, idx) => {
                  const status = getStepStatus(idx);
                  return <Step {...props} key={idx} status={status} />;
                })}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const Step = ({ title, description, status }: StepProps) => {
  const getIndicator = () => {
    if (status === "done")
      return (
        <StatusIndicator
          svgClassName="w-14 h-14"
          checkIconClassName="fill-current text-primary"
        />
      );
    if (status === "pending")
      return (
        <StatusIndicator
          svgClassName="w-14 h-14"
          checkIconClassName="fill-current text-gray-300"
        />
      );
    return <LoadingSpinner svgClassName="w-14 h-14" />;
  };
  const titleIsString = typeof title === "string";
  const descriptionIsString = typeof description === "string";
  return (
    <div className="flex py-6">
      <div>{getIndicator()}</div>

      <div className="min-w-0 flex-1 flex-col ml-4">
        {titleIsString ? (
          <div className="text-lg text-gray-900">{title}</div>
        ) : (
          title
        )}
        {descriptionIsString ? (
          <div className="text-base text-gray-900 ">{description}</div>
        ) : (
          description
        )}
      </div>
    </div>
  );
};

const StatusIndicator = ({
  svgClassName,
  checkIconClassName,
}: {
  svgClassName?: string;
  checkIconClassName?: string;
}) => {
  return (
    <svg
      width="61"
      height="62"
      viewBox="0 0 61 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={svgClassName}
    >
      <g id="Check" className={checkIconClassName}>
        <path d="M0 31C0 14.1553 13.6553 0.5 30.5 0.5V0.5C47.3447 0.5 61 14.1553 61 31V31C61 47.8447 47.3447 61.5 30.5 61.5V61.5C13.6553 61.5 0 47.8447 0 31V31Z" />
        <path
          id="Icon"
          d="M13 33.5L23 43.5L48 18.5"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
