import { createContext, Dispatch, useContext, useReducer } from "react";

import { Eth721Asset } from "@/features/nft/types";
import { PostTypeEnum } from "@/generated/generated-hooks";

/* asset type */
type NftAssetsStateType = {
  [PostTypeEnum.Eth721]: Record<string, Eth721Asset>;
};

/* action and payload type */
export enum NftAssetActionEnum {
  UpdateEth721Asset = "UPDATE_ETH721_ASSET",
  RemoveEth721Asset = "REMOVE_ETH721_ASSET",
}

type NftActionType =
  | {
      type: NftAssetActionEnum.UpdateEth721Asset;
      payload: Eth721Asset;
    }
  | { type: NftAssetActionEnum.RemoveEth721Asset; payload: string };

const NftAssetsReducer = (state: NftAssetsStateType, action: NftActionType) => {
  switch (action.type) {
    case NftAssetActionEnum.UpdateEth721Asset:
      const asset = action.payload;
      if (!asset) return { ...state };

      return {
        ...state,
        [PostTypeEnum.Eth721]: {
          ...state[PostTypeEnum.Eth721],
          [action.payload.id]: { ...action.payload },
        },
      };
    case NftAssetActionEnum.RemoveEth721Asset:
      const tokenId = action.payload;
      let updatedState = {
        ...state[PostTypeEnum.Eth721],
      };
      delete updatedState[tokenId];
      return { ...state, [PostTypeEnum.Eth721]: updatedState };
    default:
      return state;
  }
};

const NftAssetsInitialState: NftAssetsStateType = {
  [PostTypeEnum.Eth721]: {},
};
const NftAssetsContextInitialState = {
  nftAssetsMap: { [PostTypeEnum.Eth721]: {} },
};

const NftAssetsContext = createContext<{
  nftAssetsMap: NftAssetsStateType;
  dispatch?: Dispatch<NftActionType>;
}>(NftAssetsContextInitialState);

export const NftAssetsMapProvider = (props) => {
  const [nftAssetsMap, dispatch] = useReducer(
    NftAssetsReducer,
    NftAssetsInitialState,
  );

  return (
    <NftAssetsContext.Provider value={{ dispatch, nftAssetsMap }} {...props} />
  );
};

export function useNftAssetsMap() {
  return useContext(NftAssetsContext);
}
