import * as Sentry from "@sentry/nextjs";
import differenceInYears from "date-fns/differenceInYears";
import firebase from "firebase/app";
import { useRouter } from "next/router";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  FC,
} from "react";

import { BannerAvatarSize } from "@/consts/avatarSizes";
import { BannerSize } from "@/consts/bannerSize";
import { useUser } from "@/features/user/hooks/useUser";
import { useMeQuery, User } from "@/generated/generated-hooks";

interface UserContextProps {
  ssrUserId?: string;
  ssrShowSensitiveContent?: boolean;
  user?: User;
  signOut?: () => Promise<void>;
  ready?: boolean;
}

const UserContext = createContext<UserContextProps>({});

type UserProviderProps = {
  ssrUserId: string;
  ssrShowSensitiveContent: boolean;
};

export const UserProvider: FC<UserProviderProps> = (props) => {
  const { push } = useRouter();
  const [user, setUser] = useState(null);

  const { user: firebaseUser, signOut, onIdTokenChanged } = useUser();

  const { data, loading, error } = useMeQuery({
    skip: !onIdTokenChanged || (onIdTokenChanged && !firebaseUser),
    variables: {
      avatarSize: {
        maxHeight: BannerAvatarSize.MaxHeight,
        maxWidth: BannerAvatarSize.MaxWidth,
      },
      bannerSize: {
        maxWidth: BannerSize.MaxWidth,
      },
    },
    onError: (error) => {
      if (error?.graphQLErrors[0]?.message === "NOT_FOUND") push("/register");
    },
  });

  useEffect(() => {
    if (loading) return;
    if (onIdTokenChanged && !firebaseUser) return setUser(null);
    if (data?.me) {
      const { _id, gender, birthdate, locale, accountName } = data.me;
      Sentry.setUser({ id: _id, username: accountName });
      const age = birthdate
        ? differenceInYears(new Date(), new Date(birthdate))
        : null;
      firebase.analytics().setUserProperties({ gender, age, locale, _id });
      firebase.analytics().setUserId(_id);
    }
    setUser(data?.me ?? null);
  }, [data, firebaseUser, loading, onIdTokenChanged]);

  return (
    <UserContext.Provider
      value={{
        user,
        ssrUserId: props.ssrUserId,
        ssrShowSensitiveContent: props.ssrShowSensitiveContent,
        signOut,
        ready:
          onIdTokenChanged &&
          !loading &&
          ((!!firebaseUser && !!user) || (!firebaseUser && !user)),
      }}
      {...props}
    />
  );
};
export function useUserContext() {
  return useContext(UserContext);
}
