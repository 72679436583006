export enum BannerAvatarSize {
  MaxHeight = 460,
  MaxWidth = 460,
}
export enum CommentAvatarSize {
  MaxHeight = 150,
  MaxWidth = 150,
}
export enum PostWriterAvatarSize {
  MaxHeight = 300,
  MaxWidth = 300,
}
