const formatDistanceLocale = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds",
  },
  xSeconds: {
    one: "Now",
    other: "Now",
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes",
  },
  xMinutes: {
    one: "1m",
    other: "{{count}}m",
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours",
  },
  xHours: {
    one: "1h",
    other: "{{count}}h",
  },
  xDays: {
    one: "1d",
    other: "{{count}}d",
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks",
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks",
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months",
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months",
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years",
  },
  xYears: {
    one: "1y",
    other: "{{count}}y",
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years",
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years",
  },
};

export function formatDistanceEnUs(token, count, options) {
  options = options || {};
  let result;

  if (typeof formatDistanceLocale[token] === "string") {
    result = formatDistanceLocale[token];
  } else if (count === 1) {
    result = formatDistanceLocale[token].one;
  } else {
    result = formatDistanceLocale[token].other.replace("{{count}}", count);
  }

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }

  return result;
}
