import {
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import React, { FC, ReactNode } from "react";

export enum TopReminderBannerType {
  alert = "ALERT_BANNER",
  warning = "WARNNING_BANNER",
  success = "SUCCESS_BANNER",
}

export const TopReminderBanner: FC<{
  type: TopReminderBannerType;
  listItems?: (string | ReactNode)[];
  header?: string;
  withAccentBorder?: boolean;
}> = (props) => {
  const { type, listItems, header, withAccentBorder = false } = props;

  const getStyleTheme: () => {
    Icon: ReactNode;
    headerColor: string;
    backgroundColor: string;
    borderColor: string;
    listItemsColor: string;
    themeColor: string;
  } = () => {
    switch (type) {
      case TopReminderBannerType.alert: {
        return {
          Icon: (
            <ExclamationIcon
              className={`h-5 w-5 text-yellow-400`}
              aria-hidden="true"
            />
          ),
          themeColor: "yellow",
          headerColor: `text-yellow-800`,
          backgroundColor: `bg-yellow-50`,
          borderColor: `border-yellow-400`,
          listItemsColor: `text-yellow-700`,
        };
      }

      case TopReminderBannerType.warning: {
        return {
          Icon: (
            <XCircleIcon
              className={`h-5 w-5 text-red-400`}
              aria-hidden="true"
            />
          ),
          themeColor: "red",
          headerColor: `text-red-800`,
          backgroundColor: `bg-red-50`,
          borderColor: `border-red-400`,
          listItemsColor: `text-red-700`,
        };
      }
      default: {
        return {
          Icon: (
            <CheckCircleIcon
              className={`h-5 w-5 text-green-400`}
              aria-hidden="true"
            />
          ),
          themeColor: `green`,
          headerColor: `text-green-800`,
          backgroundColor: `bg-green-50`,
          borderColor: `border-green-400`,
          listItemsColor: `text-green-700`,
        };
      }
    }
  };

  const styleTheme = getStyleTheme();

  return (
    <div className="fixed top-16 w-full z-50 p-4">
      <div
        className={`p-4 rounded-md ${styleTheme.backgroundColor} ${
          withAccentBorder && `border-l-4 ${styleTheme.borderColor}`
        } sm:w-3/4 md:w-3/5 mx-auto filter drop-shadow-md`}
      >
        <div className="flex">
          <div className="shrink-0">{styleTheme.Icon}</div>
          <div className="min-w-0 ml-3">
            <div className={`text-sm font-medium ${styleTheme.headerColor}`}>
              {header}
            </div>
            <div className={`mt-2 text-sm ${styleTheme.listItemsColor}`}>
              <ul role="list" className="list-disc pl-5 space-y-1">
                {listItems.map((el, idx) => {
                  return <li key={idx}>{el}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopReminderBanner;
