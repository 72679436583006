import DearshareMarketplaceV1Abi from "contracts/generated/abi/DearShareMarketplaceV1.json";
import DearshareNFTV1Abi from "contracts/generated/abi/DearShareNFTV1.json";
import { DearShareMarketplaceV1 } from "contracts/generated/types/DearShareMarketplaceV1";
import { DearShareNFTV1 } from "contracts/generated/types/DearShareNFTV1";
import { createContext, useContext, useMemo } from "react";

import { getPublicConfig } from "@/helpers/getPublicConfig";

import { useNftContext } from "./useNft";

interface ContractContextProps {
  eth721Contract?: DearShareNFTV1;
  marketplaceContract?: DearShareMarketplaceV1;
}

const ContractContext = createContext<ContractContextProps>({});

export const ContractProvider = (props) => {
  const {
    service: { ethereum },
  } = getPublicConfig();

  const { eth } = useNftContext();

  const eth721Contract = useMemo(() => {
    if (!eth) return null;

    const contract = new eth.Contract(
      DearshareNFTV1Abi as any,
      ethereum.contractAddress.eth721,
    ) as any as DearShareNFTV1;

    return contract;
  }, [eth, ethereum.contractAddress]);

  const marketplaceContract = useMemo(() => {
    if (!eth) return null;

    const contract = new eth.Contract(
      DearshareMarketplaceV1Abi as any,
      ethereum.contractAddress.marketplace,
    ) as any as DearShareMarketplaceV1;

    return contract;
  }, [eth, ethereum.contractAddress]);

  return (
    <ContractContext.Provider value={{ eth721Contract, marketplaceContract }}>
      {props.children}
    </ContractContext.Provider>
  );
};

export function useContract() {
  return useContext(ContractContext);
}
