import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { withPasswordProtect } from "@storyofams/next-password-protect";
import type { NextPage } from "next";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import Error from "next/error";
import Head from "next/head";
import NextNprogress from "nextjs-progressbar";
import React from "react";
import type { ReactElement, ReactNode } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import theme from "@/consts/theme";
import { TailwindBaseDialogProvider as UniqueDialogProvider } from "@/features/common/components/dialog/v3/BaseDialog";
import { I18NProvider } from "@/features/common/components/i18n/I18NProvider";
import SiteLayout from "@/features/common/components/navigation/SiteLayout";
import { MuiThemeProvider } from "@/features/common/components/theme/MuiThemeProvider";
import { UIContextProvider } from "@/features/common/hooks/UIContext/useUIContext";
import { MasonryProvider } from "@/features/common/hooks/masonry/useMasonry";
import { SnackbarProvider } from "@/features/common/hooks/snackbar/useSnackbar";
import { useApollo } from "@/features/common/hooks/useApollo";
import { DialogProvider } from "@/features/common/hooks/useDialog";
import { LinkWalletAccount } from "@/features/nft/components/LinkWalletAccount";
import { OpenSeaPortEventProvider } from "@/features/nft/hooks/OpenSeaPortEventProvider";
import { ContractProvider } from "@/features/nft/hooks/useContract";
import { NftProvider } from "@/features/nft/hooks/useNft";
import { PostDialog } from "@/features/post/components/PostDialog";
import { NftAssetsMapProvider } from "@/features/post/hooks/useNftAssetsMap";
import { AlgoliaProvider } from "@/features/search/hooks/useAlgoliaClient";
import { UserProvider } from "@/features/user/contexts/useUserContext";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { trpc } from "@/helpers/trpc";
import { initDatadogBrowserLogger } from "@/services/datadog/browserLogger";
import "@/services/firebase/initialization/app";
//initialize firebase app
import "@/styles/editor.css";
import "@/styles/imageSlider.css";
import "@/styles/root.css";
import "@/styles/tailwind.css";

import nextI18NextConfig from "../../next-i18next.config";

initDatadogBrowserLogger();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const config = getPublicConfig();

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  const client = useApollo(pageProps);

  const getLayout =
    Component.getLayout || ((page) => <SiteLayout>{page}</SiteLayout>);

  return (
    <React.Fragment>
      <Sentry.ErrorBoundary fallback={<Error statusCode={500} />}>
        <Head>
          <link rel="icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" sizes="150x150" href="/favicon.ico" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>

        <ApolloProvider client={client}>
          <StyledThemeProvider theme={{ colors: { primary: "#0070f3" } }}>
            <SnackbarProvider>
              <UserProvider
                ssrUserId={pageProps.ssrUserId}
                ssrShowSensitiveContent={pageProps.ssrShowSensitiveContent}
              >
                <MuiThemeProvider>
                  <I18NProvider>
                    <NextNprogress
                      color={theme.palette.primary.main}
                      height={5}
                      options={{ showSpinner: false }}
                    />

                    <UIContextProvider>
                      <MasonryProvider>
                        <NftProvider>
                          <LinkWalletAccount />
                          <ContractProvider>
                            <UniqueDialogProvider>
                              <DialogProvider>
                                <AlgoliaProvider>
                                  <OpenSeaPortEventProvider>
                                    <NftAssetsMapProvider>
                                      {getLayout(<Component {...pageProps} />)}
                                      <PostDialog />
                                    </NftAssetsMapProvider>
                                  </OpenSeaPortEventProvider>
                                </AlgoliaProvider>
                              </DialogProvider>
                            </UniqueDialogProvider>
                          </ContractProvider>
                        </NftProvider>
                      </MasonryProvider>
                    </UIContextProvider>
                  </I18NProvider>
                </MuiThemeProvider>
              </UserProvider>
            </SnackbarProvider>
          </StyledThemeProvider>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </React.Fragment>
  );
}

const App = trpc.withTRPC(appWithTranslation(MyApp, nextI18NextConfig));

export default !config.isProduction && !config.isLocal
  ? withPasswordProtect(App)
  : App;
