import Container from "@material-ui/core/Container";
import Skeleton from "@material-ui/lab/Skeleton";

import { Square } from "@/features/common/components/square/Square";
import { View } from "@/features/common/components/tailwind/View";
import { convertVariantToDimensions } from "@/helpers/user/avatar/avatarHelper";

export const DetailPostPlaceholder = () => {
  const { width, height } = convertVariantToDimensions(
    "postPageBasicInformation",
  );
  return (
    <Container maxWidth="lg">
      <View className="w-full flex justify-center pb-6 mt-6">
        <View className="flex flex-col mb-6 flex-1 max-w-[800px]">
          <View className="mb-4 flex">
            <Skeleton variant="circle" width={width} height={height} />
            <View className="flex flex-col ml-2 mt-2">
              <View className="mb-2">
                <Skeleton width={250} />
              </View>
              <Skeleton width={150} />
            </View>
          </View>
          <View className="mb-2">
            <Skeleton variant="circle" width={width} height={height} />
            <View className="flex flex-col ml-2 mt-2">
              <View className="mb-2">
                <Skeleton width={250} />
              </View>
              <Skeleton width={150} />
            </View>
          </View>

          <Square>
            <Skeleton variant="rect" width="100%" height="100%" />
          </Square>

          <View className="my-4">
            <Skeleton width={250} height={40} />
          </View>

          <Skeleton width={150} />
        </View>
      </View>
    </Container>
  );
};
