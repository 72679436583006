const path = require("path");

const Sentry = require("@sentry/nextjs");

module.exports = {
  i18n: {
    defaultLocale: "zh",
    locales: ["zh", "en"],
  },
  localePath: path.resolve("./public/static/locales"),
  serializeConfig: false,
  saveMissing: true,
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    // Sentry.captureException("Translation missing", {
    //   tags: { language: lng.toString() },
    //   extra: { namespace: ns, key },
    // });
  },
};
