/* ug: update gitbook */
export enum AnalyticsEventEnum {
  Logo = "logo",
  SignOut = "sign_out",

  /* Login */
  LoginMobile = "login_mobile",
  LoginEmailLink = "login_email_link",
  LoginTwitter = "login_twitter",
  LoginFacebook = "login_facebook",
  LoginGoogle = "login_google",
  LoginPrivacy = "login_privacy",
  LoginTermsOfUse = "login_termsOfUse",

  /* Landing */
  //Login
  LandingLogin = "landing_login",

  //JoinedWriter
  JoinedWriterClickAvatar = "joined_writer_click_avatar",
  JoinedWriterSliderNext = "joined_writer_slider_next",
  JoinedWriterSliderPrevious = "joined_writer_slider_previous",

  //Dearshare Functions/features
  DearshareFeaturesSubscriptionPlan = "dearshare_features_subscription_plan",
  DearshareFeaturesChat = "dearshare_features_chat",
  DearshareFeaturesCustomizePost = "dearshare_features_customize_post",
  DearshareFeaturesChannel = "dearshare_features_channel",
  DearshareFeaturesNotification = "dearshare_features_notification",
  DearshareFeaturesFollow = "dearshare_features_follow",

  /* Home */
  //Top writer
  TopWriterClickFollow = "top_writer_click_follow",
  TopWriterClickUnfollow = "top_writer_click_unfollow",
  TopWriterSliderNext = "top_writer_slider_next",
  TopWriterSliderPrevious = "top_writer_slider_previous",
  TopWriterClickAvatar = "top_writer_click_avatar",
  TopWriterClickDisplayName = "top_writer_click_display_name",
  //Tag
  HomeTagClick = "home_tag_click",

  //Post Filter
  AllPost = "all_post",
  NormalPost = "normal_post",
  Collectible = "collectibe",

  /* My Following */
  MyFollowingSuggestionClickFollow = "my_following_suggestion_click_follow",
  MyFollowingClickSubscriptionPlans = "my_following_click_subscription_plans",

  /* post */
  PostCreate = "post_create",
  PostCreateMobile = "post_create_mobile",
  PostCreateMobileBottomAppBar = "post_create_mobile_bottom_app_bar",
  PostEmptyPlaceholderCreate = "post_empty_placeholder_create",
  PostEmptyPlaceholderCreateMobile = "post_empty_placeholder_create_mobile",
  PostPreview = "post_preview",
  PostEdit = "post_edit",
  PostFocusTitle = "post_focus_title",
  PostUploadFiles = "post_upload_files",
  PostUploadVideo = "post_upload_video",
  PostSelectChannel = "post_select_channel",
  PostSelectTag = "post_select_tag",
  PostCreateTag = "post_create_tag",
  PostRemoveTag = "post_remove_tag",
  PostSelectBlurLevel = "post_select_blur_level",
  PostAdjustContentVisibleHeight = "post_adjust_content_visible_height",
  PostPublish = "post_publish",
  /* post page, /post/[slug] */
  PostPageClickAvatar = "post_page_avatar",
  PostPageClickDisplayName = "post_page_display_name",
  PostPageClickChannel = "post_page_click_channel",
  PostPageLike = "post_page_like",
  PostPageRemoveLike = "post_page_remove_like",
  PostPageScrollToComment = "post_page_scroll_to_comment",
  PostPageReplyUrlScrollToComment = "post_page_reply_url_scroll_to_comment",
  PostPageShareWhatsapp = "post_page_share_whatsapp",
  PostPageShareFacebook = "post_page_share_facebook",
  PostPageShareTelegram = "post_page_share_telegram",
  PostPageShareTwitter = "post_page_share_twitter",
  PostPageShareMobile = "post_page_share_mobile",

  /* post page -> comment */
  CommentNew = "comment_new",
  CommentReplyToParent = "comment_reply_to_parent",
  CommentToggleShowChildren = "comment_toggle_show_children",
  CommentShowMoreChildren = "comment_show_more_children",
  CommentToggleReplyParentInputField = "comment_toggle_reply_parent_input_field",
  CommentRemoveClick = "comment_remove_click",
  CommentRemoveDialogConfirm = "comment_remove_dialog_confirm",
  CommentRemoveDialogCancel = "comment_remove_dialog_cancel",

  /* profile */
  //tab
  ScrollableTabMySettingsProfile = "scrollable_tab_my_settings_profile",
  ScrollableTabMySettingsChannel = "scrollable_tab_my_settings_channel",
  ScrollableTabSubscriptionPlansSetting = "scrollable_tab_subscription_plans_setting",
  ScrollableTabSubscriptionPlansSubscribers = "scrollable_tab_subscription_plans_subscribers",
  ScrollableTabPaymentMethods = "scrollable_tab_payment_methods",
  ScrollableTabPaymentRecords = "scrollable_tab_payment_records",
  ScrollableTabMySubscriptionActive = "scrollable_tab_my_subscription_active",
  ScrollableTabMySubscriptionHistory = "scrollable_tab_my_subscription_history",
  ScrollableTabMyFollowingUser = "scrollable_tab_my_following_user",
  ScrollableTabMyFollowingChannel = "scrollable_tab_my_following_channel",
  ScrollableTabMyAudienceUser = "scrollable_tab_my_audience_user",
  ScrollableTabMyAudienceChannel = "scrollable_tab_my_audience_channel",

  //basic
  ProfileBasicUpdateBanner = "profile_basic_update_banner",
  ProfileBasicUpdateAvatar = "profile_basic_update_avatar",
  ProfileBasicSelectLocale = "profile_basic_select_locale",
  ProfileBasicFocusAccountName = "profile_basic_focus_account_name",
  ProfileBasicSelectAccountNameSuggestion = "profile_basic_select_account_name_suggestion",
  ProfileBasicFocusDisplayName = "profile_basic_focus_display_name",
  ProfileBasicSelectMale = "profile_basic_select_male",
  ProfileBasicSelectFemale = "profile_basic_select_female",
  ProfileBasicSelectOther = "profile_basic_select_other",
  ProfileBasicEditBirthDate = "profile_basic_edit_birth_date",
  ProfileBasicSave = "profile_basic_save",

  /* subscription plan settings */
  SubscriptionPlanSettingsCreate = "subscription_plan_settings_create",
  SubscriptionPlanSettingsSelectChannel = "subscription_plan_settings_select_channel",
  SubscriptionPlanSettingsEdit = "subscription_plan_settings_edit",
  SubscriptionPlanSettingsCloseEditDialog = "subscription_plan_settings_close_edit",
  SubscriptionPlanSettingsRemoveSubscriptionPlan = "subscription_plan_settings_remove",
  SubscriptionPlanSettingsCloseRemoveSubscriptionPlanDialog = "subscription_plan_settings_close_remove",
  SubscriptionPlanSettingsConfirmRemoveSubscriptionPlan = "subscription_plan_settings_confirm_remove",

  /* subscription */
  SubscriptionDetail = "subscription_detail",
  SubscriptionDetailCancelSubscribe = "subscription_detail_cancel_subscribe",
  SubscriptionDetailBackToDetail = "subscription_detail_back_to_detail",
  SubscriptionDetailConfirmCancelSubscribe = "subscription_detail_back_to_detail",
  SubscriptionDetailClickChannel = "subscription_detail_click_channel",

  /* Payment record */
  SubscriptionDetailPaymentRecord = "subscription_detail_payment_record",
  SubscriptionDetailClosePaymentRecord = "subscription_detail_close_payment_record",
  InvoiceUrl = "invoice_url",
  ReceiptUrl = "receipt_url",

  /* Offline Payment */
  OfflinePaymentTopupCreate = "offline_payment_topup_create",
  OfflinePaymentTopupCancel = "offline_payment_topup_cancel",

  /* channel */
  CreateChannelProfile = "create_channel_profile",
  EditChannelProfile = "edit_channel_profile",
  ChannelDialogClose = "channel_dialog_close_profile",

  /* Notification */
  NotificationOpen = "notification_open",
  NotificationClose = "notification_close",
  NotificationClickItem = "notification_click_item",

  /* Avatar Menu */
  PersonalMenuOpen = "personal_menu_open",
  PersonalMenuClose = "personal_menu_close",
  PersonalMenuSetting = "personal_menu_setting",
  PersonalMenuChannel = "personal_menu_channel",
  PersonalMenuProfile = "personal_menu_profile",
  PersonalMenuPayment = "personal_menu_payment",
  PersonalMenuReceivePayment = "personal_menu_receive_payment",
  PersonalMenuSubscription = "personal_menu_subscription",
  PersonalSupport = "personal_menu_support",

  /* Drawer Menu */
  DrawerMenuOpen = "drawer_menu_open",
  DrawerMenuClose = "drawer_menu_close",
  DrawerMenuSetting = "drawer_menu_setting",
  DrawerMenuChannel = "drawer_menu_channel",
  DrawerMenuSubscription = "drawer_menu_subscription",
  DrawerMenuSubscriptionPlans = "drawer_menu_subscription_plans",
  DrawerMenuPayment = "drawer_menu_payment",
  DrawerMenuPayout = "drawer_menu_payout",
  DrawerMenuConnectWallet = "drawer_menu_connect_wallet",
  DrawerMenuCollectibles = "drawer_menu_collectibles",
  DrawerMenuSupport = "drawer_menu_support",
  DrawerMenuSignOut = "drawer_menu_sign_out",
  DrawerMenuPrivacyPolicy = "drawer_menu_privacy_policy",
  DrawerMenuTermsOfUse = "drawer_menu_terms_of_use",
  DrawerMenuFaqs = "drawer_menu_faqs",
  DrawerMenuFacebook = "drawer_menu_facebook",

  /* Public Profile */
  PublicProfileSelectChannel = "public_profile_select_channel",
  PublicProfileCreateNewPost = "public_profile_create_new_post",
  PublicProfileClickDisplayName = "public_profile_click_display_name",
  PublicProfileClickFollow = "public_profile_click_follow",
  PublicProfileClickUnfollow = "public_profile_click_unfollow",
  PublicProfileClickMessage = "public_profile_click_message",
  PublicProfileClickSubscriptionPlans = "public_profile_click_subscription_plans",
  PublicProfileClickFollowChannel = "public_profile_click_follow_channel",
  PublicProfileClickUnfollowChannel = "public_profile_click_unfollow_channel",
  PublicProfileScrollToPostSection = "public_profile_scroll_to_post_section",
  PublicProfileEditProfile = "public_profile_edit_profile",
  PublicProfileClickUpgradeCreatorTier = "public_profile_click_upgrade_creator_tier",

  /* Subscription Card */
  SubscriptionCardClickSubscribe = "subscription_card_click_subscribe",

  /* Subscription Card -> confirm payment */
  PaymentConfirmClickDefault = "payment_confirm_click_default",
  PaymentConfirmClickOthers = "payment_confirm_click_other",
  PaymentConfirmSelectPayment = "payment_confirm_select_payment",
  PaymentConfirmCompleteNewCard = "payment_confirm_complete_new_card",
  PaymentConfirmSelectNewCard = "payment_confirm_select_new_card",
  SubscribeWithStripeNew = "subscribe_with_stripe_new",
  SubscribeWithStripeExisting = "subscribe_with_stripe_existing",
  PurchaseWithStripeNew = "purchase_with_stripe_new",
  PurchaseWithStripeExisting = "purchase_with_stripe_existing",
  SubscribeWithCredit = "subscribe_with_credit",
  PurchaseWithCredit = "purchase_with_credit",

  /* Post Card  */
  ImageCardClickAuthor = "image_card_click_author",
  ImageCardClickTitle = "image_card_click_title",
  ImageCardClickMenu = "image_card_click_menu",
  ImageCardClickSubscribe = "image_card_click_subscribe",
  ImageCardClickImage = "image_card_click_image",
  ImageCardClickLike = "image_card_click_like",
  ImageCardClickRemoveLike = "image_card_click_remove_like",
  ImageCardClickShare = "image_card_click_share",
  ImageCardPurchase = "image_card_purchase",
  HoverSubscribeMask = "hover_subscribe_mask_join",

  /* Nft */
  NftCancelBuy = "nft_cancel_buy",
  NftCancelList = "nft_cancel_list",
  NftBuyNow = "nft_buy_now",
  NftList = "nft_list",
  NftConfirmList = "nft_confirmList",
  NftConfirmBuy = "nft_confirmBuy",
  NftImageCardLike = "nft_image_card_like",
  NftImageCardUnlike = "nft_image_card_unlike",
  NftPostContentLike = "nft_post_content_like",
  NftPostContentUnlike = "nft_post_content_unlike",
  NftDisplayNameClick = "nft_display_name_click",
  NftEtherScanLinkClick = "nft_ether_scan_link_click",

  /* Nft Post Content */
  NftPostContentActionMenuReportPost = "nft_post_action_menu_report_post",
  NftPostContentActionMenuEdit = "nft_post_action_menu_edit",
  NftPostContentActionMenuUnlist = "nft_post_action_menu_unlist",
  NftPostContentActionMenuUpdatePrice = "nft_post_action_menu_update_price",
  NftPostContentNavInfo = "nft_post_content_nav_info",
  NftPostContentNavHistory = "nft_post_content_nav_history",

  /* User Post Action menu */
  MenuReportPost = "menu_report_post",

  /* Owner Post Action Menu */
  MenuEditPost = "menu_edit_post",

  /* Subscription Plan Dialog */
  SubscriptionPlanDialogNext = "subscription_plan_dialog_next",
  SubscriptionPlanDialogBack = "subscription_plan_dialog_back",
  SubscriptionPlanDialogClose = "subscription_plan_dialog_close",
  SubscriptionPlanDialogSelectChannel = "subscription_plan_dialog_select_channel",

  CopyToClipBoard = "copy_to_clip_board",

  /* Application */
  CreatorApplicationBannerApply = "creator_application_banner_apply",
  CreatorApplicationPayoutApply = "creator_application_payout_apply",
  // CreatorApplicationCreatingPost = "creator_application_creating_post",
  CreatorApplicationSubscriptionPlansApply = "creator_application_subscription_plans_apply",
  CreatorApplicationAvatarMenuApply = "creator_application_avatar_apply",
  CreatorApplicationDrawerMenuApply = "creator_application_drawer_apply",
  CreatorApplicationSubMenuApply = "creator_application_sub_menu_apply",
  CreatorApplicationSubmit = "creator_application_submit",
  CreatorApplicationResubmit = "creator_application_resubmit",
  CreatorApplicationDialogClose = "creator_application_dialog_close",
  CreatorApplicationDialogCreateForm = "creator_application_dialog_update_form",
  CreatorApplicationDialogUpdateForm = "creator_application_dialog_create_form",

  /* sensitive content */
  SensitiveContentDialogOpen = "sensitive_content_dialog_open",
  SensitiveContentDialogLogin = "sensitive_content_dialog_login",
  SensitiveContentDialogClose = "sensitive_content_dialog_close",
  SensitiveContentDialogGoToMySettings = "sensitive_content_dialog_go_to_my_settings",
  SensitiveContentDialogBackToVisitor = "sensitive_content_dialog_back_to_visitor",

  /* nft wallet */
  ConnectWalletAvatarMenu = "connect_wallet_avatar_menu",
  ConnectWalletDrawerMenu = "connect_wallet_drawer_menu",
  ConnectWalletBanner = "connect_wallet_banner",
  ConnectWalletProfile = "connect_wallet_profile",
  ConnectWalletContentSelect = "connect_wallet_content_select",

  /* Sub Menu */
  SubmenuToggleItem = "submenu_toggle_item",
  SubmenuNavigateToRoute = "submenu_navigate_to_route",

  /* Footer */
  FooterPrivacyPolicy = "footer_privacy_policy",
  FooterTermsOfUse = "footer_terms_of_use",
  FooterFaq = "footer_faq",
  FooterFacebook = "footer_facebook",
  FooterInstagram = "footer_instagram",

  /* Support */
  SupportMail = "support_mail",
  SupportMailMobile = "support_mail_mobile",

  /* Post Dialog */
  PostDialogClose = "post_dialog_close",
  PostDialogCloseMobile = "post_dialog_close_mobile",

  ImageSliderNext = "image_slider_next",
  ImageSliderPrevious = "image_slider_previous",

  /* Message Button */
  MessageButtonClick = "message_button_click",
  MessageButtonClickMobile = "message_button_click_mobile",

  /* Royalty Table */
  RoyaltyTableClickPost = "royalty_table_click_post",
  RoyaltyTableClickDetail = "royalty_table_click_detail",
  RoyaltyTableClickFromAddress = "royalty_table_click_from_address",

  /* Search */
  SearchDialogMobileOpen = "seach_dialog_open",
  SearchDialogMobileClose = "seach_dialog_close",
  SearchDesktopOpen = "seach_desktop_open",
  SearchDesktopClose = "seach_desktop_close",

  /* Tips */
  TipsDialogOpen = "tips_dialog_open",
  TipsDialogDefaultValueSelected = "tips_dialog_default_value_selected",
}
