import { FieldMergeFunction } from "@apollo/client";

import { LikeDocument, LikedPostPage } from "@/generated/generated-hooks";

export const likedPostPageMergeFn: FieldMergeFunction<LikedPostPage> = (
  existing,
  incoming,
  { cache, readField },
) => {
  const existingItems = existing?.items ?? [];
  const incomingItems = incoming?.items ?? [];

  const mergedItems = [...existingItems, ...incomingItems];

  incomingItems.forEach((element) => {
    const userId = readField("userId", element);
    const postId = readField("postId", element);

    cache.writeQuery({
      query: LikeDocument,
      variables: { input: postId },
      data: {
        like: {
          postId,
          userId,
          __typename: "Like",
        },
      },
    });
  });

  return { ...incoming, items: mergedItems };
};
