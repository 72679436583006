import React, { createContext, useContext, useState } from "react";

import { Snackbar } from "./Snackbar";
import { SnackbarProps } from "./type";

interface SnackbarContextProps {
  openSnackbar?: (snackbarProps: SnackbarProps) => void;
}

const SnackbarContext = createContext<SnackbarContextProps>({});

export const SnackbarProvider = ({ children }) => {
  const [snackProps, setSnackProps] = useState<SnackbarProps>({});
  return (
    <SnackbarContext.Provider value={{ openSnackbar: setSnackProps }}>
      {children}
      <Snackbar {...snackProps} />
    </SnackbarContext.Provider>
  );
};
export const useSnackbar = () => useContext(SnackbarContext);
