import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import React, {
  FC,
  Fragment,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

export const DialogContentWrapper: FC = ({ children }) => {
  return <div className="inline-block w-full overflow-hidden">{children}</div>;
};

interface TailwindBaseDialogProps {
  openBaseDialog: (props: {
    content: ReactNode;
    header?: string | ReactNode;
  }) => void;
  closeBaseDialog: () => void;
  DialogContentWrapper: FC;
}

const TailwindBaseDialogContext = createContext<TailwindBaseDialogProps>({
  openBaseDialog: () => {},
  closeBaseDialog: () => {},
  DialogContentWrapper,
});

export const TailwindBaseDialogProvider: FC = ({ children }) => {
  const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
  const [dialogHeader, setDialogHeader] = useState<string | ReactNode | null>(
    null,
  );

  const closeBaseDialog = () => {
    setDialogContent(null);
    setDialogHeader(null);
  };

  const openBaseDialog = ({
    content,
    header,
  }: {
    content: ReactNode;
    header?: string | ReactNode;
  }) => {
    setDialogContent(content);
    setDialogHeader(header);
  };

  return (
    <TailwindBaseDialogContext.Provider
      value={{
        openBaseDialog,
        closeBaseDialog,
        DialogContentWrapper,
      }}
    >
      <>
        <Transition appear show={!!dialogContent} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-modal overflow-y-auto max-h-screen max-w-[100vw]"
            onClose={closeBaseDialog}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity z-[-1]" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              {!!dialogContent && (
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="relative max-w-4xl sm:min-w-md inline-block w-full sm:w-auto py-7 px-2 md:px-14 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-md">
                    <div
                      className={clsx(
                        "flex justify-center text-2xl  text-gray-900",
                      )}
                    >
                      {dialogHeader}
                    </div>
                    {dialogContent}
                  </div>
                </Transition.Child>
              )}
            </div>
          </Dialog>
        </Transition>
        {children}
      </>
    </TailwindBaseDialogContext.Provider>
  );
};

export const useTailwindBaseDialog = () =>
  useContext(TailwindBaseDialogContext);
