import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useRouter } from "next/router";
import { useMemo } from "react";

import theme, {
  muiThemeObject,
  muiThemeTypographyObjectEn,
  muiThemeTypographyObjectZh,
} from "@/consts/theme";

export const MuiThemeProvider: React.FC = (props) => {
  const { locale } = useRouter();

  const muiTheme = useMemo(() => {
    if (locale === "zh") {
      return createMuiTheme({
        ...muiThemeObject,
        typography: muiThemeTypographyObjectZh,
      });
    } else if (locale === "en") {
      return createMuiTheme({
        ...muiThemeObject,
        typography: muiThemeTypographyObjectEn,
      });
    }

    return theme;
  }, [locale]);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};
