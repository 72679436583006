import clsx from "clsx";
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { LoadingSpinner } from "../animation/LoadingSpinner";

type OutlinedButtonProps = {
  variant:
    | "primary"
    | "secondary"
    | "tertiary"
    | "warning"
    | "gray"
    | "overlay"
    | "overlay-white-transparent";
  loading?: boolean;
  fullWidth?: boolean;
  utilityClassName?: string;
  buttonProps?: Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "className"
  >;
};

export const OutlinedButton: FC<OutlinedButtonProps> = (props) => {
  const {
    variant,
    loading = false,
    buttonProps = {},
    utilityClassName = "",
    fullWidth = false,
  } = props;
  const { disabled = false } = buttonProps;

  const className = useMemo(() => {
    if (disabled) {
      switch (variant) {
        case "secondary":
          return "border border-gray-400 text-gray-400";
        case "tertiary":
          return "text-gray-400 rounded-none";
        default:
          return "bg-primary-disabled text-white";
      }
    }

    switch (variant) {
      case "overlay":
        return "border border-white bg-white text-gray-900";
      case "overlay-white-transparent":
        return "border border-white bg-transparent text-white";
      case "secondary":
        return "border border-primary text-primary hover:text-primary-800 hover:border-primary-800";
      case "tertiary":
        return "text-primary hover:text-primary-800 rounded-none";
      case "warning":
        return "border border-red-700 bg-red-50 text-red-700 hover:text-red-800 hover:border-red-800";
      case "gray":
        return "text-gray-500 hover:text-gray-700 border border-gray-500 hover:border-gray-700 hover:bg-gray-50";
      default:
        return "bg-primary text-white hover:bg-primary-800";
    }
  }, [disabled, variant]);

  return (
    <button
      {...buttonProps}
      className={twMerge(
        "space-x-2 px-4 py-2 text-sm rounded-3xl",
        variant === "tertiary"
          ? "truncate inline-block max-w-full"
          : "flex items-center justify-center",
        className,
        disabled && "cursor-not-allowed",
        fullWidth && "w-full",
        utilityClassName,
      )}
    >
      {loading && <LoadingSpinner svgClassName="w-5 h-5 mr-1" />}
      {props.children}
    </button>
  );
};
