import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};


export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['String']>;
  health: Scalars['Boolean'];
  uploadPage: UploadPage;
  post?: Maybe<Post>;
  postPage: PostPage;
  likedPostPage?: Maybe<LikedPostPage>;
  mapTokensToPostPage?: Maybe<MappedPostPage>;
  me?: Maybe<User>;
  user?: Maybe<User>;
  userPage: UserPage;
  userAccountName: AccountName;
  isTestUser: Scalars['Boolean'];
  tags?: Maybe<Array<Maybe<Tag>>>;
  tag?: Maybe<Tag>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  subscriptionPlanPage?: Maybe<SubscriptionPlanPage>;
  like?: Maybe<Like>;
  userSubscriptionPage: UserSubscriptionPage;
  subscriberPage: UserSubscriptionPage;
  subscriberPageForCms: UserSubscriptionPage;
  followUserPage: FollowUserPage;
  followUserDoc?: Maybe<FollowUser>;
  followTagPage: FollowTagPage;
  followTagDoc?: Maybe<FollowTag>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  channel?: Maybe<Channel>;
  hasNotification?: Maybe<Scalars['Boolean']>;
  notificationPage: NotificationPage;
  commentPage?: Maybe<CommentPage>;
  followChannelPage: FollowChannelPage;
  /** @deprecated Replaced by followChannelPage */
  followerChannelPage: FollowChannelPage;
  followChannelDoc?: Maybe<FollowChannel>;
  chatPage?: Maybe<ChatPage>;
  initialChat?: Maybe<InitialChat>;
  hasMessage?: Maybe<Scalars['Boolean']>;
  messagePage?: Maybe<MessagePage>;
  userPaymentMethods: Array<Maybe<UserPaymentMethod>>;
  userInvoicePage: UserInvoicePage;
  topPosts: Array<TopPost>;
  topTags: Array<TopTag>;
  topWriters: Array<TopWriter>;
  userReceivePaymentMethods: Array<Maybe<UserReceivePaymentMethod>>;
  enabledPayoutProviders: Array<Maybe<ReceivePaymentMethodProviderEnum>>;
  userPayoutPage: UserPayoutPage;
  userWallet?: Maybe<UserWallet>;
  enabledFeatures?: Maybe<Array<Maybe<FeatureNameEnum>>>;
  application?: Maybe<Application>;
};


export type QueryUploadPageArgs = {
  input: SearchInput;
};


export type QueryPostArgs = {
  slug: Scalars['String'];
  isOriginalImage?: Maybe<Scalars['Boolean']>;
};


export type QueryPostPageArgs = {
  input?: Maybe<PostPageInput>;
  paging?: Maybe<CursorPagingInput>;
};


export type QueryLikedPostPageArgs = {
  postTypes: Array<Maybe<PostTypeEnum>>;
  paging?: Maybe<CursorPagingInput>;
};


export type QueryMapTokensToPostPageArgs = {
  purchasedOnly: Scalars['Boolean'];
  showSensitiveContent?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  tokenIds: Array<Maybe<Scalars['String']>>;
  nextOffset: Scalars['String'];
  hasNext: Scalars['Boolean'];
};


export type QueryUserArgs = {
  accountName?: Maybe<Scalars['String']>;
  walletAccount?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryUserPageArgs = {
  input?: Maybe<SearchInput>;
};


export type QueryUserAccountNameArgs = {
  accountName: Scalars['String'];
};


export type QueryIsTestUserArgs = {
  email: Scalars['String'];
};


export type QueryTagsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryTagArgs = {
  value: Scalars['String'];
};


export type QuerySubscriptionPlanArgs = {
  slug: Scalars['String'];
};


export type QuerySubscriptionPlanPageArgs = {
  input?: Maybe<SearchInput>;
};


export type QueryLikeArgs = {
  postId: Scalars['String'];
};


export type QueryUserSubscriptionPageArgs = {
  input: UserSubscriptionPageInput;
  paging?: Maybe<CursorPagingInput>;
};


export type QuerySubscriberPageArgs = {
  input: SubscriberPageInput;
  paging?: Maybe<CursorPagingInput>;
};


export type QuerySubscriberPageForCmsArgs = {
  input?: Maybe<SubscriberPageForCmsInput>;
  paging?: Maybe<CursorPagingInput>;
};


export type QueryFollowUserPageArgs = {
  userId?: Maybe<Scalars['String']>;
  writerId?: Maybe<Scalars['String']>;
  paging?: Maybe<CursorPagingInput>;
};


export type QueryFollowUserDocArgs = {
  writerId: Scalars['ID'];
};


export type QueryFollowTagPageArgs = {
  input?: Maybe<SearchInput>;
};


export type QueryFollowTagDocArgs = {
  tagId: Scalars['ID'];
};


export type QueryChannelsArgs = {
  userId?: Maybe<Scalars['String']>;
  channelStatus?: Maybe<Array<ChannelStatusEnum>>;
};


export type QueryChannelArgs = {
  slug: Scalars['String'];
};


export type QueryNotificationPageArgs = {
  paging?: Maybe<CursorPagingInput>;
};


export type QueryCommentPageArgs = {
  input: CommentPageInput;
  paging?: Maybe<CursorPagingInput>;
};


export type QueryFollowChannelPageArgs = {
  input: FollowChannelQueryInput;
  paging?: Maybe<CursorPagingInput>;
};


export type QueryFollowerChannelPageArgs = {
  input: SearchInput;
  paging?: Maybe<CursorPagingInput>;
  creatorId: Scalars['String'];
};


export type QueryFollowChannelDocArgs = {
  channelId: Scalars['ID'];
};


export type QueryChatPageArgs = {
  paging?: Maybe<CursorPagingInput>;
};


export type QueryInitialChatArgs = {
  input: UserInput;
};


export type QueryMessagePageArgs = {
  input: SearchInput;
  currentUserId: Scalars['String'];
  relatedUserId: Scalars['String'];
  paging?: Maybe<CursorPagingInput>;
};


export type QueryUserPaymentMethodsArgs = {
  userId: Scalars['String'];
  paymentMethodStatus: Array<PaymentMethodStatusEnum>;
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
};


export type QueryUserInvoicePageArgs = {
  userId: Scalars['String'];
  paging?: Maybe<CursorPagingInput>;
};


export type QueryUserReceivePaymentMethodsArgs = {
  userId: Scalars['String'];
};


export type QueryUserPayoutPageArgs = {
  userId: Scalars['String'];
  paging?: Maybe<CursorPagingInput>;
};


export type QueryUserWalletArgs = {
  startAt?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyEnum>;
};


export type QueryApplicationArgs = {
  type: ApplicationTypeEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['String']>;
  uploadFiles?: Maybe<Array<Maybe<CustomUpload>>>;
  reportPost: Scalars['Boolean'];
  createPost: Post;
  updatePost?: Maybe<Post>;
  publishPost?: Maybe<Post>;
  removePost?: Maybe<Post>;
  updateUser: User;
  udpateUserNotificationLastSeen: User;
  udpateUserMessageLastSeen: User;
  linkWalletAccount: User;
  updateUserCreatorTier: User;
  createTag?: Maybe<Tag>;
  createSubscriptionPlan: SubscriptionPlan;
  updateSubscriptionPlan?: Maybe<SubscriptionPlan>;
  removeSubscriptionPlan?: Maybe<SubscriptionPlan>;
  createLike?: Maybe<Like>;
  removeLike?: Maybe<Like>;
  purchaseExpiredContentWithStripe: StripePaymentResponse;
  subscribeWithStripe?: Maybe<StripePaymentResponse>;
  /** deprecated */
  createUserSubscriptionPlanWithExistingPaymentMethod: CreateUserSubscriptionPlanResponse;
  /** deprecated */
  createUserSubscriptionPlanWithNewPaymentMethod: CreateUserSubscriptionPlanResponse;
  cancelUserSubscriptionPlan: UserSubscriptionPlan;
  reactivateSubscription: UserSubscriptionPlan;
  followUser: FollowUser;
  unfollowUser: FollowUser;
  followTag: FollowTag;
  unfollowTag: FollowTag;
  createChannel: Channel;
  updateChannel: UpdateChannelResponse;
  removeChannel: Channel;
  updateNotificationStatus: Notification;
  confirmNftPurchase?: Maybe<NftNotificationResponse>;
  confirmNftList?: Maybe<NftNotificationResponse>;
  confirmNftCancelList?: Maybe<NftNotificationResponse>;
  confirmNftUpdateList?: Maybe<NftNotificationResponse>;
  createComment: Comment;
  removeComment: Comment;
  followChannel: FollowChannel;
  unfollowChannel: FollowChannel;
  createMessage: Message;
  updateMessage: Message;
  removeMessage: Message;
  readMessages: Array<Maybe<Message>>;
  removeUserPaymentMethod: UserPaymentMethod;
  addUserPaymentMethodStripe: UserPaymentMethod;
  createUserReceivePaymentMethod: UserReceivePaymentMethod;
  updateUserReceivePaymentMethod: UserReceivePaymentMethod;
  updateAutoTransfer: UserReceivePaymentMethod;
  createUserPayout: UserPayout;
  createApplication: SubmittedApplicationResponse;
  updateApplication: SubmittedApplicationResponse;
};


export type MutationUploadFilesArgs = {
  input?: Maybe<UploadFileInput>;
};


export type MutationReportPostArgs = {
  input: ReportPostInput;
};


export type MutationCreatePostArgs = {
  postType?: Maybe<PostTypeEnum>;
  isOriginalImage?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationPublishPostArgs = {
  slug: Scalars['String'];
};


export type MutationRemovePostArgs = {
  slug: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationLinkWalletAccountArgs = {
  walletAccount: Scalars['String'];
};


export type MutationCreateTagArgs = {
  input: TagInput;
};


export type MutationCreateSubscriptionPlanArgs = {
  input: CreateSubscriptionPlanInput;
};


export type MutationUpdateSubscriptionPlanArgs = {
  input: UpdateSubscriptionPlanInput;
};


export type MutationRemoveSubscriptionPlanArgs = {
  _id: Scalars['String'];
  removeAllPosts?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateLikeArgs = {
  postId: Scalars['String'];
};


export type MutationRemoveLikeArgs = {
  postId: Scalars['String'];
};


export type MutationPurchaseExpiredContentWithStripeArgs = {
  subscriptionPlanId: Scalars['String'];
  postId: Scalars['String'];
  userPaymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
  email?: Maybe<Scalars['String']>;
};


export type MutationSubscribeWithStripeArgs = {
  subscriptionPlanId: Scalars['String'];
  userPaymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
  email?: Maybe<Scalars['String']>;
};


export type MutationCreateUserSubscriptionPlanWithExistingPaymentMethodArgs = {
  subscriptionPlanId: Scalars['String'];
  userPaymentMethodId: Scalars['String'];
};


export type MutationCreateUserSubscriptionPlanWithNewPaymentMethodArgs = {
  subscriptionPlanId: Scalars['String'];
  paymentMethodId: Scalars['String'];
};


export type MutationCancelUserSubscriptionPlanArgs = {
  userSubscriptionPlanId: Scalars['String'];
};


export type MutationReactivateSubscriptionArgs = {
  userSubscriptionPlanId: Scalars['String'];
};


export type MutationFollowUserArgs = {
  writerId: Scalars['ID'];
};


export type MutationUnfollowUserArgs = {
  writerId: Scalars['ID'];
};


export type MutationFollowTagArgs = {
  tagId: Scalars['ID'];
};


export type MutationUnfollowTagArgs = {
  tagId: Scalars['ID'];
};


export type MutationCreateChannelArgs = {
  title: Scalars['String'];
  isSensitive: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  isCommentRestricted?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationRemoveChannelArgs = {
  _id: Scalars['String'];
};


export type MutationUpdateNotificationStatusArgs = {
  input: UpdateNotificationInput;
};


export type MutationConfirmNftPurchaseArgs = {
  transactionHash: Scalars['String'];
};


export type MutationConfirmNftListArgs = {
  transactionHash: Scalars['String'];
};


export type MutationConfirmNftCancelListArgs = {
  transactionHash: Scalars['String'];
};


export type MutationConfirmNftUpdateListArgs = {
  transactionHash: Scalars['String'];
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationRemoveCommentArgs = {
  commentId: Scalars['String'];
};


export type MutationFollowChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationUnfollowChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
};


export type MutationRemoveMessageArgs = {
  _id: Scalars['String'];
};


export type MutationReadMessagesArgs = {
  senderId: Scalars['String'];
};


export type MutationRemoveUserPaymentMethodArgs = {
  _id: Scalars['String'];
};


export type MutationAddUserPaymentMethodStripeArgs = {
  email?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
};


export type MutationCreateUserReceivePaymentMethodArgs = {
  input: CreateUserReceivePaymentMethodInput;
};


export type MutationUpdateUserReceivePaymentMethodArgs = {
  input: UpdateUserReceivePaymentMethodInput;
};


export type MutationUpdateAutoTransferArgs = {
  receivePaymentMethodId: Scalars['String'];
  autoTransfer: Scalars['Boolean'];
};


export type MutationCreateUserPayoutArgs = {
  input: CreateUserPayoutInput;
};


export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};


export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type IntCriterionInput = {
  eq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Boolean']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type StringCriterionInput = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  like?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['Boolean']>;
  regex?: Maybe<Scalars['String']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type TimeCriterionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['Boolean']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type SearchCriteriaInput = {
  field?: Maybe<Scalars['String']>;
  orSearchCriteria?: Maybe<Array<SearchCriteriaInput>>;
  intCriterion?: Maybe<IntCriterionInput>;
  stringCriterion?: Maybe<StringCriterionInput>;
  timeCriterion?: Maybe<TimeCriterionInput>;
};

export enum SorterTypeEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SorterInput = {
  field: Scalars['String'];
  type: SorterTypeEnum;
};

export type PagingInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageToken?: Maybe<Scalars['Int']>;
};

export type SearchInput = {
  searchCriteria?: Maybe<Array<SearchCriteriaInput>>;
  sorter?: Maybe<Array<SorterInput>>;
  paging?: Maybe<PagingInput>;
};

export type CursorSortingInput = {
  sortBy?: Maybe<Scalars['String']>;
  isAscending?: Maybe<Scalars['Boolean']>;
};

export type CursorPagingInput = {
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['String']>;
};

export type CursorPagingInfo = {
  __typename?: 'CursorPagingInfo';
  previous?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  hasNext: Scalars['Boolean'];
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type IntrinsicSize = {
  __typename?: 'IntrinsicSize';
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type CustomUpload = {
  __typename?: 'CustomUpload';
  _id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  filepath?: Maybe<Scalars['String']>;
  fileType?: Maybe<FileTypeEnum>;
  uploadType?: Maybe<UploadTypeEnum>;
  uploadStatus?: Maybe<UploadStatusEnum>;
  writerId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UploadShort = {
  __typename?: 'UploadShort';
  _id: Scalars['ID'];
  url: Scalars['String'];
  uploadType: UploadTypeEnum;
  fileType: FileTypeEnum;
  filepath?: Maybe<Scalars['String']>;
  hasAlpha?: Maybe<Scalars['Boolean']>;
  intrinsicSize?: Maybe<IntrinsicSize>;
};

export type UploadPage = {
  __typename?: 'UploadPage';
  items: Array<Maybe<CustomUpload>>;
  count?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageToken?: Maybe<Scalars['Int']>;
};

export enum UploadTypeEnum {
  Firebase = 'FIREBASE',
  Cloudinary = 'CLOUDINARY',
  ExternalLink = 'EXTERNAL_LINK',
  Local = 'LOCAL'
}

export enum FileUsageEnum {
  Profile = 'PROFILE',
  Post = 'POST',
  PostArticle = 'POST_ARTICLE',
  SubscriptionPlan = 'SUBSCRIPTION_PLAN',
  Chat = 'CHAT',
  Application = 'APPLICATION'
}

export enum CmsFileUsageEnum {
  PayoutReceipt = 'PAYOUT_RECEIPT'
}

export enum FileTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Audio = 'AUDIO',
  Other = 'OTHER'
}

export enum UploadStatusEnum {
  Saved = 'SAVED',
  Active = 'ACTIVE',
  Removed = 'REMOVED'
}

export type UploadFileInput = {
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  postId?: Maybe<Scalars['String']>;
  fileUsage: FileUsageEnum;
};

export type IntrinsicSizeInput = {
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type UploadInput = {
  _id: Scalars['ID'];
  uploadType: UploadTypeEnum;
  url: Scalars['String'];
  fileType: FileTypeEnum;
  filepath?: Maybe<Scalars['String']>;
  hasAlpha?: Maybe<Scalars['Boolean']>;
  intrinsicSize?: Maybe<IntrinsicSizeInput>;
};

export type Post = {
  __typename?: 'Post';
  _id: Scalars['ID'];
  id: Scalars['String'];
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  views: Scalars['Int'];
  likes: Scalars['Int'];
  /** @deprecated This field has been seperated into userBlurLevel and postBlurLevel */
  blur?: Maybe<Scalars['Int']>;
  userBlurLevel?: Maybe<Scalars['Int']>;
  /** @deprecated This field has been replaced by isUserContentLocked */
  userVisibleHeight?: Maybe<Scalars['Int']>;
  postBlurLevel?: Maybe<Scalars['Int']>;
  /** @deprecated This field has been replaced by isContentLocked */
  visibleHeight?: Maybe<Scalars['Int']>;
  postStatus: PostStatusEnum;
  writer: User;
  writerId: Scalars['String'];
  subscriptionPlanId?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<UploadShort>>>;
  content?: Maybe<RichTextContent>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  previewImage?: Maybe<UploadShort>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  multipleImages: Scalars['Boolean'];
  channelId?: Maybe<Scalars['String']>;
  isSensitive: Scalars['Boolean'];
  channel?: Maybe<Channel>;
  commentCount: Scalars['Int'];
  watermarkPosition?: Maybe<WatermarkPositionEnum>;
  hasCurrentUserReport: Scalars['Boolean'];
  hasCurrentUserLike: Scalars['Boolean'];
  ownerBlurImageUrl?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  postType: PostTypeEnum;
  previewContent?: Maybe<Scalars['String']>;
  isContentLocked?: Maybe<Scalars['Boolean']>;
  isUserContentLocked: Scalars['Boolean'];
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyEnum>;
  cid?: Maybe<Scalars['String']>;
  asset?: Maybe<NftInfo>;
  videoUri?: Maybe<Scalars['String']>;
  publishAt?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type PostImagesArgs = {
  signedUrlCount?: Maybe<Scalars['Int']>;
  isOriginalImage?: Maybe<Scalars['Boolean']>;
};

export type LikedPost = {
  __typename?: 'LikedPost';
  _id: Scalars['ID'];
  postId: Scalars['String'];
  userId: Scalars['String'];
  post: Post;
};

export type PostPage = {
  __typename?: 'PostPage';
  items: Array<Maybe<Post>>;
  paging: CursorPagingInfo;
};

export type LikedPostPage = {
  __typename?: 'LikedPostPage';
  items: Array<Maybe<LikedPost>>;
  paging: CursorPagingInfo;
};

export type MappedPostPage = {
  __typename?: 'MappedPostPage';
  items: Array<Maybe<Post>>;
  paging: CursorPagingInfo;
};

export type NftInfo = {
  __typename?: 'NftInfo';
  id: Scalars['String'];
  owner: Scalars['String'];
  initialOwner: Scalars['String'];
  price: Scalars['String'];
  listStatus: Scalars['Boolean'];
  cid?: Maybe<Scalars['String']>;
};

export enum PostStatusEnum {
  Published = 'PUBLISHED',
  Removed = 'REMOVED',
  Drafted = 'DRAFTED',
  Banned = 'BANNED'
}

export enum WatermarkPositionEnum {
  Clear = 'CLEAR',
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT'
}

export enum PostReportStatusEnum {
  Processing = 'PROCESSING',
  Reviewed = 'REVIEWED'
}

export enum PostTypeEnum {
  Article = 'ARTICLE',
  Normal = 'NORMAL',
  Nft = 'NFT',
  Eth721 = 'ETH721',
  Video = 'VIDEO'
}

export type UpdatePostInput = {
  _id?: Maybe<Scalars['ID']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  postBlurLevel?: Maybe<Scalars['Int']>;
  visibleHeight?: Maybe<Scalars['Int']>;
  postStatus: PostStatusEnum;
  content?: Maybe<UpdateRichTextInput>;
  tags?: Maybe<Array<Maybe<TagInput>>>;
  images?: Maybe<Array<Maybe<UploadInput>>>;
  watermarkPosition?: Maybe<WatermarkPositionEnum>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  postType?: Maybe<PostTypeEnum>;
  previewContent?: Maybe<Scalars['String']>;
  videoUri?: Maybe<Scalars['String']>;
  publishAt?: Maybe<Scalars['String']>;
};

export type ReportPostInput = {
  postId: Scalars['String'];
  reporterId: Scalars['String'];
  content: Scalars['String'];
  writerId: Scalars['String'];
};

export type PostPageInput = {
  tagValue?: Maybe<Scalars['String']>;
  writerId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  latest?: Maybe<Scalars['Boolean']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  showSensitiveContent?: Maybe<Scalars['Boolean']>;
  showPaidContent?: Maybe<Scalars['Boolean']>;
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>>>;
};

export type BlurPostCount = {
  __typename?: 'BlurPostCount';
  currentMonth?: Maybe<Scalars['Int']>;
  lastMonth?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type AccountName = {
  __typename?: 'AccountName';
  validationStatus: AccountNameValidationStatusEnum;
  suggestions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreatorType = {
  __typename?: 'CreatorType';
  tier: CreatorTypeEnum;
  updatedAt: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  loginMethod?: Maybe<LoginMethodEnum>;
  accountName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  avatar?: Maybe<UploadShort>;
  banner?: Maybe<UploadShort>;
  gender: GenderEnum;
  about?: Maybe<RichTextContent>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  showSensitiveContent: Scalars['Boolean'];
  userStatus: UserStatusEnum;
  userType: UserTypeEnum;
  creatorType: CreatorType;
  locale?: Maybe<LocaleEnum>;
  blurPostCount: BlurPostCount;
  hasCurrentUserFollowed?: Maybe<Scalars['Boolean']>;
  shortCaption?: Maybe<Scalars['String']>;
  postCount: Scalars['Int'];
  currentMonthPostCount: Scalars['Int'];
  followerCount: Scalars['Int'];
  followingCount: Scalars['Int'];
  subscriberCount: Scalars['Int'];
  channels: Array<Maybe<Channel>>;
  notificationLastSeen?: Maybe<Scalars['String']>;
  messageLastSeen?: Maybe<Scalars['String']>;
  pubnubAuthKey?: Maybe<Scalars['String']>;
  walletAccount?: Maybe<Scalars['String']>;
  creditBalance?: Maybe<Scalars['Int']>;
};


export type UserAvatarArgs = {
  imageSize?: Maybe<ImageSizeInput>;
};


export type UserBannerArgs = {
  imageSize?: Maybe<ImageSizeInput>;
};


export type UserPostCountArgs = {
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>>>;
};


export type UserCurrentMonthPostCountArgs = {
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>>>;
};

export type UserPage = {
  __typename?: 'UserPage';
  users: Array<Maybe<User>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
  pageToken?: Maybe<Scalars['String']>;
};

export enum GenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER'
}

export enum LocaleEnum {
  Zh = 'ZH',
  En = 'EN'
}

export enum UserStatusEnum {
  Active = 'ACTIVE',
  Removed = 'REMOVED',
  Banned = 'BANNED'
}

export enum UserTypeEnum {
  NormalUser = 'NORMAL_USER',
  Creator = 'CREATOR'
}

export enum CreatorTypeEnum {
  Lite = 'LITE',
  Pro = 'PRO'
}

export enum AccountNameValidationStatusEnum {
  Valid = 'VALID',
  Used = 'USED',
  Inappropriate = 'INAPPROPRIATE'
}

export enum LoginMethodEnum {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
  Email = 'EMAIL',
  Unknown = 'UNKNOWN'
}

export type UpdateUserInput = {
  _id?: Maybe<Scalars['ID']>;
  accountName?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['String']>;
  avatar?: Maybe<UserUploadInput>;
  banner?: Maybe<UserUploadInput>;
  gender?: Maybe<GenderEnum>;
  about?: Maybe<UpdateRichTextInput>;
  locale?: Maybe<LocaleEnum>;
  shortCaption?: Maybe<Scalars['String']>;
  showSensitiveContent?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  id?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
};

export type UserUploadInput = {
  intrinsicSize: IntrinsicSizeInput;
  url: Scalars['String'];
  filepath: Scalars['String'];
};

export type TagInput = {
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  bgImage?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  _id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  bgImage?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  hasCurrentUserFollowed?: Maybe<Scalars['Boolean']>;
};

export enum SubscriptionPlanStatusEnum {
  Published = 'PUBLISHED',
  Removed = 'REMOVED',
  Banned = 'BANNED'
}

export enum CurrentUserSubscriptionStatusEnum {
  Subscribe = 'SUBSCRIBE',
  Subscribed = 'SUBSCRIBED',
  Upgrade = 'UPGRADE',
  SubscribedWithHigherPrice = 'SUBSCRIBED_WITH_HIGHER_PRICE',
  Owner = 'OWNER',
  InvalidSubscriptionPlan = 'INVALID_SUBSCRIPTION_PLAN'
}

export enum SubscriptionPlanBenefitsEnum {
  PrivateMessages = 'PRIVATE_MESSAGES',
  UnlockContent = 'UNLOCK_CONTENT',
  UnblurLevel1 = 'UNBLUR_LEVEL1',
  UnblurLevel2 = 'UNBLUR_LEVEL2',
  UnblurLevel3 = 'UNBLUR_LEVEL3',
  Comments = 'COMMENTS'
}

export enum SubscriptionPlanPeriodEnum {
  Monthly = 'MONTHLY',
  HalfYearly = 'HALF_YEARLY',
  Creation = 'CREATION'
}

export type UpdateSubscriptionPlanInput = {
  _id: Scalars['ID'];
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  channelIds: Array<Scalars['String']>;
  subscriptionPlanStatus: SubscriptionPlanStatusEnum;
  content?: Maybe<UpdateRichTextInput>;
  image?: Maybe<UploadInput>;
  price: Scalars['Float'];
  plan?: Maybe<SubscriptionPlanPeriodEnum>;
  benefits?: Maybe<Array<Maybe<SubscriptionPlanBenefitsEnum>>>;
  currency?: Maybe<CurrencyEnum>;
  fileInput?: Maybe<UploadFileInput>;
};

export type CreateSubscriptionPlanInput = {
  title?: Maybe<Scalars['String']>;
  channelIds: Array<Scalars['String']>;
  content?: Maybe<UpdateRichTextInput>;
  image?: Maybe<UploadInput>;
  price: Scalars['Float'];
  plan?: Maybe<SubscriptionPlanPeriodEnum>;
  benefits?: Maybe<Array<Maybe<SubscriptionPlanBenefitsEnum>>>;
  currency?: Maybe<CurrencyEnum>;
  fileInput?: Maybe<UploadFileInput>;
};

export type SubscriptionPlanStatusHistory = {
  __typename?: 'SubscriptionPlanStatusHistory';
  action: SubscriptionPlanStatusEnum;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  _id: Scalars['ID'];
  id: Scalars['String'];
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  channelIds: Array<Maybe<Scalars['String']>>;
  image?: Maybe<UploadShort>;
  price: Scalars['Float'];
  plan?: Maybe<SubscriptionPlanPeriodEnum>;
  subscriptionPlanStatusHistory?: Maybe<Array<Maybe<SubscriptionPlanStatusHistory>>>;
  subscriptionPlanStatus: SubscriptionPlanStatusEnum;
  writerId: Scalars['String'];
  content?: Maybe<RichTextContent>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  benefits?: Maybe<Array<Maybe<SubscriptionPlanBenefitsEnum>>>;
  currency?: Maybe<CurrencyEnum>;
  currentUserSubscriptionStatus: CurrentUserSubscriptionStatusEnum;
  writer?: Maybe<User>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  posts?: Maybe<Array<Maybe<Post>>>;
};

export type SubscriptionPlanPage = {
  __typename?: 'SubscriptionPlanPage';
  items?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  pageToken?: Maybe<Scalars['String']>;
};

export type RichTextContent = {
  __typename?: 'RichTextContent';
  stringifyed?: Maybe<Scalars['String']>;
  serialized?: Maybe<Scalars['String']>;
};

export type UpdateRichTextInput = {
  stringifyed?: Maybe<Scalars['String']>;
  serialized?: Maybe<Scalars['String']>;
};

export type Like = {
  __typename?: 'Like';
  _id: Scalars['ID'];
  postId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type LikeInput = {
  postId: Scalars['String'];
};

export enum CurrencyEnum {
  Usd = 'USD',
  Hkd = 'HKD'
}

export enum UserSubscriptionPlanStatusEnum {
  Active = 'ACTIVE',
  Cancel = 'CANCEL',
  CancelledBeforePaid = 'CANCELLED_BEFORE_PAID',
  CancelledByUser = 'CANCELLED_BY_USER',
  PaymentFailed = 'PAYMENT_FAILED',
  Deleted = 'DELETED'
}

export enum UserSubscriptionPlanTypeEnum {
  Normal = 'NORMAL',
  SinglePurchase = 'SINGLE_PURCHASE'
}

export enum UserSubscriptionPlanBadgeEnum {
  SinglePurchase = 'SINGLE_PURCHASE',
  Active = 'ACTIVE',
  ActiveUntilMonth = 'ACTIVE_UNTIL_MONTH',
  PaymentFailed = 'PAYMENT_FAILED',
  Cancel = 'CANCEL',
  CancelWithHeightPrice = 'CANCEL_WITH_HEIGHT_PRICE'
}

export type SubscriberPageInput = {
  writerId: Scalars['String'];
  subscriptionPlanId?: Maybe<Scalars['String']>;
  period?: Maybe<PeriodInput>;
  userSubscriptionPlanStatus: Array<UserSubscriptionPlanStatusEnum>;
};

export type UserSubscriptionPageInput = {
  userId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type SubscriberPageForCmsInput = {
  writerId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  period?: Maybe<PeriodInput>;
  userSubscriptionPlanStatus?: Maybe<Array<UserSubscriptionPlanStatusEnum>>;
};

export type UserSubscriptionPlan = {
  __typename?: 'UserSubscriptionPlan';
  _id: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  userId: Scalars['String'];
  subscriptionPlanId: Scalars['String'];
  writerId?: Maybe<Scalars['String']>;
  paymentPeriod: SubscriptionPlanPeriodEnum;
  userSubscriptionPlanStatus: UserSubscriptionPlanStatusEnum;
  userSubscriptionPlanType: UserSubscriptionPlanTypeEnum;
  subscriptionPlan: SubscriptionPlan;
  startAt?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  cancelledAt?: Maybe<Scalars['String']>;
  user: User;
  writer: User;
  latestInvoicesStatus?: Maybe<UserInvoiceTypeEnum>;
  currentSubscriptionStatus?: Maybe<UserSubscriptionPlanBadgeEnum>;
  priceBreakdown?: Maybe<PriceBreakdown>;
};

export type PriceBreakdown = {
  __typename?: 'priceBreakdown';
  price?: Maybe<Scalars['Float']>;
  totalIncome?: Maybe<Scalars['Float']>;
  providerFee?: Maybe<Scalars['Float']>;
  platformFee?: Maybe<Array<Maybe<PlatformfeeBreakDown>>>;
  totalPeriod?: Maybe<Scalars['Int']>;
};

export type PlatformfeeBreakDown = {
  __typename?: 'platformfeeBreakDown';
  subscriptionPeriod: Scalars['Int'];
  writerPlatformFee: Scalars['Float'];
};

export type CreateUserSubscriptionPlanResponse = {
  __typename?: 'CreateUserSubscriptionPlanResponse';
  paymentMethodId: Scalars['String'];
  priceId: Scalars['String'];
  subscription?: Maybe<Stripe_Subscription>;
};

export type StripePaymentResponse = {
  __typename?: 'StripePaymentResponse';
  success: Scalars['Boolean'];
  requiresAction: Scalars['Boolean'];
  paymentIntentClientSecret: Scalars['String'];
};

export type UserSubscriptionPlanPage = {
  __typename?: 'UserSubscriptionPlanPage';
  items: Array<Maybe<UserSubscriptionPlan>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  pageToken?: Maybe<Scalars['String']>;
};

export type UserSubscriptionPage = {
  __typename?: 'UserSubscriptionPage';
  items: Array<Maybe<UserSubscriptionPlan>>;
  paging: CursorPagingInfo;
};

export type FollowUser = {
  __typename?: 'FollowUser';
  _id: Scalars['ID'];
  writerId: Scalars['ID'];
  userId: Scalars['ID'];
  writer: User;
  user: User;
  createdAt: Scalars['String'];
};

export type FollowUserPage = {
  __typename?: 'FollowUserPage';
  items: Array<Maybe<FollowUser>>;
  paging: CursorPagingInfo;
};

export type FollowTag = {
  __typename?: 'FollowTag';
  tagId: Scalars['ID'];
  userId: Scalars['ID'];
  tag?: Maybe<Tag>;
};

export type FollowTagPage = {
  __typename?: 'FollowTagPage';
  items: Array<Maybe<FollowTag>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
  pageToken?: Maybe<Scalars['String']>;
};

export enum ChannelStatusEnum {
  Published = 'PUBLISHED',
  Pending = 'PENDING',
  Removed = 'REMOVED',
  Banned = 'BANNED'
}

export type Channel = {
  __typename?: 'Channel';
  _id: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
  tagIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  creatorId: Scalars['String'];
  creator: User;
  /** @deprecated This field has been replaced by CreatorId */
  editorIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isSensitive: Scalars['Boolean'];
  isCommentRestricted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  channelStatus: ChannelStatusEnum;
  postCount: Scalars['Int'];
  currentMonthPostCount: Scalars['Int'];
  followerCount: Scalars['Int'];
  subscriberCount: Scalars['Int'];
  subscriptionCount: Scalars['Int'];
  blurPostCount: BlurPostCount;
};


export type ChannelPostCountArgs = {
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>>>;
};


export type ChannelCurrentMonthPostCountArgs = {
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>>>;
};

export type UpdateChannelResponse = {
  __typename?: 'UpdateChannelResponse';
  channel: Channel;
  notification?: Maybe<Notification>;
};

export type UpdateChannelInput = {
  _id: Scalars['String'];
  title: Scalars['String'];
  isSensitive: Scalars['Boolean'];
  isCommentRestricted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  relatedUserId?: Maybe<Scalars['String']>;
  relatedUser?: Maybe<User>;
  postId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  userPayoutId?: Maybe<Scalars['String']>;
  userPayout?: Maybe<UserPayout>;
  commentId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  type: NotificationTypeEnum;
  notificationStatus: NotificationStatusEnum;
  startAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  transactionHash?: Maybe<Scalars['String']>;
};

export type NotificationPage = {
  __typename?: 'NotificationPage';
  items: Array<Maybe<Notification>>;
  count: Scalars['Int'];
  paging: CursorPagingInfo;
};

export type NftNotificationResponse = {
  __typename?: 'NftNotificationResponse';
  notification?: Maybe<Notification>;
  asset?: Maybe<NftInfo>;
};

export enum NotificationTypeEnum {
  PostNew = 'POST_NEW',
  PostChannelNew = 'POST_CHANNEL_NEW',
  PostEdit = 'POST_EDIT',
  SubscriptionPlanNew = 'SUBSCRIPTION_PLAN_NEW',
  SubscriptionPlanUpdate = 'SUBSCRIPTION_PLAN_UPDATE',
  SubscriptionPlanRemove = 'SUBSCRIPTION_PLAN_REMOVE',
  SubscriptionPlanSpecialOffer = 'SUBSCRIPTION_PLAN_SPECIAL_OFFER',
  SubscriptionSuccess = 'SUBSCRIPTION_SUCCESS',
  SubscriptionCancel = 'SUBSCRIPTION_CANCEL',
  SubscriptionReactivate = 'SUBSCRIPTION_REACTIVATE',
  PurchaseSuccess = 'PURCHASE_SUCCESS',
  PaymentFail = 'PAYMENT_FAIL',
  UserNew = 'USER_NEW',
  Comment = 'COMMENT',
  UserBanned = 'USER_BANNED',
  UserUnbanned = 'USER_UNBANNED',
  FollowerChannelNew = 'FOLLOWER_CHANNEL_NEW',
  FollowerNew = 'FOLLOWER_NEW',
  SubscriptionNew = 'SUBSCRIPTION_NEW',
  PurchaseNew = 'PURCHASE_NEW',
  PostLike = 'POST_LIKE',
  PostComment = 'POST_COMMENT',
  PostBanned = 'POST_BANNED',
  PostUnbanned = 'POST_UNBANNED',
  PayoutSucceeded = 'PAYOUT_SUCCEEDED',
  PayoutFailed = 'PAYOUT_FAILED',
  PayoutRefunded = 'PAYOUT_REFUNDED',
  PayoutUnclaimed = 'PAYOUT_UNCLAIMED',
  PayoutProcessing = 'PAYOUT_PROCESSING',
  CreatorSubscriptionPlanUnbanned = 'CREATOR_SUBSCRIPTION_PLAN_UNBANNED',
  CreatorSubscriptionPlanBanned = 'CREATOR_SUBSCRIPTION_PLAN_BANNED',
  CreatorSubscriptionPlanUpdate = 'CREATOR_SUBSCRIPTION_PLAN_UPDATE',
  NftConfirmpurchaseNotifyBuyer = 'NFT_CONFIRMPURCHASE_NOTIFY_BUYER',
  NftConfirmpurchaseNotifySeller = 'NFT_CONFIRMPURCHASE_NOTIFY_SELLER',
  NftConfirmlist = 'NFT_CONFIRMLIST',
  NftConfirmCancellist = 'NFT_CONFIRM_CANCELLIST',
  NftConfirmUpdatelist = 'NFT_CONFIRM_UPDATELIST',
  ChannelNewNft = 'CHANNEL_NEW_NFT',
  ApplicationCreatorSubmitted = 'APPLICATION_CREATOR_SUBMITTED',
  ApplicationCreatorRejected = 'APPLICATION_CREATOR_REJECTED',
  ApplicationCreatorApproved = 'APPLICATION_CREATOR_APPROVED',
  ApplicationCreatorReexamine = 'APPLICATION_CREATOR_REEXAMINE',
  ChannelStatusPublished = 'CHANNEL_STATUS_PUBLISHED',
  ChannelStatusPending = 'CHANNEL_STATUS_PENDING',
  ChannelStatusBanned = 'CHANNEL_STATUS_BANNED',
  ChannelStatusRemoved = 'CHANNEL_STATUS_REMOVED',
  ChannelStatusRemovedByAdmin = 'CHANNEL_STATUS_REMOVED_BY_ADMIN'
}

export enum NotificationStatusEnum {
  Read = 'READ',
  NotRead = 'NOT_READ',
  Removed = 'REMOVED'
}

export type UpdateNotificationInput = {
  _id: Scalars['String'];
  notificationStatus: NotificationStatusEnum;
};

export type ConfirmNftPurchaseInput = {
  transactionHash: Scalars['String'];
  tokenId: Scalars['String'];
};

export enum CommentStatusEnum {
  Active = 'ACTIVE',
  Removed = 'REMOVED',
  Banned = 'BANNED'
}

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['String'];
  parentCommentId?: Maybe<Scalars['String']>;
  postWriterId: Scalars['String'];
  postId: Scalars['String'];
  userId: Scalars['String'];
  mentionUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  content: Scalars['String'];
  commentStatus: CommentStatusEnum;
  updatedAt: Scalars['String'];
  createdAt: Scalars['String'];
  user: User;
  mentionUsers?: Maybe<Array<Maybe<User>>>;
  numberOfReplies: Scalars['Int'];
};

export type CommentPage = {
  __typename?: 'CommentPage';
  items: Array<Maybe<Comment>>;
  paging: CursorPagingInfo;
};

export type CreateCommentInput = {
  content: Scalars['String'];
  parentCommentId?: Maybe<Scalars['String']>;
  postId: Scalars['String'];
  mentionUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommentPageInput = {
  postId: Scalars['String'];
  parentCommentId?: Maybe<Scalars['String']>;
};

export type FollowChannel = {
  __typename?: 'FollowChannel';
  _id: Scalars['ID'];
  userId: Scalars['ID'];
  channelId: Scalars['ID'];
  createdAt: Scalars['String'];
  user: User;
  channel: Channel;
};

export type FollowChannelPage = {
  __typename?: 'FollowChannelPage';
  items: Array<Maybe<FollowChannel>>;
  paging: CursorPagingInfo;
};

export type FollowChannelQueryInput = {
  userId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['String']>;
};

export type Chat = {
  __typename?: 'Chat';
  _id: Scalars['ID'];
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastMessage?: Maybe<Message>;
  users?: Maybe<Array<Maybe<User>>>;
  relatedUser?: Maybe<User>;
};

export type InitialChat = {
  __typename?: 'InitialChat';
  _id?: Maybe<Scalars['ID']>;
  relatedUser?: Maybe<User>;
};

export type ChatPage = {
  __typename?: 'ChatPage';
  items?: Maybe<Array<Maybe<Chat>>>;
  paging: CursorPagingInfo;
};

export enum MessageStatusEnum {
  Sending = 'SENDING',
  Active = 'ACTIVE',
  Edited = 'EDITED',
  Removed = 'REMOVED'
}

export type Message = {
  __typename?: 'Message';
  _id: Scalars['ID'];
  chatId: Scalars['String'];
  senderId: Scalars['String'];
  receiverId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<UploadShort>>>;
  read: Scalars['Boolean'];
  messageStatus: MessageStatusEnum;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  sender: User;
  receiver: User;
};

export type MessagePage = {
  __typename?: 'MessagePage';
  items?: Maybe<Array<Maybe<Message>>>;
  paging: CursorPagingInfo;
  /**
   * used in mutation update function only, no resolver is needed
   * @deprecated using cache modify will return directly (skipping the merge fn in type policies
   */
  mergedManually?: Maybe<Scalars['Boolean']>;
};

export type CreateMessageInput = {
  _id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  files?: Maybe<UploadFileInput>;
  receiverId: Scalars['String'];
  chatId: Scalars['String'];
};

export type UpdateMessageInput = {
  message: Scalars['String'];
  messageId: Scalars['String'];
};

export type Card = {
  __typename?: 'Card';
  lastFourDigits: Scalars['String'];
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
};

export enum PaymentMethodStatusEnum {
  Default = 'DEFAULT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Removed = 'REMOVED'
}

export enum PaymentMethodProviderEnum {
  Stripe = 'STRIPE',
  Stripe2 = 'STRIPE2',
  Credit = 'CREDIT'
}

export type Stripe_Payment_Intent = {
  __typename?: 'stripe_payment_intent';
  status: Scalars['String'];
  client_secret: Scalars['String'];
};

export type Stripe_Invoice = {
  __typename?: 'stripe_invoice';
  payment_intent?: Maybe<Stripe_Payment_Intent>;
};

export type Stripe_Subscription = {
  __typename?: 'stripe_subscription';
  id: Scalars['String'];
  status: Scalars['String'];
  latest_invoice: Stripe_Invoice;
};

export type PaymentMethodDetail = {
  __typename?: 'PaymentMethodDetail';
  card?: Maybe<Card>;
};

export type UserPaymentMethod = {
  __typename?: 'UserPaymentMethod';
  _id: Scalars['String'];
  userId: Scalars['String'];
  refId: Scalars['String'];
  provider: PaymentMethodProviderEnum;
  paymentMethodStatus: PaymentMethodStatusEnum;
  paymentMethodDetail: PaymentMethodDetail;
};

export type Invoice = {
  __typename?: 'Invoice';
  url?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
};

export type UserInvoice = {
  __typename?: 'UserInvoice';
  _id: Scalars['String'];
  id: Scalars['String'];
  userId: Scalars['String'];
  refId: Scalars['String'];
  invoice?: Maybe<Invoice>;
  subscriptionPlanId: Scalars['String'];
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  provider: PaymentMethodProviderEnum;
  userInvoiceStatus: UserInvoiceStatusEnum;
  refundedAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<UserInvoiceTypeEnum>;
  startAt?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  writerIncome: Scalars['Float'];
  writerPlatformFee: Scalars['Float'];
  writerProviderFee: Scalars['Float'];
};

export type UserInvoicePage = {
  __typename?: 'UserInvoicePage';
  items: Array<Maybe<UserInvoice>>;
  paging: CursorPagingInfo;
};

export enum UserInvoiceStatusEnum {
  Paid = 'PAID',
  Open = 'OPEN',
  Refunded = 'REFUNDED',
  Duplicated = 'DUPLICATED'
}

export enum UserInvoiceTypeEnum {
  Subscription = 'SUBSCRIPTION',
  SinglePurchase = 'SINGLE_PURCHASE',
  Tips = 'TIPS'
}

export type TopPost = {
  __typename?: 'TopPost';
  _id: Scalars['ID'];
  postId: Scalars['String'];
  locale: LocaleEnum;
  likes: Scalars['Int'];
  post?: Maybe<Post>;
};

export type TopTag = {
  __typename?: 'TopTag';
  _id: Scalars['ID'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type TopWriter = {
  __typename?: 'TopWriter';
  _id: Scalars['ID'];
  userId: Scalars['String'];
  locale: LocaleEnum;
  score: Scalars['Int'];
  user?: Maybe<User>;
};

export type TopWriterPage = {
  __typename?: 'TopWriterPage';
  items?: Maybe<Array<Maybe<TopWriter>>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  count: Scalars['Int'];
  pageToken?: Maybe<Scalars['String']>;
};

export enum UpdatePeriodEnum {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CreateUserReceivePaymentMethodInput = {
  provider: ReceivePaymentMethodProviderEnum;
  email?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAccountHolder?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type UpdateUserReceivePaymentMethodInput = {
  _id: Scalars['String'];
  provider: ReceivePaymentMethodProviderEnum;
  email?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAccountHolder?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type UserReceivePaymentMethod = {
  __typename?: 'UserReceivePaymentMethod';
  _id: Scalars['String'];
  userId: Scalars['String'];
  autoTransfer: Scalars['Boolean'];
  userReceivePaymentMethodStatus: UserReceivePaymentMethodStatusEnum;
  provider: ReceivePaymentMethodProviderEnum;
  email?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAccountHolder?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export enum UserReceivePaymentMethodStatusEnum {
  Active = 'ACTIVE',
  Default = 'DEFAULT',
  Inactive = 'INACTIVE'
}

export enum ReceivePaymentMethodProviderEnum {
  Paypal = 'PAYPAL',
  Payoneer = 'PAYONEER',
  Direct = 'DIRECT'
}

export type CreateUserPayoutInput = {
  value: Scalars['Float'];
  provider: ReceivePaymentMethodProviderEnum;
  currency: CurrencyEnum;
};

export type UserPayout = {
  __typename?: 'UserPayout';
  _id: Scalars['String'];
  userId: Scalars['String'];
  currency: CurrencyEnum;
  value: Scalars['Float'];
  userPayoutStatus: UserPayoutStatusEnum;
  provider: ReceivePaymentMethodProviderEnum;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
};

export type UserPayoutPage = {
  __typename?: 'UserPayoutPage';
  items: Array<Maybe<UserPayout>>;
  paging: CursorPagingInfo;
};

export enum UserPayoutStatusEnum {
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED',
  Refunded = 'REFUNDED',
  Unclaimed = 'UNCLAIMED',
  Processing = 'PROCESSING'
}

export enum UserPayoutRequestedByEnum {
  User = 'USER',
  Auto = 'AUTO',
  Admin = 'ADMIN'
}

export type PeriodInput = {
  startAt: Scalars['String'];
  endAt: Scalars['String'];
};

export type UserWallet = {
  __typename?: 'UserWallet';
  _id: Scalars['ID'];
  id: Scalars['String'];
  userId: Scalars['String'];
  income: Scalars['Float'];
  withdrawal: Scalars['Float'];
  currency: CurrencyEnum;
  openBalance: Scalars['Float'];
  startAt: Scalars['String'];
  detail: UserWalletBalance;
};

export type UserWalletBalance = {
  __typename?: 'UserWalletBalance';
  totalBalance: Scalars['Float'];
  balance: Scalars['Float'];
  isWithdrewOrProcessing: Scalars['Boolean'];
  balanceExcludeProcessingAmount: Scalars['Float'];
};

export type ImageSizeInput = {
  maxWidth?: Maybe<Scalars['Int']>;
  maxHeight?: Maybe<Scalars['Int']>;
};

export enum FeatureTypeEnum {
  PayoutMethod = 'PAYOUT_METHOD'
}

export enum FeatureNameEnum {
  Tips = 'TIPS',
  OfflinePayment = 'OFFLINE_PAYMENT',
  Subscription = 'SUBSCRIPTION',
  Paypal = 'PAYPAL',
  Direct = 'DIRECT',
  Pyoneer = 'PYONEER',
  Nft = 'NFT',
  DirectMessage = 'DIRECT_MESSAGE',
  Search = 'SEARCH',
  VideoPost = 'VIDEO_POST'
}

export enum TransactionActionTypeEnum {
  Mint = 'MINT',
  ApproveAll = 'APPROVE_ALL',
  List = 'LIST',
  CancelList = 'CANCEL_LIST',
  Buy = 'BUY',
  GenerateIpfsCid = 'GENERATE_IPFS_CID',
  UpdateTokenCid = 'UPDATE_TOKEN_CID',
  UpdateListedPrice = 'UPDATE_LISTED_PRICE'
}

export type Application = {
  __typename?: 'Application';
  _id: Scalars['ID'];
  userId: Scalars['String'];
  name: Scalars['String'];
  bankAccount: Scalars['String'];
  address: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  identityDocument: UploadShort;
  status: ApplicationStatusEnum;
  type: ApplicationTypeEnum;
};

export type SubmittedApplicationResponse = {
  __typename?: 'SubmittedApplicationResponse';
  application: Application;
  notification?: Maybe<Notification>;
};

export enum ApplicationTypeEnum {
  Creator = 'CREATOR'
}

export enum ApplicationStatusEnum {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type CreateApplicationInput = {
  name: Scalars['String'];
  address: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  type: ApplicationTypeEnum;
};

export type UpdateApplicationInput = {
  _id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  type: ApplicationTypeEnum;
};

export enum UserRefundStatusEnum {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED'
}


export type ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, '_id' | 'type' | 'status' | 'userId' | 'name' | 'phoneNumber' | 'postalCode' | 'address'>
);

export type CreateApplicationMutationVariables = Exact<{
  input: CreateApplicationInput;
}>;


export type CreateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { createApplication: (
    { __typename?: 'SubmittedApplicationResponse' }
    & { application: (
      { __typename?: 'Application' }
      & ApplicationFragment
    ), notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )> }
  ) }
);

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplicationInput;
}>;


export type UpdateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { updateApplication: (
    { __typename?: 'SubmittedApplicationResponse' }
    & { application: (
      { __typename?: 'Application' }
      & ApplicationFragment
    ), notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )> }
  ) }
);

export type ApplicationQueryVariables = Exact<{
  type: ApplicationTypeEnum;
}>;


export type ApplicationQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & ApplicationFragment
  )> }
);

export type IsTestUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type IsTestUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isTestUser'>
);

export type ChannelFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, '_id' | 'id' | 'title' | 'slug' | 'creatorId' | 'description' | 'isSensitive' | 'isCommentRestricted' | 'channelStatus'>
  & { creator: (
    { __typename?: 'User' }
    & Pick<User, 'accountName'>
  ) }
);

export type ChannelPostCountFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'postCount' | 'currentMonthPostCount'>
);

export type ChannelSubscribeContentCountFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'subscriberCount'>
  & { blurPostCount: (
    { __typename?: 'BlurPostCount' }
    & Pick<BlurPostCount, 'currentMonth' | 'lastMonth' | 'total'>
  ) }
);

export type ChannelFollowCountFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'followerCount'>
);

export type CreateChannelMutationVariables = Exact<{
  title: Scalars['String'];
  isSensitive: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  isCommentRestricted?: Maybe<Scalars['Boolean']>;
}>;


export type CreateChannelMutation = (
  { __typename?: 'Mutation' }
  & { createChannel: (
    { __typename?: 'Channel' }
    & ChannelFragment
  ) }
);

export type RemoveChannelMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type RemoveChannelMutation = (
  { __typename?: 'Mutation' }
  & { removeChannel: (
    { __typename?: 'Channel' }
    & ChannelFragment
  ) }
);

export type UpdateChannelMutationVariables = Exact<{
  input: UpdateChannelInput;
}>;


export type UpdateChannelMutation = (
  { __typename?: 'Mutation' }
  & { updateChannel: (
    { __typename?: 'UpdateChannelResponse' }
    & { channel: (
      { __typename?: 'Channel' }
      & ChannelFragment
    ), notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )> }
  ) }
);

export type ChannelsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>> | Maybe<PostTypeEnum>>;
  channelStatus?: Maybe<Array<ChannelStatusEnum> | ChannelStatusEnum>;
  skipCount?: Maybe<Scalars['Boolean']>;
}>;


export type ChannelsQuery = (
  { __typename?: 'Query' }
  & { channels?: Maybe<Array<Maybe<(
    { __typename?: 'Channel' }
    & MakeOptional<Pick<Channel, 'postCount' | 'followerCount' | 'subscriptionCount'>, 'postCount' | 'followerCount' | 'subscriptionCount'>
    & ChannelFragment
  )>>> }
);

export type ProfileChannelQueryVariables = Exact<{
  channelSlug: Scalars['String'];
}>;


export type ProfileChannelQuery = (
  { __typename?: 'Query' }
  & { channel?: Maybe<(
    { __typename?: 'Channel' }
    & ChannelFragment
    & ChannelFollowCountFragment
  )> }
);

export type ProfileChannelPostCountQueryVariables = Exact<{
  channelSlug: Scalars['String'];
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>> | Maybe<PostTypeEnum>>;
}>;


export type ProfileChannelPostCountQuery = (
  { __typename?: 'Query' }
  & { channel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, '_id'>
    & ChannelPostCountFragment
    & ChannelSubscribeContentCountFragment
  )> }
);

export type CommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, '_id' | 'parentCommentId' | 'postWriterId' | 'postId' | 'userId' | 'mentionUserIds' | 'content' | 'commentStatus' | 'updatedAt' | 'createdAt' | 'numberOfReplies'>
  & { user: (
    { __typename?: 'User' }
    & UserBasicInfoFragment
  ), mentionUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & UserBasicInfoFragment
  )>>> }
);

export type RemoveCommentMutationVariables = Exact<{
  commentId: Scalars['String'];
}>;


export type RemoveCommentMutation = (
  { __typename?: 'Mutation' }
  & { removeComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, '_id' | 'parentCommentId' | 'commentStatus' | 'postId'>
  ) }
);

export type CommentPageQueryVariables = Exact<{
  input: CommentPageInput;
  avatarSize?: Maybe<ImageSizeInput>;
  paging?: Maybe<CursorPagingInput>;
}>;


export type CommentPageQuery = (
  { __typename?: 'Query' }
  & { commentPage?: Maybe<(
    { __typename?: 'CommentPage' }
    & { items: Array<Maybe<(
      { __typename?: 'Comment' }
      & CommentFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  )> }
);

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'Comment' }
    & CommentFragment
  ) }
);

export type CursorPagingInfoFragment = (
  { __typename?: 'CursorPagingInfo' }
  & Pick<CursorPagingInfo, 'hasNext' | 'next'>
);

export type EnabledFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type EnabledFeaturesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'enabledFeatures'>
);

export type FollowChannelFragment = (
  { __typename?: 'FollowChannel' }
  & Pick<FollowChannel, 'channelId' | 'userId'>
);

export type FollowChannelPageFragment = (
  { __typename?: 'FollowChannelPage' }
  & { items: Array<Maybe<(
    { __typename?: 'FollowChannel' }
    & Pick<FollowChannel, '_id' | 'userId' | 'channelId' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserBasicInfoFragment
    )>, channel: (
      { __typename?: 'Channel' }
      & FollowingChannelFragment
    ) }
  )>>, paging: (
    { __typename?: 'CursorPagingInfo' }
    & CursorPagingInfoFragment
  ) }
);

export type FollowingChannelFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'postCount'>
  & { creator?: Maybe<(
    { __typename?: 'User' }
    & UserBasicInfoFragment
  )> }
  & ChannelFragment
);

export type FollowChannelMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FollowChannelMutation = (
  { __typename?: 'Mutation' }
  & { followChannel: (
    { __typename?: 'FollowChannel' }
    & FollowChannelFragment
  ) }
);

export type UnfollowChannelMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type UnfollowChannelMutation = (
  { __typename?: 'Mutation' }
  & { unfollowChannel: (
    { __typename?: 'FollowChannel' }
    & Pick<FollowChannel, 'channelId' | 'userId'>
  ) }
);

export type FollowChannelPageQueryVariables = Exact<{
  input: FollowChannelQueryInput;
  paging?: Maybe<CursorPagingInput>;
  includeUser: Scalars['Boolean'];
  includeCreator: Scalars['Boolean'];
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type FollowChannelPageQuery = (
  { __typename?: 'Query' }
  & { followChannelPage: (
    { __typename?: 'FollowChannelPage' }
    & FollowChannelPageFragment
  ) }
);

export type FollowChannelDocQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FollowChannelDocQuery = (
  { __typename?: 'Query' }
  & { followChannelDoc?: Maybe<(
    { __typename?: 'FollowChannel' }
    & FollowChannelFragment
  )> }
);

export type FollowTagMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FollowTagMutation = (
  { __typename?: 'Mutation' }
  & { followTag: (
    { __typename?: 'FollowTag' }
    & Pick<FollowTag, 'tagId' | 'userId'>
  ) }
);

export type FollowTagDocQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FollowTagDocQuery = (
  { __typename?: 'Query' }
  & { followTagDoc?: Maybe<(
    { __typename?: 'FollowTag' }
    & Pick<FollowTag, 'tagId' | 'userId'>
  )> }
);

export type FollowedTagFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, '_id' | 'value' | 'label' | 'bgImage'>
);

export type FollowTagPageQueryVariables = Exact<{
  input?: Maybe<SearchInput>;
}>;


export type FollowTagPageQuery = (
  { __typename?: 'Query' }
  & { followTagPage: (
    { __typename?: 'FollowTagPage' }
    & { items: Array<Maybe<(
      { __typename?: 'FollowTag' }
      & Pick<FollowTag, 'tagId' | 'userId'>
      & { tag?: Maybe<(
        { __typename?: 'Tag' }
        & FollowedTagFragment
      )> }
    )>> }
  ) }
);

export type UnfollowTagMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type UnfollowTagMutation = (
  { __typename?: 'Mutation' }
  & { unfollowTag: (
    { __typename?: 'FollowTag' }
    & Pick<FollowTag, 'tagId' | 'userId'>
  ) }
);

export type FollowUserMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FollowUserMutation = (
  { __typename?: 'Mutation' }
  & { followUser: (
    { __typename?: 'FollowUser' }
    & Pick<FollowUser, 'writerId' | 'userId'>
  ) }
);

export type FollowUserDocQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type FollowUserDocQuery = (
  { __typename?: 'Query' }
  & { followUserDoc?: Maybe<(
    { __typename?: 'FollowUser' }
    & Pick<FollowUser, 'writerId' | 'userId'>
  )> }
);

export type FollowUserPageQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  writerId?: Maybe<Scalars['String']>;
  paging?: Maybe<CursorPagingInput>;
  includeUser: Scalars['Boolean'];
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type FollowUserPageQuery = (
  { __typename?: 'Query' }
  & { followUserPage: (
    { __typename?: 'FollowUserPage' }
    & { items: Array<Maybe<(
      { __typename?: 'FollowUser' }
      & Pick<FollowUser, '_id' | 'writerId' | 'userId' | 'createdAt'>
      & { writer?: Maybe<(
        { __typename?: 'User' }
        & UserBasicInfoFragment
        & UserFollowCountFragment
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & UserFollowCountFragment
        & UserBasicInfoFragment
      )> }
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  ) }
);

export type UnfollowUserMutationVariables = Exact<{
  input: Scalars['ID'];
}>;


export type UnfollowUserMutation = (
  { __typename?: 'Mutation' }
  & { unfollowUser: (
    { __typename?: 'FollowUser' }
    & Pick<FollowUser, 'writerId' | 'userId'>
  ) }
);

export type CreateLikeMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type CreateLikeMutation = (
  { __typename?: 'Mutation' }
  & { createLike?: Maybe<(
    { __typename?: 'Like' }
    & LikeRelationshipFragment
  )> }
);

export type LikeRelationshipFragment = (
  { __typename?: 'Like' }
  & Pick<Like, 'postId' | 'userId'>
);

export type LikeQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type LikeQuery = (
  { __typename?: 'Query' }
  & { like?: Maybe<(
    { __typename?: 'Like' }
    & LikeRelationshipFragment
  )> }
);

export type RemoveLikeMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type RemoveLikeMutation = (
  { __typename?: 'Mutation' }
  & { removeLike?: Maybe<(
    { __typename?: 'Like' }
    & LikeRelationshipFragment
  )> }
);

export type ChatFragment = (
  { __typename?: 'Chat' }
  & Pick<Chat, '_id' | 'userIds'>
  & { relatedUser?: Maybe<(
    { __typename?: 'User' }
    & UserBasicInfoFragment
  )>, lastMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'senderId' | 'message' | 'messageStatus' | 'read' | 'createdAt'>
  )> }
);

export type ChatPageQueryVariables = Exact<{
  paging?: Maybe<CursorPagingInput>;
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type ChatPageQuery = (
  { __typename?: 'Query' }
  & { chatPage?: Maybe<(
    { __typename?: 'ChatPage' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Chat' }
      & ChatFragment
    )>>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  )> }
);

export type InitialChatQueryVariables = Exact<{
  input: UserInput;
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type InitialChatQuery = (
  { __typename?: 'Query' }
  & { initialChat?: Maybe<(
    { __typename?: 'InitialChat' }
    & Pick<InitialChat, '_id'>
    & { relatedUser?: Maybe<(
      { __typename?: 'User' }
      & UserBasicInfoFragment
    )> }
  )> }
);

export type MessageFragment = (
  { __typename?: 'Message' }
  & Pick<Message, '_id' | 'chatId' | 'senderId' | 'receiverId' | 'message' | 'read' | 'messageStatus' | 'createdAt' | 'updatedAt'>
  & { files?: Maybe<Array<Maybe<(
    { __typename?: 'UploadShort' }
    & UploadShortFragment
  )>>> }
);

export type ReadMessagesMutationVariables = Exact<{
  senderId: Scalars['String'];
}>;


export type ReadMessagesMutation = (
  { __typename?: 'Mutation' }
  & { readMessages: Array<Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, '_id' | 'read'>
  )>> }
);

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage: (
    { __typename?: 'Message' }
    & MessageFragment
  ) }
);

export type MessagePageQueryVariables = Exact<{
  input: SearchInput;
  currentUserId: Scalars['String'];
  relatedUserId: Scalars['String'];
  paging?: Maybe<CursorPagingInput>;
}>;


export type MessagePageQuery = (
  { __typename?: 'Query' }
  & { messagePage?: Maybe<(
    { __typename?: 'MessagePage' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Message' }
      & MessageFragment
    )>>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  )> }
);

export type HasMessageQueryVariables = Exact<{ [key: string]: never; }>;


export type HasMessageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasMessage'>
);

export type NotificationFullFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, '_id' | 'relatedUserId' | 'postId' | 'subscriptionPlanId' | 'channelId' | 'userPayoutId' | 'commentId' | 'type' | 'notificationStatus' | 'startAt' | 'createdAt' | 'updatedAt'>
  & { relatedUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'accountName' | 'displayName'>
  )>, post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, '_id' | 'slug' | 'title'>
  )>, subscriptionPlan?: Maybe<(
    { __typename?: 'SubscriptionPlan' }
    & Pick<SubscriptionPlan, 'title'>
  )>, channel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'title' | 'slug'>
  )>, userPayout?: Maybe<(
    { __typename?: 'UserPayout' }
    & Pick<UserPayout, 'provider' | 'value' | 'currency'>
  )> }
);

export type ConfirmNftPurchaseNotificationMutationVariables = Exact<{
  transactionHash: Scalars['String'];
}>;


export type ConfirmNftPurchaseNotificationMutation = (
  { __typename?: 'Mutation' }
  & { confirmNftPurchase?: Maybe<(
    { __typename?: 'NftNotificationResponse' }
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )>, asset?: Maybe<(
      { __typename?: 'NftInfo' }
      & NftInfoFragment
    )> }
  )> }
);

export type ConfirmNftListNotificationMutationVariables = Exact<{
  transactionHash: Scalars['String'];
}>;


export type ConfirmNftListNotificationMutation = (
  { __typename?: 'Mutation' }
  & { confirmNftList?: Maybe<(
    { __typename?: 'NftNotificationResponse' }
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )>, asset?: Maybe<(
      { __typename?: 'NftInfo' }
      & NftInfoFragment
    )> }
  )> }
);

export type ConfirmNftCancelListNotificationMutationVariables = Exact<{
  transactionHash: Scalars['String'];
}>;


export type ConfirmNftCancelListNotificationMutation = (
  { __typename?: 'Mutation' }
  & { confirmNftCancelList?: Maybe<(
    { __typename?: 'NftNotificationResponse' }
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )>, asset?: Maybe<(
      { __typename?: 'NftInfo' }
      & NftInfoFragment
    )> }
  )> }
);

export type ConfirmNftUpdateListNotificationMutationVariables = Exact<{
  transactionHash: Scalars['String'];
}>;


export type ConfirmNftUpdateListNotificationMutation = (
  { __typename?: 'Mutation' }
  & { confirmNftUpdateList?: Maybe<(
    { __typename?: 'NftNotificationResponse' }
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )>, asset?: Maybe<(
      { __typename?: 'NftInfo' }
      & NftInfoFragment
    )> }
  )> }
);

export type NotificationPageQueryVariables = Exact<{
  paging?: Maybe<CursorPagingInput>;
  skipCount?: Maybe<Scalars['Boolean']>;
}>;


export type NotificationPageQuery = (
  { __typename?: 'Query' }
  & { notificationPage: (
    { __typename?: 'NotificationPage' }
    & MakeOptional<Pick<NotificationPage, 'count'>, 'count'>
    & { items: Array<Maybe<(
      { __typename?: 'Notification' }
      & NotificationFullFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  ) }
);

export type HasNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type HasNotificationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasNotification'>
);

export type UpdateNotificationStatusMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;


export type UpdateNotificationStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationStatus: (
    { __typename?: 'Notification' }
    & Pick<Notification, '_id' | 'notificationStatus'>
  ) }
);

export type AddUserPaymentMethodStripeMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
  email?: Maybe<Scalars['String']>;
}>;


export type AddUserPaymentMethodStripeMutation = (
  { __typename?: 'Mutation' }
  & { addUserPaymentMethodStripe: (
    { __typename?: 'UserPaymentMethod' }
    & UserPaymentMethodFragment
  ) }
);

export type RemoveUserPaymentMethodMutationVariables = Exact<{
  _id: Scalars['String'];
}>;


export type RemoveUserPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { removeUserPaymentMethod: (
    { __typename?: 'UserPaymentMethod' }
    & UserPaymentMethodFragment
  ) }
);

export type UserPaymentMethodFragment = (
  { __typename?: 'UserPaymentMethod' }
  & Pick<UserPaymentMethod, '_id' | 'refId' | 'userId' | 'provider' | 'paymentMethodStatus'>
  & { paymentMethodDetail: (
    { __typename?: 'PaymentMethodDetail' }
    & { card?: Maybe<(
      { __typename?: 'Card' }
      & CardFragment
    )> }
  ) }
);

export type CardFragment = (
  { __typename?: 'Card' }
  & Pick<Card, 'lastFourDigits' | 'brand' | 'expMonth' | 'expYear'>
);

export type UserPaymentMethodsQueryVariables = Exact<{
  userId: Scalars['String'];
  paymentMethodStatus: Array<PaymentMethodStatusEnum> | PaymentMethodStatusEnum;
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
}>;


export type UserPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { userPaymentMethods: Array<Maybe<(
    { __typename?: 'UserPaymentMethod' }
    & UserPaymentMethodFragment
  )>> }
);

export type PostCommonFragment = (
  { __typename?: 'Post' }
  & Pick<Post, '_id' | 'id' | 'slug' | 'title' | 'postStatus' | 'likes' | 'channelId' | 'writerId' | 'isSensitive' | 'postBlurLevel' | 'visibleHeight' | 'subscriptionPlanId' | 'watermarkPosition' | 'createdAt' | 'updatedAt' | 'postType' | 'tokenId' | 'previewContent' | 'isContentLocked' | 'price' | 'currency' | 'publishAt' | 'isPublished'>
  & { tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'value' | 'label' | 'bgImage'>
  )>>>, asset?: Maybe<(
    { __typename?: 'NftInfo' }
    & NftInfoFragment
  )> }
);

export type PostFullFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'videoUri'>
  & { content?: Maybe<(
    { __typename?: 'RichTextContent' }
    & Pick<RichTextContent, 'stringifyed' | 'serialized'>
  )>, images?: Maybe<Array<Maybe<(
    { __typename?: 'UploadShort' }
    & Pick<UploadShort, 'uploadType' | 'filepath'>
    & UploadWithIntrinsicSizeFragment
  )>>> }
  & PostCommonFragment
);

export type PostPagePostFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'commentCount'>
  & { previewImage?: Maybe<(
    { __typename?: 'UploadShort' }
    & UploadWithIntrinsicSizeFragment
  )>, writer: (
    { __typename?: 'User' }
    & UserBasicInfoFragment
  ), content?: Maybe<(
    { __typename?: 'RichTextContent' }
    & Pick<RichTextContent, 'serialized'>
  )>, channel?: Maybe<(
    { __typename?: 'Channel' }
    & ChannelFragment
  )>, subscriptionPlan?: Maybe<(
    { __typename?: 'SubscriptionPlan' }
    & Pick<SubscriptionPlan, '_id' | 'benefits' | 'currentUserSubscriptionStatus' | 'title' | 'slug' | 'subscriptionPlanStatus'>
  )> }
  & PostCommonFragment
);

export type PostDetailFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'hasCurrentUserReport' | 'isUserContentLocked' | 'userBlurLevel' | 'views' | 'commentCount' | 'isContentLocked' | 'videoUri'>
  & { previewImage?: Maybe<(
    { __typename?: 'UploadShort' }
    & UploadWithIntrinsicSizeFragment
  )>, writer: (
    { __typename?: 'User' }
    & UserBasicInfoFragment
  ), content?: Maybe<(
    { __typename?: 'RichTextContent' }
    & Pick<RichTextContent, 'serialized'>
  )>, channel?: Maybe<(
    { __typename?: 'Channel' }
    & ChannelFragment
  )>, images?: Maybe<Array<Maybe<(
    { __typename?: 'UploadShort' }
    & UploadWithIntrinsicSizeFragment
  )>>> }
  & PostCommonFragment
);

export type PostInfoWithLoggedInUserFragment = (
  { __typename?: 'Post' }
  & Pick<Post, '_id' | 'hasCurrentUserReport' | 'hasCurrentUserLike' | 'userBlurLevel' | 'isUserContentLocked' | 'ownerBlurImageUrl' | 'videoUri' | 'publishAt' | 'isPublished'>
  & { images?: Maybe<Array<Maybe<(
    { __typename?: 'UploadShort' }
    & UploadWithIntrinsicSizeFragment
  )>>>, asset?: Maybe<(
    { __typename?: 'NftInfo' }
    & NftInfoFragment
  )> }
);

export type NftInfoFragment = (
  { __typename?: 'NftInfo' }
  & Pick<NftInfo, 'id' | 'owner' | 'initialOwner' | 'price' | 'listStatus' | 'cid'>
);

export type PublishPostMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type PublishPostMutation = (
  { __typename?: 'Mutation' }
  & { publishPost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, '_id' | 'postStatus'>
  )> }
);

export type CreatePostMutationVariables = Exact<{
  postType?: Maybe<PostTypeEnum>;
  signedUrlCount?: Maybe<Scalars['Int']>;
  isOriginalImage?: Maybe<Scalars['Boolean']>;
}>;


export type CreatePostMutation = (
  { __typename?: 'Mutation' }
  & { createPost: (
    { __typename?: 'Post' }
    & PostFullFragment
  ) }
);

export type ReportPostMutationVariables = Exact<{
  input: ReportPostInput;
}>;


export type ReportPostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportPost'>
);

export type UpdatePostMutationVariables = Exact<{
  input: UpdatePostInput;
}>;


export type UpdatePostMutation = (
  { __typename?: 'Mutation' }
  & { updatePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, '_id' | 'slug' | 'cid'>
  )> }
);

export type RemovePostMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type RemovePostMutation = (
  { __typename?: 'Mutation' }
  & { removePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, '_id' | 'postStatus'>
  )> }
);

export type PostPageQueryVariables = Exact<{
  input?: Maybe<PostPageInput>;
  paging?: Maybe<CursorPagingInput>;
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type PostPageQuery = (
  { __typename?: 'Query' }
  & { postPage: (
    { __typename?: 'PostPage' }
    & { items: Array<Maybe<(
      { __typename?: 'Post' }
      & PostPagePostFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  ) }
);

export type PostInfoWithLoggedInUserQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type PostInfoWithLoggedInUserQuery = (
  { __typename?: 'Query' }
  & { post?: Maybe<(
    { __typename?: 'Post' }
    & PostInfoWithLoggedInUserFragment
  )> }
);

export type PostQueryVariables = Exact<{
  slug: Scalars['String'];
  signedUrlCount?: Maybe<Scalars['Int']>;
  isEdit: Scalars['Boolean'];
  avatarSize?: Maybe<ImageSizeInput>;
  isOriginalImage?: Maybe<Scalars['Boolean']>;
}>;


export type PostQuery = (
  { __typename?: 'Query' }
  & { post?: Maybe<(
    { __typename?: 'Post' }
    & PostDetailFragment
    & PostFullFragment
  )> }
);

export type LikedPostPageQueryVariables = Exact<{
  postTypes: Array<Maybe<PostTypeEnum>> | Maybe<PostTypeEnum>;
  paging?: Maybe<CursorPagingInput>;
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type LikedPostPageQuery = (
  { __typename?: 'Query' }
  & { likedPostPage?: Maybe<(
    { __typename?: 'LikedPostPage' }
    & { items: Array<Maybe<(
      { __typename?: 'LikedPost' }
      & Pick<LikedPost, '_id' | 'postId' | 'userId'>
      & { post: (
        { __typename?: 'Post' }
        & PostPagePostFragment
      ) }
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  )> }
);

export type MapTokensToPostPageQueryVariables = Exact<{
  purchasedOnly: Scalars['Boolean'];
  showSensitiveContent?: Maybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  tokenIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  nextOffset: Scalars['String'];
  hasNext: Scalars['Boolean'];
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type MapTokensToPostPageQuery = (
  { __typename?: 'Query' }
  & { mapTokensToPostPage?: Maybe<(
    { __typename?: 'MappedPostPage' }
    & { items: Array<Maybe<(
      { __typename?: 'Post' }
      & PostPagePostFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  )> }
);

export type Stripe_SubscriptionFragment = (
  { __typename?: 'stripe_subscription' }
  & Pick<Stripe_Subscription, 'id' | 'status'>
  & { latest_invoice: (
    { __typename?: 'stripe_invoice' }
    & { payment_intent?: Maybe<(
      { __typename?: 'stripe_payment_intent' }
      & Pick<Stripe_Payment_Intent, 'client_secret' | 'status'>
    )> }
  ) }
);

export type UserInvoicePageQueryVariables = Exact<{
  userId: Scalars['String'];
  paging?: Maybe<CursorPagingInput>;
  includeWriter?: Maybe<Scalars['Boolean']>;
  includeStringifyed?: Maybe<Scalars['Boolean']>;
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type UserInvoicePageQuery = (
  { __typename?: 'Query' }
  & { userInvoicePage: (
    { __typename?: 'UserInvoicePage' }
    & { items: Array<Maybe<(
      { __typename?: 'UserInvoice' }
      & UserInvoiceFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  ) }
);

export type UserInvoiceFragment = (
  { __typename?: 'UserInvoice' }
  & Pick<UserInvoice, '_id' | 'id' | 'refundedAmount' | 'startAt' | 'endAt' | 'createdAt' | 'type'>
  & { subscriptionPlan?: Maybe<(
    { __typename?: 'SubscriptionPlan' }
    & UserSubscriptionPlanDetailsFragment
  )>, invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'url'>
  )> }
);

export type UserSubscriptionPlanFullFragment = (
  { __typename?: 'UserSubscriptionPlan' }
  & Pick<UserSubscriptionPlan, '_id' | 'id' | 'paymentPeriod' | 'userId' | 'subscriptionPlanId' | 'userSubscriptionPlanStatus' | 'userSubscriptionPlanType' | 'startAt' | 'endAt' | 'createdAt' | 'updatedAt' | 'cancelledAt' | 'writerId' | 'latestInvoicesStatus' | 'currentSubscriptionStatus'>
  & { subscriptionPlan: (
    { __typename?: 'SubscriptionPlan' }
    & UserSubscriptionPlanDetailsFragment
  ), user?: Maybe<(
    { __typename?: 'User' }
    & UserBasicInfoFragment
  )>, priceBreakdown?: Maybe<(
    { __typename?: 'priceBreakdown' }
    & Pick<PriceBreakdown, 'price' | 'totalIncome' | 'totalPeriod' | 'providerFee'>
    & { platformFee?: Maybe<Array<Maybe<(
      { __typename?: 'platformfeeBreakDown' }
      & Pick<PlatformfeeBreakDown, 'subscriptionPeriod' | 'writerPlatformFee'>
    )>>> }
  )> }
);

export type UserSubscriptionPlanAfterMutationFragment = (
  { __typename?: 'UserSubscriptionPlan' }
  & Pick<UserSubscriptionPlan, '_id' | 'userSubscriptionPlanStatus'>
  & { subscriptionPlan: (
    { __typename?: 'SubscriptionPlan' }
    & Pick<SubscriptionPlan, 'currentUserSubscriptionStatus'>
  ) }
);

export type SubscribeWithStripeMutationVariables = Exact<{
  subscriptionPlanId: Scalars['String'];
  userPaymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
  email?: Maybe<Scalars['String']>;
}>;


export type SubscribeWithStripeMutation = (
  { __typename?: 'Mutation' }
  & { subscribeWithStripe?: Maybe<(
    { __typename?: 'StripePaymentResponse' }
    & Pick<StripePaymentResponse, 'success' | 'requiresAction' | 'paymentIntentClientSecret'>
  )> }
);

export type PurchaseExpiredContentWithStripeMutationVariables = Exact<{
  subscriptionPlanId: Scalars['String'];
  userPaymentMethodId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  postId: Scalars['String'];
  paymentMethodProvider?: Maybe<PaymentMethodProviderEnum>;
  email?: Maybe<Scalars['String']>;
}>;


export type PurchaseExpiredContentWithStripeMutation = (
  { __typename?: 'Mutation' }
  & { purchaseExpiredContentWithStripe: (
    { __typename?: 'StripePaymentResponse' }
    & Pick<StripePaymentResponse, 'success' | 'requiresAction' | 'paymentIntentClientSecret'>
  ) }
);

export type CancelUserSubscriptionPlanMutationVariables = Exact<{
  userSubscriptionPlanId: Scalars['String'];
}>;


export type CancelUserSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { cancelUserSubscriptionPlan: (
    { __typename?: 'UserSubscriptionPlan' }
    & UserSubscriptionPlanAfterMutationFragment
  ) }
);

export type ReactivateSubscriptionMutationVariables = Exact<{
  userSubscriptionPlanId: Scalars['String'];
}>;


export type ReactivateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { reactivateSubscription: (
    { __typename?: 'UserSubscriptionPlan' }
    & UserSubscriptionPlanAfterMutationFragment
  ) }
);

export type SubscriberPageQueryVariables = Exact<{
  input: SubscriberPageInput;
  paging?: Maybe<CursorPagingInput>;
  includeUser: Scalars['Boolean'];
  includeWriter: Scalars['Boolean'];
  avatarSize?: Maybe<ImageSizeInput>;
  includeStringifyed?: Maybe<Scalars['Boolean']>;
}>;


export type SubscriberPageQuery = (
  { __typename?: 'Query' }
  & { subscriberPage: (
    { __typename?: 'UserSubscriptionPage' }
    & { items: Array<Maybe<(
      { __typename?: 'UserSubscriptionPlan' }
      & UserSubscriptionPlanFullFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  ) }
);

export type UserSubscriptionPageQueryVariables = Exact<{
  input: UserSubscriptionPageInput;
  paging?: Maybe<CursorPagingInput>;
  includeUser: Scalars['Boolean'];
  includeWriter: Scalars['Boolean'];
  avatarSize?: Maybe<ImageSizeInput>;
  includeStringifyed?: Maybe<Scalars['Boolean']>;
}>;


export type UserSubscriptionPageQuery = (
  { __typename?: 'Query' }
  & { userSubscriptionPage: (
    { __typename?: 'UserSubscriptionPage' }
    & { items: Array<Maybe<(
      { __typename?: 'UserSubscriptionPlan' }
      & UserSubscriptionPlanFullFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  ) }
);

export type SubscriptionPlanFullFragment = (
  { __typename?: 'SubscriptionPlan' }
  & Pick<SubscriptionPlan, '_id' | 'id' | 'slug' | 'title' | 'channelIds' | 'price' | 'plan' | 'subscriptionPlanStatus' | 'writerId' | 'createdAt' | 'updatedAt' | 'benefits' | 'currency' | 'currentUserSubscriptionStatus'>
  & { channels?: Maybe<Array<Maybe<(
    { __typename?: 'Channel' }
    & ChannelFragment
  )>>>, image?: Maybe<(
    { __typename?: 'UploadShort' }
    & Pick<UploadShort, 'url'>
  )>, content?: Maybe<(
    { __typename?: 'RichTextContent' }
    & MakeOptional<Pick<RichTextContent, 'serialized' | 'stringifyed'>, 'stringifyed'>
  )> }
);

export type UserSubscriptionPlanDetailsFragment = (
  { __typename?: 'SubscriptionPlan' }
  & { writer?: Maybe<(
    { __typename?: 'User' }
    & UserBasicInfoFragment
  )> }
  & SubscriptionPlanFullFragment
);

export type CreateSubscriptionPlanMutationVariables = Exact<{
  input: CreateSubscriptionPlanInput;
  includeStringifyed?: Maybe<Scalars['Boolean']>;
}>;


export type CreateSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { createSubscriptionPlan: (
    { __typename?: 'SubscriptionPlan' }
    & SubscriptionPlanFullFragment
  ) }
);

export type RemoveSubscriptionPlanMutationVariables = Exact<{
  input: Scalars['String'];
  includeStringifyed?: Maybe<Scalars['Boolean']>;
  removeAllPosts?: Maybe<Scalars['Boolean']>;
}>;


export type RemoveSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { removeSubscriptionPlan?: Maybe<(
    { __typename?: 'SubscriptionPlan' }
    & SubscriptionPlanFullFragment
  )> }
);

export type UpdateSubscriptionPlanMutationVariables = Exact<{
  input: UpdateSubscriptionPlanInput;
  includeStringifyed?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & { updateSubscriptionPlan?: Maybe<(
    { __typename?: 'SubscriptionPlan' }
    & SubscriptionPlanFullFragment
  )> }
);

export type SubscriptionPlanPageQueryVariables = Exact<{
  input?: Maybe<SearchInput>;
  includeStringifyed: Scalars['Boolean'];
}>;


export type SubscriptionPlanPageQuery = (
  { __typename?: 'Query' }
  & { subscriptionPlanPage?: Maybe<(
    { __typename?: 'SubscriptionPlanPage' }
    & Pick<SubscriptionPlanPage, 'count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'SubscriptionPlan' }
      & SubscriptionPlanFullFragment
    )>>> }
  )> }
);

export type TopPostFragment = (
  { __typename?: 'TopPost' }
  & Pick<TopPost, '_id' | 'postId' | 'locale' | 'likes'>
  & { post?: Maybe<(
    { __typename?: 'Post' }
    & PostPagePostFragment
  )> }
);

export type TopPostsQueryVariables = Exact<{
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type TopPostsQuery = (
  { __typename?: 'Query' }
  & { topPosts: Array<(
    { __typename?: 'TopPost' }
    & TopPostFragment
  )> }
);

export type TopTagFragment = (
  { __typename?: 'TopTag' }
  & Pick<TopTag, '_id' | 'label' | 'value'>
);

export type TopTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TopTagsQuery = (
  { __typename?: 'Query' }
  & { topTags: Array<(
    { __typename?: 'TopTag' }
    & TopTagFragment
  )> }
);

export type TopWriterFullFragment = (
  { __typename?: 'TopWriter' }
  & Pick<TopWriter, '_id' | 'userId' | 'locale' | 'score'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserBasicInfoFragment
    & UserFollowCountFragment
  )> }
);

export type TopWritersQueryVariables = Exact<{
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type TopWritersQuery = (
  { __typename?: 'Query' }
  & { topWriters: Array<(
    { __typename?: 'TopWriter' }
    & TopWriterFullFragment
  )> }
);

export type CustomUploadFullFragment = (
  { __typename?: 'CustomUpload' }
  & Pick<CustomUpload, '_id' | 'url' | 'filepath' | 'fileType' | 'uploadType'>
);

export type UploadShortFragment = (
  { __typename?: 'UploadShort' }
  & Pick<UploadShort, '_id' | 'url' | 'uploadType' | 'fileType'>
);

export type UploadWithIntrinsicSizeFragment = (
  { __typename?: 'UploadShort' }
  & Pick<UploadShort, '_id' | 'url' | 'fileType' | 'hasAlpha'>
  & { intrinsicSize?: Maybe<(
    { __typename?: 'IntrinsicSize' }
    & Pick<IntrinsicSize, 'width' | 'height'>
  )> }
);

export type UploadFilesMutationVariables = Exact<{
  input?: Maybe<UploadFileInput>;
}>;


export type UploadFilesMutation = (
  { __typename?: 'Mutation' }
  & { uploadFiles?: Maybe<Array<Maybe<(
    { __typename?: 'CustomUpload' }
    & CustomUploadFullFragment
  )>>> }
);

export type UploadPageQueryVariables = Exact<{
  input: SearchInput;
}>;


export type UploadPageQuery = (
  { __typename?: 'Query' }
  & { uploadPage: (
    { __typename?: 'UploadPage' }
    & Pick<UploadPage, 'count' | 'page' | 'pageSize'>
    & { items: Array<Maybe<(
      { __typename?: 'CustomUpload' }
      & Pick<CustomUpload, '_id' | 'url' | 'fileType' | 'uploadType' | 'uploadStatus' | 'createdAt' | 'updatedAt'>
    )>> }
  ) }
);

export type UserFullFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'accountName' | 'displayName' | 'birthdate' | 'gender' | 'createdAt' | 'updatedAt' | 'userStatus' | 'locale' | 'showSensitiveContent' | 'shortCaption' | 'walletAccount' | 'userType' | 'creditBalance'>
  & { avatar?: Maybe<(
    { __typename?: 'UploadShort' }
    & UploadShortFragment
  )>, banner?: Maybe<(
    { __typename?: 'UploadShort' }
    & UploadShortFragment
  )>, creatorType: (
    { __typename?: 'CreatorType' }
    & Pick<CreatorType, 'tier' | 'updatedAt'>
  ) }
);

export type UserPostCountFragment = (
  { __typename?: 'User' }
  & Pick<User, 'postCount' | 'currentMonthPostCount'>
);

export type UserSubscribeContentCountFragment = (
  { __typename?: 'User' }
  & Pick<User, 'subscriberCount'>
  & { blurPostCount: (
    { __typename?: 'BlurPostCount' }
    & Pick<BlurPostCount, 'currentMonth' | 'lastMonth' | 'total'>
  ) }
);

export type UserFollowCountFragment = (
  { __typename?: 'User' }
  & Pick<User, 'followingCount' | 'followerCount'>
);

export type UserBasicInfoFragment = (
  { __typename?: 'User' }
  & Pick<User, '_id' | 'accountName' | 'displayName' | 'walletAccount' | 'shortCaption' | 'showSensitiveContent'>
  & { avatar?: Maybe<(
    { __typename?: 'UploadShort' }
    & UploadShortFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
  avatarSize?: Maybe<ImageSizeInput>;
  bannerSize?: Maybe<ImageSizeInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & UserFullFragment
  ) }
);

export type UpdateUserCreatorTierMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateUserCreatorTierMutation = (
  { __typename?: 'Mutation' }
  & { updateUserCreatorTier: (
    { __typename?: 'User' }
    & Pick<User, '_id'>
    & { creatorType: (
      { __typename?: 'CreatorType' }
      & Pick<CreatorType, 'tier'>
    ) }
  ) }
);

export type UpdateUserNotificationLastSeenMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateUserNotificationLastSeenMutation = (
  { __typename?: 'Mutation' }
  & { udpateUserNotificationLastSeen: (
    { __typename?: 'User' }
    & Pick<User, 'notificationLastSeen'>
  ) }
);

export type UpdateUserMessageLastSeenMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateUserMessageLastSeenMutation = (
  { __typename?: 'Mutation' }
  & { udpateUserMessageLastSeen: (
    { __typename?: 'User' }
    & Pick<User, 'messageLastSeen'>
  ) }
);

export type LinkWalletAccountMutationVariables = Exact<{
  walletAccount: Scalars['String'];
}>;


export type LinkWalletAccountMutation = (
  { __typename?: 'Mutation' }
  & { linkWalletAccount: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'walletAccount'>
  ) }
);

export type MeQueryVariables = Exact<{
  avatarSize?: Maybe<ImageSizeInput>;
  bannerSize?: Maybe<ImageSizeInput>;
}>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email'>
    & UserFullFragment
  )> }
);

export type UserTypeQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type UserTypeQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'userType'>
  )> }
);

export type UserBasicInfoQueryVariables = Exact<{
  accountName?: Maybe<Scalars['String']>;
  walletAccount?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  avatarSize?: Maybe<ImageSizeInput>;
}>;


export type UserBasicInfoQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserBasicInfoFragment
  )> }
);

export type ProfileUserQueryVariables = Exact<{
  accountName?: Maybe<Scalars['String']>;
  avatarSize?: Maybe<ImageSizeInput>;
  bannerSize?: Maybe<ImageSizeInput>;
}>;


export type ProfileUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & { channels: Array<Maybe<(
      { __typename?: 'Channel' }
      & ChannelFragment
    )>> }
    & UserFullFragment
    & UserFollowCountFragment
  )> }
);

export type ProfileUserPostCountQueryVariables = Exact<{
  accountName?: Maybe<Scalars['String']>;
  postTypes?: Maybe<Array<Maybe<PostTypeEnum>> | Maybe<PostTypeEnum>>;
}>;


export type ProfileUserPostCountQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id'>
    & UserPostCountFragment
    & UserSubscribeContentCountFragment
  )> }
);

export type UserAccountNameQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type UserAccountNameQuery = (
  { __typename?: 'Query' }
  & { userAccountName: (
    { __typename?: 'AccountName' }
    & Pick<AccountName, 'validationStatus' | 'suggestions'>
  ) }
);

export type UserPayoutFragment = (
  { __typename?: 'UserPayout' }
  & Pick<UserPayout, '_id' | 'userId' | 'currency' | 'remarks' | 'receiptUrl' | 'value' | 'userPayoutStatus' | 'provider' | 'createdAt' | 'updatedAt'>
);

export type CreateUserPayoutMutationVariables = Exact<{
  input: CreateUserPayoutInput;
}>;


export type CreateUserPayoutMutation = (
  { __typename?: 'Mutation' }
  & { createUserPayout: (
    { __typename?: 'UserPayout' }
    & UserPayoutFragment
  ) }
);

export type UserPayoutPageQueryVariables = Exact<{
  userId: Scalars['String'];
  paging?: Maybe<CursorPagingInput>;
}>;


export type UserPayoutPageQuery = (
  { __typename?: 'Query' }
  & { userPayoutPage: (
    { __typename?: 'UserPayoutPage' }
    & { items: Array<Maybe<(
      { __typename?: 'UserPayout' }
      & UserPayoutFragment
    )>>, paging: (
      { __typename?: 'CursorPagingInfo' }
      & CursorPagingInfoFragment
    ) }
  ) }
);

export type UserReceivePaymentMethodFragment = (
  { __typename?: 'UserReceivePaymentMethod' }
  & Pick<UserReceivePaymentMethod, '_id' | 'userId' | 'autoTransfer' | 'userReceivePaymentMethodStatus' | 'provider' | 'email' | 'bankAccount' | 'bankAccountHolder' | 'currencyCode' | 'countryCode' | 'bank'>
);

export type UpdateAutoTransferMutationVariables = Exact<{
  receivePaymentMethodId: Scalars['String'];
  autoTransfer: Scalars['Boolean'];
}>;


export type UpdateAutoTransferMutation = (
  { __typename?: 'Mutation' }
  & { updateAutoTransfer: (
    { __typename?: 'UserReceivePaymentMethod' }
    & UserReceivePaymentMethodFragment
  ) }
);

export type CreateUserReceivePaymentMethodMutationVariables = Exact<{
  input: CreateUserReceivePaymentMethodInput;
}>;


export type CreateUserReceivePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { createUserReceivePaymentMethod: (
    { __typename?: 'UserReceivePaymentMethod' }
    & UserReceivePaymentMethodFragment
  ) }
);

export type UpdateUserReceivePaymentMethodMutationVariables = Exact<{
  input: UpdateUserReceivePaymentMethodInput;
}>;


export type UpdateUserReceivePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updateUserReceivePaymentMethod: (
    { __typename?: 'UserReceivePaymentMethod' }
    & UserReceivePaymentMethodFragment
  ) }
);

export type UserReceivePaymentMethodsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserReceivePaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { userReceivePaymentMethods: Array<Maybe<(
    { __typename?: 'UserReceivePaymentMethod' }
    & UserReceivePaymentMethodFragment
  )>> }
);

export type EnabledPayoutProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type EnabledPayoutProvidersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'enabledPayoutProviders'>
);

export type UserWalletFragment = (
  { __typename?: 'UserWallet' }
  & Pick<UserWallet, '_id' | 'id' | 'income' | 'withdrawal' | 'currency'>
  & { detail?: Maybe<(
    { __typename?: 'UserWalletBalance' }
    & Pick<UserWalletBalance, 'totalBalance' | 'balance' | 'isWithdrewOrProcessing' | 'balanceExcludeProcessingAmount'>
  )> }
);

export type UserWalletQueryVariables = Exact<{
  startAt?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyEnum>;
  includeDetail?: Maybe<Scalars['Boolean']>;
}>;


export type UserWalletQuery = (
  { __typename?: 'Query' }
  & { userWallet?: Maybe<(
    { __typename?: 'UserWallet' }
    & UserWalletFragment
  )> }
);

export const ApplicationFragmentDoc = gql`
    fragment Application on Application {
  _id
  type
  status
  userId
  name
  phoneNumber
  postalCode
  address
}
    `;
export const ChannelPostCountFragmentDoc = gql`
    fragment ChannelPostCount on Channel {
  postCount(postTypes: $postTypes)
  currentMonthPostCount(postTypes: $postTypes)
}
    `;
export const ChannelSubscribeContentCountFragmentDoc = gql`
    fragment ChannelSubscribeContentCount on Channel {
  subscriberCount
  blurPostCount {
    currentMonth
    lastMonth
    total
  }
}
    `;
export const ChannelFollowCountFragmentDoc = gql`
    fragment ChannelFollowCount on Channel {
  followerCount
}
    `;
export const UploadShortFragmentDoc = gql`
    fragment UploadShort on UploadShort {
  _id
  url
  uploadType
  fileType
}
    `;
export const UserBasicInfoFragmentDoc = gql`
    fragment UserBasicInfo on User {
  _id
  accountName
  displayName
  avatar(imageSize: $avatarSize) {
    ...UploadShort
  }
  walletAccount
  shortCaption
  showSensitiveContent
}
    ${UploadShortFragmentDoc}`;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  _id
  parentCommentId
  postWriterId
  postId
  userId
  mentionUserIds
  content
  commentStatus
  updatedAt
  createdAt
  numberOfReplies
  user {
    ...UserBasicInfo
  }
  mentionUsers {
    ...UserBasicInfo
  }
}
    ${UserBasicInfoFragmentDoc}`;
export const FollowChannelFragmentDoc = gql`
    fragment FollowChannel on FollowChannel {
  channelId
  userId
}
    `;
export const ChannelFragmentDoc = gql`
    fragment Channel on Channel {
  _id
  id
  title
  slug
  creatorId
  creator {
    accountName
  }
  description
  isSensitive
  isCommentRestricted
  channelStatus
}
    `;
export const FollowingChannelFragmentDoc = gql`
    fragment FollowingChannel on Channel {
  ...Channel
  creator @include(if: $includeCreator) {
    ...UserBasicInfo
  }
  postCount
}
    ${ChannelFragmentDoc}
${UserBasicInfoFragmentDoc}`;
export const CursorPagingInfoFragmentDoc = gql`
    fragment CursorPagingInfo on CursorPagingInfo {
  hasNext
  next
}
    `;
export const FollowChannelPageFragmentDoc = gql`
    fragment FollowChannelPage on FollowChannelPage {
  items {
    _id
    userId
    user @include(if: $includeUser) {
      ...UserBasicInfo
    }
    channelId
    channel {
      ...FollowingChannel
    }
    createdAt
  }
  paging {
    ...CursorPagingInfo
  }
}
    ${UserBasicInfoFragmentDoc}
${FollowingChannelFragmentDoc}
${CursorPagingInfoFragmentDoc}`;
export const FollowedTagFragmentDoc = gql`
    fragment FollowedTag on Tag {
  _id
  value
  label
  bgImage
}
    `;
export const LikeRelationshipFragmentDoc = gql`
    fragment LikeRelationship on Like {
  postId
  userId
}
    `;
export const ChatFragmentDoc = gql`
    fragment Chat on Chat {
  _id
  userIds
  relatedUser {
    ...UserBasicInfo
  }
  lastMessage {
    senderId
    message
    messageStatus
    read
    createdAt
  }
}
    ${UserBasicInfoFragmentDoc}`;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  _id
  chatId
  senderId
  receiverId
  message
  files {
    ...UploadShort
  }
  read
  messageStatus
  createdAt
  updatedAt
}
    ${UploadShortFragmentDoc}`;
export const NotificationFullFragmentDoc = gql`
    fragment NotificationFull on Notification {
  _id
  relatedUserId
  relatedUser {
    _id
    accountName
    displayName
  }
  postId
  post {
    _id
    slug
    title
  }
  subscriptionPlanId
  subscriptionPlan {
    title
  }
  channelId
  channel {
    title
    slug
  }
  userPayoutId
  userPayout {
    provider
    value
    currency
  }
  commentId
  type
  notificationStatus
  startAt
  createdAt
  updatedAt
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on Card {
  lastFourDigits
  brand
  expMonth
  expYear
}
    `;
export const UserPaymentMethodFragmentDoc = gql`
    fragment UserPaymentMethod on UserPaymentMethod {
  _id
  refId
  userId
  provider
  paymentMethodStatus
  paymentMethodDetail {
    card {
      ...Card
    }
  }
}
    ${CardFragmentDoc}`;
export const NftInfoFragmentDoc = gql`
    fragment NftInfo on NftInfo {
  id
  owner
  initialOwner
  price
  listStatus
  cid
}
    `;
export const PostCommonFragmentDoc = gql`
    fragment PostCommon on Post {
  _id
  id
  slug
  title
  postStatus
  likes
  channelId
  writerId
  isSensitive
  postBlurLevel
  visibleHeight
  tags {
    value
    label
    bgImage
  }
  subscriptionPlanId
  watermarkPosition
  createdAt
  updatedAt
  postType
  tokenId
  previewContent
  isContentLocked
  price
  currency
  asset {
    ...NftInfo
  }
  publishAt
  isPublished
}
    ${NftInfoFragmentDoc}`;
export const UploadWithIntrinsicSizeFragmentDoc = gql`
    fragment UploadWithIntrinsicSize on UploadShort {
  _id
  url
  fileType
  hasAlpha
  intrinsicSize {
    width
    height
  }
}
    `;
export const PostFullFragmentDoc = gql`
    fragment PostFull on Post {
  ...PostCommon
  videoUri
  content {
    stringifyed
    serialized
  }
  images(signedUrlCount: $signedUrlCount, isOriginalImage: $isOriginalImage) {
    ...UploadWithIntrinsicSize
    uploadType
    filepath
  }
}
    ${PostCommonFragmentDoc}
${UploadWithIntrinsicSizeFragmentDoc}`;
export const PostDetailFragmentDoc = gql`
    fragment PostDetail on Post {
  ...PostCommon
  previewImage {
    ...UploadWithIntrinsicSize
  }
  writer {
    ...UserBasicInfo
  }
  content {
    serialized
  }
  channel {
    ...Channel
  }
  hasCurrentUserReport
  isUserContentLocked
  userBlurLevel
  views
  images(signedUrlCount: $signedUrlCount, isOriginalImage: $isOriginalImage) {
    ...UploadWithIntrinsicSize
  }
  commentCount
  isContentLocked
  videoUri
}
    ${PostCommonFragmentDoc}
${UploadWithIntrinsicSizeFragmentDoc}
${UserBasicInfoFragmentDoc}
${ChannelFragmentDoc}`;
export const PostInfoWithLoggedInUserFragmentDoc = gql`
    fragment PostInfoWithLoggedInUser on Post {
  _id
  hasCurrentUserReport
  hasCurrentUserLike
  userBlurLevel
  isUserContentLocked
  images(signedUrlCount: 1) {
    ...UploadWithIntrinsicSize
  }
  ownerBlurImageUrl
  asset {
    ...NftInfo
  }
  videoUri
  publishAt
  isPublished
}
    ${UploadWithIntrinsicSizeFragmentDoc}
${NftInfoFragmentDoc}`;
export const Stripe_SubscriptionFragmentDoc = gql`
    fragment stripe_subscription on stripe_subscription {
  id
  status
  latest_invoice {
    payment_intent {
      client_secret
      status
    }
  }
}
    `;
export const SubscriptionPlanFullFragmentDoc = gql`
    fragment SubscriptionPlanFull on SubscriptionPlan {
  _id
  id
  slug
  title
  channelIds
  channels {
    ...Channel
  }
  image {
    url
  }
  price
  plan
  subscriptionPlanStatus
  writerId
  content {
    serialized
    stringifyed @include(if: $includeStringifyed)
  }
  createdAt
  updatedAt
  benefits
  currency
  currentUserSubscriptionStatus
}
    ${ChannelFragmentDoc}`;
export const UserSubscriptionPlanDetailsFragmentDoc = gql`
    fragment UserSubscriptionPlanDetails on SubscriptionPlan {
  ...SubscriptionPlanFull
  writer @include(if: $includeWriter) {
    ...UserBasicInfo
  }
}
    ${SubscriptionPlanFullFragmentDoc}
${UserBasicInfoFragmentDoc}`;
export const UserInvoiceFragmentDoc = gql`
    fragment UserInvoice on UserInvoice {
  _id
  id
  subscriptionPlan {
    ...UserSubscriptionPlanDetails
  }
  refundedAmount
  startAt
  endAt
  invoice {
    url
  }
  createdAt
  type
}
    ${UserSubscriptionPlanDetailsFragmentDoc}`;
export const UserSubscriptionPlanFullFragmentDoc = gql`
    fragment UserSubscriptionPlanFull on UserSubscriptionPlan {
  _id
  id
  paymentPeriod
  userId
  subscriptionPlanId
  userSubscriptionPlanStatus
  subscriptionPlan {
    ...UserSubscriptionPlanDetails
  }
  userSubscriptionPlanType
  startAt
  endAt
  createdAt
  updatedAt
  cancelledAt
  writerId
  user @include(if: $includeUser) {
    ...UserBasicInfo
  }
  latestInvoicesStatus
  currentSubscriptionStatus
  priceBreakdown {
    price
    totalIncome
    totalPeriod
    providerFee
    platformFee {
      subscriptionPeriod
      writerPlatformFee
    }
  }
}
    ${UserSubscriptionPlanDetailsFragmentDoc}
${UserBasicInfoFragmentDoc}`;
export const UserSubscriptionPlanAfterMutationFragmentDoc = gql`
    fragment UserSubscriptionPlanAfterMutation on UserSubscriptionPlan {
  _id
  userSubscriptionPlanStatus
  subscriptionPlan {
    currentUserSubscriptionStatus
  }
}
    `;
export const PostPagePostFragmentDoc = gql`
    fragment PostPagePost on Post {
  ...PostCommon
  commentCount
  previewImage {
    ...UploadWithIntrinsicSize
  }
  writer {
    ...UserBasicInfo
  }
  content {
    serialized
  }
  channel {
    ...Channel
  }
  subscriptionPlan {
    _id
    benefits
    currentUserSubscriptionStatus
    title
    slug
    subscriptionPlanStatus
  }
}
    ${PostCommonFragmentDoc}
${UploadWithIntrinsicSizeFragmentDoc}
${UserBasicInfoFragmentDoc}
${ChannelFragmentDoc}`;
export const TopPostFragmentDoc = gql`
    fragment TopPost on TopPost {
  _id
  postId
  locale
  likes
  post {
    ...PostPagePost
  }
}
    ${PostPagePostFragmentDoc}`;
export const TopTagFragmentDoc = gql`
    fragment TopTag on TopTag {
  _id
  label
  value
}
    `;
export const UserFollowCountFragmentDoc = gql`
    fragment UserFollowCount on User {
  followingCount
  followerCount
}
    `;
export const TopWriterFullFragmentDoc = gql`
    fragment TopWriterFull on TopWriter {
  _id
  userId
  locale
  score
  user {
    ...UserBasicInfo
    ...UserFollowCount
  }
}
    ${UserBasicInfoFragmentDoc}
${UserFollowCountFragmentDoc}`;
export const CustomUploadFullFragmentDoc = gql`
    fragment CustomUploadFull on CustomUpload {
  _id
  url
  filepath
  fileType
  uploadType
}
    `;
export const UserFullFragmentDoc = gql`
    fragment UserFull on User {
  _id
  accountName
  displayName
  birthdate
  avatar(imageSize: $avatarSize) {
    ...UploadShort
  }
  banner(imageSize: $bannerSize) {
    ...UploadShort
  }
  gender
  createdAt
  updatedAt
  userStatus
  locale
  showSensitiveContent
  shortCaption
  walletAccount
  userType
  creditBalance
  creatorType {
    tier
    updatedAt
  }
}
    ${UploadShortFragmentDoc}`;
export const UserPostCountFragmentDoc = gql`
    fragment UserPostCount on User {
  postCount(postTypes: $postTypes)
  currentMonthPostCount(postTypes: $postTypes)
}
    `;
export const UserSubscribeContentCountFragmentDoc = gql`
    fragment UserSubscribeContentCount on User {
  subscriberCount
  blurPostCount {
    currentMonth
    lastMonth
    total
  }
}
    `;
export const UserPayoutFragmentDoc = gql`
    fragment UserPayout on UserPayout {
  _id
  userId
  currency
  remarks
  receiptUrl
  value
  userPayoutStatus
  provider
  createdAt
  updatedAt
}
    `;
export const UserReceivePaymentMethodFragmentDoc = gql`
    fragment UserReceivePaymentMethod on UserReceivePaymentMethod {
  _id
  userId
  autoTransfer
  userReceivePaymentMethodStatus
  provider
  email
  bankAccount
  bankAccountHolder
  currencyCode
  countryCode
  bank
}
    `;
export const UserWalletFragmentDoc = gql`
    fragment UserWallet on UserWallet {
  _id
  id
  income
  withdrawal
  currency
  detail @include(if: $includeDetail) {
    totalBalance
    balance
    isWithdrewOrProcessing
    balanceExcludeProcessingAmount
  }
}
    `;
export const CreateApplicationDocument = gql`
    mutation createApplication($input: CreateApplicationInput!) {
  createApplication(input: $input) {
    application {
      ...Application
    }
    notification {
      ...NotificationFull
    }
  }
}
    ${ApplicationFragmentDoc}
${NotificationFullFragmentDoc}`;
export type CreateApplicationMutationFn = Apollo.MutationFunction<CreateApplicationMutation, CreateApplicationMutationVariables>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>) {
        return Apollo.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(CreateApplicationDocument, baseOptions);
      }
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<CreateApplicationMutation, CreateApplicationMutationVariables>;
export const UpdateApplicationDocument = gql`
    mutation updateApplication($input: UpdateApplicationInput!) {
  updateApplication(input: $input) {
    application {
      ...Application
    }
    notification {
      ...NotificationFull
    }
  }
}
    ${ApplicationFragmentDoc}
${NotificationFullFragmentDoc}`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<UpdateApplicationMutation, UpdateApplicationMutationVariables>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>) {
        return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(UpdateApplicationDocument, baseOptions);
      }
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>;
export const ApplicationDocument = gql`
    query application($type: ApplicationTypeEnum!) {
  application(type: $type) {
    ...Application
  }
}
    ${ApplicationFragmentDoc}`;

/**
 * __useApplicationQuery__
 *
 * To run a query within a React component, call `useApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useApplicationQuery(baseOptions: Apollo.QueryHookOptions<ApplicationQuery, ApplicationQueryVariables>) {
        return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, baseOptions);
      }
export function useApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationQuery, ApplicationQueryVariables>) {
          return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, baseOptions);
        }
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>;
export type ApplicationLazyQueryHookResult = ReturnType<typeof useApplicationLazyQuery>;
export type ApplicationQueryResult = Apollo.QueryResult<ApplicationQuery, ApplicationQueryVariables>;
export const IsTestUserDocument = gql`
    query IsTestUser($email: String!) {
  isTestUser(email: $email)
}
    `;

/**
 * __useIsTestUserQuery__
 *
 * To run a query within a React component, call `useIsTestUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTestUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTestUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsTestUserQuery(baseOptions: Apollo.QueryHookOptions<IsTestUserQuery, IsTestUserQueryVariables>) {
        return Apollo.useQuery<IsTestUserQuery, IsTestUserQueryVariables>(IsTestUserDocument, baseOptions);
      }
export function useIsTestUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsTestUserQuery, IsTestUserQueryVariables>) {
          return Apollo.useLazyQuery<IsTestUserQuery, IsTestUserQueryVariables>(IsTestUserDocument, baseOptions);
        }
export type IsTestUserQueryHookResult = ReturnType<typeof useIsTestUserQuery>;
export type IsTestUserLazyQueryHookResult = ReturnType<typeof useIsTestUserLazyQuery>;
export type IsTestUserQueryResult = Apollo.QueryResult<IsTestUserQuery, IsTestUserQueryVariables>;
export const CreateChannelDocument = gql`
    mutation createChannel($title: String!, $isSensitive: Boolean!, $description: String, $isCommentRestricted: Boolean) {
  createChannel(
    title: $title
    isSensitive: $isSensitive
    description: $description
    isCommentRestricted: $isCommentRestricted
  ) {
    ...Channel
  }
}
    ${ChannelFragmentDoc}`;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      title: // value for 'title'
 *      isSensitive: // value for 'isSensitive'
 *      description: // value for 'description'
 *      isCommentRestricted: // value for 'isCommentRestricted'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, baseOptions);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const RemoveChannelDocument = gql`
    mutation removeChannel($input: String!) {
  removeChannel(_id: $input) {
    ...Channel
  }
}
    ${ChannelFragmentDoc}`;
export type RemoveChannelMutationFn = Apollo.MutationFunction<RemoveChannelMutation, RemoveChannelMutationVariables>;

/**
 * __useRemoveChannelMutation__
 *
 * To run a mutation, you first call `useRemoveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChannelMutation, { data, loading, error }] = useRemoveChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveChannelMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChannelMutation, RemoveChannelMutationVariables>) {
        return Apollo.useMutation<RemoveChannelMutation, RemoveChannelMutationVariables>(RemoveChannelDocument, baseOptions);
      }
export type RemoveChannelMutationHookResult = ReturnType<typeof useRemoveChannelMutation>;
export type RemoveChannelMutationResult = Apollo.MutationResult<RemoveChannelMutation>;
export type RemoveChannelMutationOptions = Apollo.BaseMutationOptions<RemoveChannelMutation, RemoveChannelMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation updateChannel($input: UpdateChannelInput!) {
  updateChannel(input: $input) {
    channel {
      ...Channel
    }
    notification {
      ...NotificationFull
    }
  }
}
    ${ChannelFragmentDoc}
${NotificationFullFragmentDoc}`;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, baseOptions);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const ChannelsDocument = gql`
    query channels($userId: String, $postTypes: [PostTypeEnum], $channelStatus: [ChannelStatusEnum!], $skipCount: Boolean = true) {
  channels(userId: $userId, channelStatus: $channelStatus) {
    ...Channel
    postCount(postTypes: $postTypes) @skip(if: $skipCount)
    followerCount @skip(if: $skipCount)
    subscriptionCount @skip(if: $skipCount)
  }
}
    ${ChannelFragmentDoc}`;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      postTypes: // value for 'postTypes'
 *      channelStatus: // value for 'channelStatus'
 *      skipCount: // value for 'skipCount'
 *   },
 * });
 */
export function useChannelsQuery(baseOptions?: Apollo.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
        return Apollo.useQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, baseOptions);
      }
export function useChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
          return Apollo.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, baseOptions);
        }
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<typeof useChannelsLazyQuery>;
export type ChannelsQueryResult = Apollo.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export const ProfileChannelDocument = gql`
    query profileChannel($channelSlug: String!) {
  channel(slug: $channelSlug) {
    ...Channel
    ...ChannelFollowCount
  }
}
    ${ChannelFragmentDoc}
${ChannelFollowCountFragmentDoc}`;

/**
 * __useProfileChannelQuery__
 *
 * To run a query within a React component, call `useProfileChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileChannelQuery({
 *   variables: {
 *      channelSlug: // value for 'channelSlug'
 *   },
 * });
 */
export function useProfileChannelQuery(baseOptions: Apollo.QueryHookOptions<ProfileChannelQuery, ProfileChannelQueryVariables>) {
        return Apollo.useQuery<ProfileChannelQuery, ProfileChannelQueryVariables>(ProfileChannelDocument, baseOptions);
      }
export function useProfileChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileChannelQuery, ProfileChannelQueryVariables>) {
          return Apollo.useLazyQuery<ProfileChannelQuery, ProfileChannelQueryVariables>(ProfileChannelDocument, baseOptions);
        }
export type ProfileChannelQueryHookResult = ReturnType<typeof useProfileChannelQuery>;
export type ProfileChannelLazyQueryHookResult = ReturnType<typeof useProfileChannelLazyQuery>;
export type ProfileChannelQueryResult = Apollo.QueryResult<ProfileChannelQuery, ProfileChannelQueryVariables>;
export const ProfileChannelPostCountDocument = gql`
    query profileChannelPostCount($channelSlug: String!, $postTypes: [PostTypeEnum]) {
  channel(slug: $channelSlug) {
    _id
    ...ChannelPostCount
    ...ChannelSubscribeContentCount
  }
}
    ${ChannelPostCountFragmentDoc}
${ChannelSubscribeContentCountFragmentDoc}`;

/**
 * __useProfileChannelPostCountQuery__
 *
 * To run a query within a React component, call `useProfileChannelPostCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileChannelPostCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileChannelPostCountQuery({
 *   variables: {
 *      channelSlug: // value for 'channelSlug'
 *      postTypes: // value for 'postTypes'
 *   },
 * });
 */
export function useProfileChannelPostCountQuery(baseOptions: Apollo.QueryHookOptions<ProfileChannelPostCountQuery, ProfileChannelPostCountQueryVariables>) {
        return Apollo.useQuery<ProfileChannelPostCountQuery, ProfileChannelPostCountQueryVariables>(ProfileChannelPostCountDocument, baseOptions);
      }
export function useProfileChannelPostCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileChannelPostCountQuery, ProfileChannelPostCountQueryVariables>) {
          return Apollo.useLazyQuery<ProfileChannelPostCountQuery, ProfileChannelPostCountQueryVariables>(ProfileChannelPostCountDocument, baseOptions);
        }
export type ProfileChannelPostCountQueryHookResult = ReturnType<typeof useProfileChannelPostCountQuery>;
export type ProfileChannelPostCountLazyQueryHookResult = ReturnType<typeof useProfileChannelPostCountLazyQuery>;
export type ProfileChannelPostCountQueryResult = Apollo.QueryResult<ProfileChannelPostCountQuery, ProfileChannelPostCountQueryVariables>;
export const RemoveCommentDocument = gql`
    mutation removeComment($commentId: String!) {
  removeComment(commentId: $commentId) {
    _id
    parentCommentId
    commentStatus
    postId
  }
}
    `;
export type RemoveCommentMutationFn = Apollo.MutationFunction<RemoveCommentMutation, RemoveCommentMutationVariables>;

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useRemoveCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCommentMutation, RemoveCommentMutationVariables>) {
        return Apollo.useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(RemoveCommentDocument, baseOptions);
      }
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>;
export type RemoveCommentMutationResult = Apollo.MutationResult<RemoveCommentMutation>;
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<RemoveCommentMutation, RemoveCommentMutationVariables>;
export const CommentPageDocument = gql`
    query commentPage($input: CommentPageInput!, $avatarSize: ImageSizeInput, $paging: CursorPagingInput) {
  commentPage(input: $input, paging: $paging) {
    items {
      ...Comment
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${CommentFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useCommentPageQuery__
 *
 * To run a query within a React component, call `useCommentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      avatarSize: // value for 'avatarSize'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useCommentPageQuery(baseOptions: Apollo.QueryHookOptions<CommentPageQuery, CommentPageQueryVariables>) {
        return Apollo.useQuery<CommentPageQuery, CommentPageQueryVariables>(CommentPageDocument, baseOptions);
      }
export function useCommentPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentPageQuery, CommentPageQueryVariables>) {
          return Apollo.useLazyQuery<CommentPageQuery, CommentPageQueryVariables>(CommentPageDocument, baseOptions);
        }
export type CommentPageQueryHookResult = ReturnType<typeof useCommentPageQuery>;
export type CommentPageLazyQueryHookResult = ReturnType<typeof useCommentPageLazyQuery>;
export type CommentPageQueryResult = Apollo.QueryResult<CommentPageQuery, CommentPageQueryVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($input: CreateCommentInput!, $avatarSize: ImageSizeInput) {
  createComment(input: $input) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, baseOptions);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const EnabledFeaturesDocument = gql`
    query EnabledFeatures {
  enabledFeatures
}
    `;

/**
 * __useEnabledFeaturesQuery__
 *
 * To run a query within a React component, call `useEnabledFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnabledFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnabledFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnabledFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<EnabledFeaturesQuery, EnabledFeaturesQueryVariables>) {
        return Apollo.useQuery<EnabledFeaturesQuery, EnabledFeaturesQueryVariables>(EnabledFeaturesDocument, baseOptions);
      }
export function useEnabledFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnabledFeaturesQuery, EnabledFeaturesQueryVariables>) {
          return Apollo.useLazyQuery<EnabledFeaturesQuery, EnabledFeaturesQueryVariables>(EnabledFeaturesDocument, baseOptions);
        }
export type EnabledFeaturesQueryHookResult = ReturnType<typeof useEnabledFeaturesQuery>;
export type EnabledFeaturesLazyQueryHookResult = ReturnType<typeof useEnabledFeaturesLazyQuery>;
export type EnabledFeaturesQueryResult = Apollo.QueryResult<EnabledFeaturesQuery, EnabledFeaturesQueryVariables>;
export const FollowChannelDocument = gql`
    mutation followChannel($input: ID!) {
  followChannel(channelId: $input) {
    ...FollowChannel
  }
}
    ${FollowChannelFragmentDoc}`;
export type FollowChannelMutationFn = Apollo.MutationFunction<FollowChannelMutation, FollowChannelMutationVariables>;

/**
 * __useFollowChannelMutation__
 *
 * To run a mutation, you first call `useFollowChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followChannelMutation, { data, loading, error }] = useFollowChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowChannelMutation(baseOptions?: Apollo.MutationHookOptions<FollowChannelMutation, FollowChannelMutationVariables>) {
        return Apollo.useMutation<FollowChannelMutation, FollowChannelMutationVariables>(FollowChannelDocument, baseOptions);
      }
export type FollowChannelMutationHookResult = ReturnType<typeof useFollowChannelMutation>;
export type FollowChannelMutationResult = Apollo.MutationResult<FollowChannelMutation>;
export type FollowChannelMutationOptions = Apollo.BaseMutationOptions<FollowChannelMutation, FollowChannelMutationVariables>;
export const UnfollowChannelDocument = gql`
    mutation unfollowChannel($input: ID!) {
  unfollowChannel(channelId: $input) {
    channelId
    userId
  }
}
    `;
export type UnfollowChannelMutationFn = Apollo.MutationFunction<UnfollowChannelMutation, UnfollowChannelMutationVariables>;

/**
 * __useUnfollowChannelMutation__
 *
 * To run a mutation, you first call `useUnfollowChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowChannelMutation, { data, loading, error }] = useUnfollowChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowChannelMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowChannelMutation, UnfollowChannelMutationVariables>) {
        return Apollo.useMutation<UnfollowChannelMutation, UnfollowChannelMutationVariables>(UnfollowChannelDocument, baseOptions);
      }
export type UnfollowChannelMutationHookResult = ReturnType<typeof useUnfollowChannelMutation>;
export type UnfollowChannelMutationResult = Apollo.MutationResult<UnfollowChannelMutation>;
export type UnfollowChannelMutationOptions = Apollo.BaseMutationOptions<UnfollowChannelMutation, UnfollowChannelMutationVariables>;
export const FollowChannelPageDocument = gql`
    query followChannelPage($input: FollowChannelQueryInput!, $paging: CursorPagingInput, $includeUser: Boolean!, $includeCreator: Boolean!, $avatarSize: ImageSizeInput) {
  followChannelPage(input: $input, paging: $paging) {
    ...FollowChannelPage
  }
}
    ${FollowChannelPageFragmentDoc}`;

/**
 * __useFollowChannelPageQuery__
 *
 * To run a query within a React component, call `useFollowChannelPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowChannelPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowChannelPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      paging: // value for 'paging'
 *      includeUser: // value for 'includeUser'
 *      includeCreator: // value for 'includeCreator'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useFollowChannelPageQuery(baseOptions: Apollo.QueryHookOptions<FollowChannelPageQuery, FollowChannelPageQueryVariables>) {
        return Apollo.useQuery<FollowChannelPageQuery, FollowChannelPageQueryVariables>(FollowChannelPageDocument, baseOptions);
      }
export function useFollowChannelPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowChannelPageQuery, FollowChannelPageQueryVariables>) {
          return Apollo.useLazyQuery<FollowChannelPageQuery, FollowChannelPageQueryVariables>(FollowChannelPageDocument, baseOptions);
        }
export type FollowChannelPageQueryHookResult = ReturnType<typeof useFollowChannelPageQuery>;
export type FollowChannelPageLazyQueryHookResult = ReturnType<typeof useFollowChannelPageLazyQuery>;
export type FollowChannelPageQueryResult = Apollo.QueryResult<FollowChannelPageQuery, FollowChannelPageQueryVariables>;
export const FollowChannelDocDocument = gql`
    query followChannelDoc($input: ID!) {
  followChannelDoc(channelId: $input) {
    ...FollowChannel
  }
}
    ${FollowChannelFragmentDoc}`;

/**
 * __useFollowChannelDocQuery__
 *
 * To run a query within a React component, call `useFollowChannelDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowChannelDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowChannelDocQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowChannelDocQuery(baseOptions: Apollo.QueryHookOptions<FollowChannelDocQuery, FollowChannelDocQueryVariables>) {
        return Apollo.useQuery<FollowChannelDocQuery, FollowChannelDocQueryVariables>(FollowChannelDocDocument, baseOptions);
      }
export function useFollowChannelDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowChannelDocQuery, FollowChannelDocQueryVariables>) {
          return Apollo.useLazyQuery<FollowChannelDocQuery, FollowChannelDocQueryVariables>(FollowChannelDocDocument, baseOptions);
        }
export type FollowChannelDocQueryHookResult = ReturnType<typeof useFollowChannelDocQuery>;
export type FollowChannelDocLazyQueryHookResult = ReturnType<typeof useFollowChannelDocLazyQuery>;
export type FollowChannelDocQueryResult = Apollo.QueryResult<FollowChannelDocQuery, FollowChannelDocQueryVariables>;
export const FollowTagDocument = gql`
    mutation followTag($input: ID!) {
  followTag(tagId: $input) {
    tagId
    userId
  }
}
    `;
export type FollowTagMutationFn = Apollo.MutationFunction<FollowTagMutation, FollowTagMutationVariables>;

/**
 * __useFollowTagMutation__
 *
 * To run a mutation, you first call `useFollowTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followTagMutation, { data, loading, error }] = useFollowTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowTagMutation(baseOptions?: Apollo.MutationHookOptions<FollowTagMutation, FollowTagMutationVariables>) {
        return Apollo.useMutation<FollowTagMutation, FollowTagMutationVariables>(FollowTagDocument, baseOptions);
      }
export type FollowTagMutationHookResult = ReturnType<typeof useFollowTagMutation>;
export type FollowTagMutationResult = Apollo.MutationResult<FollowTagMutation>;
export type FollowTagMutationOptions = Apollo.BaseMutationOptions<FollowTagMutation, FollowTagMutationVariables>;
export const FollowTagDocDocument = gql`
    query followTagDoc($input: ID!) {
  followTagDoc(tagId: $input) {
    tagId
    userId
  }
}
    `;

/**
 * __useFollowTagDocQuery__
 *
 * To run a query within a React component, call `useFollowTagDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowTagDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowTagDocQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowTagDocQuery(baseOptions: Apollo.QueryHookOptions<FollowTagDocQuery, FollowTagDocQueryVariables>) {
        return Apollo.useQuery<FollowTagDocQuery, FollowTagDocQueryVariables>(FollowTagDocDocument, baseOptions);
      }
export function useFollowTagDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowTagDocQuery, FollowTagDocQueryVariables>) {
          return Apollo.useLazyQuery<FollowTagDocQuery, FollowTagDocQueryVariables>(FollowTagDocDocument, baseOptions);
        }
export type FollowTagDocQueryHookResult = ReturnType<typeof useFollowTagDocQuery>;
export type FollowTagDocLazyQueryHookResult = ReturnType<typeof useFollowTagDocLazyQuery>;
export type FollowTagDocQueryResult = Apollo.QueryResult<FollowTagDocQuery, FollowTagDocQueryVariables>;
export const FollowTagPageDocument = gql`
    query followTagPage($input: SearchInput) {
  followTagPage(input: $input) {
    items {
      tagId
      userId
      tag {
        ...FollowedTag
      }
    }
  }
}
    ${FollowedTagFragmentDoc}`;

/**
 * __useFollowTagPageQuery__
 *
 * To run a query within a React component, call `useFollowTagPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowTagPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowTagPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowTagPageQuery(baseOptions?: Apollo.QueryHookOptions<FollowTagPageQuery, FollowTagPageQueryVariables>) {
        return Apollo.useQuery<FollowTagPageQuery, FollowTagPageQueryVariables>(FollowTagPageDocument, baseOptions);
      }
export function useFollowTagPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowTagPageQuery, FollowTagPageQueryVariables>) {
          return Apollo.useLazyQuery<FollowTagPageQuery, FollowTagPageQueryVariables>(FollowTagPageDocument, baseOptions);
        }
export type FollowTagPageQueryHookResult = ReturnType<typeof useFollowTagPageQuery>;
export type FollowTagPageLazyQueryHookResult = ReturnType<typeof useFollowTagPageLazyQuery>;
export type FollowTagPageQueryResult = Apollo.QueryResult<FollowTagPageQuery, FollowTagPageQueryVariables>;
export const UnfollowTagDocument = gql`
    mutation unfollowTag($input: ID!) {
  unfollowTag(tagId: $input) {
    tagId
    userId
  }
}
    `;
export type UnfollowTagMutationFn = Apollo.MutationFunction<UnfollowTagMutation, UnfollowTagMutationVariables>;

/**
 * __useUnfollowTagMutation__
 *
 * To run a mutation, you first call `useUnfollowTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowTagMutation, { data, loading, error }] = useUnfollowTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowTagMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowTagMutation, UnfollowTagMutationVariables>) {
        return Apollo.useMutation<UnfollowTagMutation, UnfollowTagMutationVariables>(UnfollowTagDocument, baseOptions);
      }
export type UnfollowTagMutationHookResult = ReturnType<typeof useUnfollowTagMutation>;
export type UnfollowTagMutationResult = Apollo.MutationResult<UnfollowTagMutation>;
export type UnfollowTagMutationOptions = Apollo.BaseMutationOptions<UnfollowTagMutation, UnfollowTagMutationVariables>;
export const FollowUserDocument = gql`
    mutation followUser($input: ID!) {
  followUser(writerId: $input) {
    writerId
    userId
  }
}
    `;
export type FollowUserMutationFn = Apollo.MutationFunction<FollowUserMutation, FollowUserMutationVariables>;

/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowUserMutation(baseOptions?: Apollo.MutationHookOptions<FollowUserMutation, FollowUserMutationVariables>) {
        return Apollo.useMutation<FollowUserMutation, FollowUserMutationVariables>(FollowUserDocument, baseOptions);
      }
export type FollowUserMutationHookResult = ReturnType<typeof useFollowUserMutation>;
export type FollowUserMutationResult = Apollo.MutationResult<FollowUserMutation>;
export type FollowUserMutationOptions = Apollo.BaseMutationOptions<FollowUserMutation, FollowUserMutationVariables>;
export const FollowUserDocDocument = gql`
    query followUserDoc($input: ID!) {
  followUserDoc(writerId: $input) {
    writerId
    userId
  }
}
    `;

/**
 * __useFollowUserDocQuery__
 *
 * To run a query within a React component, call `useFollowUserDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowUserDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowUserDocQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowUserDocQuery(baseOptions: Apollo.QueryHookOptions<FollowUserDocQuery, FollowUserDocQueryVariables>) {
        return Apollo.useQuery<FollowUserDocQuery, FollowUserDocQueryVariables>(FollowUserDocDocument, baseOptions);
      }
export function useFollowUserDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowUserDocQuery, FollowUserDocQueryVariables>) {
          return Apollo.useLazyQuery<FollowUserDocQuery, FollowUserDocQueryVariables>(FollowUserDocDocument, baseOptions);
        }
export type FollowUserDocQueryHookResult = ReturnType<typeof useFollowUserDocQuery>;
export type FollowUserDocLazyQueryHookResult = ReturnType<typeof useFollowUserDocLazyQuery>;
export type FollowUserDocQueryResult = Apollo.QueryResult<FollowUserDocQuery, FollowUserDocQueryVariables>;
export const FollowUserPageDocument = gql`
    query followUserPage($userId: String, $writerId: String, $paging: CursorPagingInput, $includeUser: Boolean!, $avatarSize: ImageSizeInput) {
  followUserPage(userId: $userId, writerId: $writerId, paging: $paging) {
    items {
      _id
      writerId
      userId
      createdAt
      writer @skip(if: $includeUser) {
        ...UserBasicInfo
        ...UserFollowCount
      }
      user @include(if: $includeUser) {
        ...UserFollowCount
        ...UserBasicInfo
      }
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${UserBasicInfoFragmentDoc}
${UserFollowCountFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useFollowUserPageQuery__
 *
 * To run a query within a React component, call `useFollowUserPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowUserPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowUserPageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      writerId: // value for 'writerId'
 *      paging: // value for 'paging'
 *      includeUser: // value for 'includeUser'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useFollowUserPageQuery(baseOptions: Apollo.QueryHookOptions<FollowUserPageQuery, FollowUserPageQueryVariables>) {
        return Apollo.useQuery<FollowUserPageQuery, FollowUserPageQueryVariables>(FollowUserPageDocument, baseOptions);
      }
export function useFollowUserPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowUserPageQuery, FollowUserPageQueryVariables>) {
          return Apollo.useLazyQuery<FollowUserPageQuery, FollowUserPageQueryVariables>(FollowUserPageDocument, baseOptions);
        }
export type FollowUserPageQueryHookResult = ReturnType<typeof useFollowUserPageQuery>;
export type FollowUserPageLazyQueryHookResult = ReturnType<typeof useFollowUserPageLazyQuery>;
export type FollowUserPageQueryResult = Apollo.QueryResult<FollowUserPageQuery, FollowUserPageQueryVariables>;
export const UnfollowUserDocument = gql`
    mutation unfollowUser($input: ID!) {
  unfollowUser(writerId: $input) {
    writerId
    userId
  }
}
    `;
export type UnfollowUserMutationFn = Apollo.MutationFunction<UnfollowUserMutation, UnfollowUserMutationVariables>;

/**
 * __useUnfollowUserMutation__
 *
 * To run a mutation, you first call `useUnfollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowUserMutation, { data, loading, error }] = useUnfollowUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowUserMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowUserMutation, UnfollowUserMutationVariables>) {
        return Apollo.useMutation<UnfollowUserMutation, UnfollowUserMutationVariables>(UnfollowUserDocument, baseOptions);
      }
export type UnfollowUserMutationHookResult = ReturnType<typeof useUnfollowUserMutation>;
export type UnfollowUserMutationResult = Apollo.MutationResult<UnfollowUserMutation>;
export type UnfollowUserMutationOptions = Apollo.BaseMutationOptions<UnfollowUserMutation, UnfollowUserMutationVariables>;
export const CreateLikeDocument = gql`
    mutation createLike($input: String!) {
  createLike(postId: $input) {
    ...LikeRelationship
  }
}
    ${LikeRelationshipFragmentDoc}`;
export type CreateLikeMutationFn = Apollo.MutationFunction<CreateLikeMutation, CreateLikeMutationVariables>;

/**
 * __useCreateLikeMutation__
 *
 * To run a mutation, you first call `useCreateLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLikeMutation, { data, loading, error }] = useCreateLikeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLikeMutation(baseOptions?: Apollo.MutationHookOptions<CreateLikeMutation, CreateLikeMutationVariables>) {
        return Apollo.useMutation<CreateLikeMutation, CreateLikeMutationVariables>(CreateLikeDocument, baseOptions);
      }
export type CreateLikeMutationHookResult = ReturnType<typeof useCreateLikeMutation>;
export type CreateLikeMutationResult = Apollo.MutationResult<CreateLikeMutation>;
export type CreateLikeMutationOptions = Apollo.BaseMutationOptions<CreateLikeMutation, CreateLikeMutationVariables>;
export const LikeDocument = gql`
    query like($input: String!) {
  like(postId: $input) {
    ...LikeRelationship
  }
}
    ${LikeRelationshipFragmentDoc}`;

/**
 * __useLikeQuery__
 *
 * To run a query within a React component, call `useLikeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeQuery(baseOptions: Apollo.QueryHookOptions<LikeQuery, LikeQueryVariables>) {
        return Apollo.useQuery<LikeQuery, LikeQueryVariables>(LikeDocument, baseOptions);
      }
export function useLikeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LikeQuery, LikeQueryVariables>) {
          return Apollo.useLazyQuery<LikeQuery, LikeQueryVariables>(LikeDocument, baseOptions);
        }
export type LikeQueryHookResult = ReturnType<typeof useLikeQuery>;
export type LikeLazyQueryHookResult = ReturnType<typeof useLikeLazyQuery>;
export type LikeQueryResult = Apollo.QueryResult<LikeQuery, LikeQueryVariables>;
export const RemoveLikeDocument = gql`
    mutation removeLike($input: String!) {
  removeLike(postId: $input) {
    ...LikeRelationship
  }
}
    ${LikeRelationshipFragmentDoc}`;
export type RemoveLikeMutationFn = Apollo.MutationFunction<RemoveLikeMutation, RemoveLikeMutationVariables>;

/**
 * __useRemoveLikeMutation__
 *
 * To run a mutation, you first call `useRemoveLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLikeMutation, { data, loading, error }] = useRemoveLikeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLikeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLikeMutation, RemoveLikeMutationVariables>) {
        return Apollo.useMutation<RemoveLikeMutation, RemoveLikeMutationVariables>(RemoveLikeDocument, baseOptions);
      }
export type RemoveLikeMutationHookResult = ReturnType<typeof useRemoveLikeMutation>;
export type RemoveLikeMutationResult = Apollo.MutationResult<RemoveLikeMutation>;
export type RemoveLikeMutationOptions = Apollo.BaseMutationOptions<RemoveLikeMutation, RemoveLikeMutationVariables>;
export const ChatPageDocument = gql`
    query chatPage($paging: CursorPagingInput, $avatarSize: ImageSizeInput) {
  chatPage(paging: $paging) {
    items {
      ...Chat
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${ChatFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useChatPageQuery__
 *
 * To run a query within a React component, call `useChatPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatPageQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useChatPageQuery(baseOptions?: Apollo.QueryHookOptions<ChatPageQuery, ChatPageQueryVariables>) {
        return Apollo.useQuery<ChatPageQuery, ChatPageQueryVariables>(ChatPageDocument, baseOptions);
      }
export function useChatPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatPageQuery, ChatPageQueryVariables>) {
          return Apollo.useLazyQuery<ChatPageQuery, ChatPageQueryVariables>(ChatPageDocument, baseOptions);
        }
export type ChatPageQueryHookResult = ReturnType<typeof useChatPageQuery>;
export type ChatPageLazyQueryHookResult = ReturnType<typeof useChatPageLazyQuery>;
export type ChatPageQueryResult = Apollo.QueryResult<ChatPageQuery, ChatPageQueryVariables>;
export const InitialChatDocument = gql`
    query initialChat($input: UserInput!, $avatarSize: ImageSizeInput) {
  initialChat(input: $input) {
    _id
    relatedUser {
      ...UserBasicInfo
    }
  }
}
    ${UserBasicInfoFragmentDoc}`;

/**
 * __useInitialChatQuery__
 *
 * To run a query within a React component, call `useInitialChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialChatQuery({
 *   variables: {
 *      input: // value for 'input'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useInitialChatQuery(baseOptions: Apollo.QueryHookOptions<InitialChatQuery, InitialChatQueryVariables>) {
        return Apollo.useQuery<InitialChatQuery, InitialChatQueryVariables>(InitialChatDocument, baseOptions);
      }
export function useInitialChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitialChatQuery, InitialChatQueryVariables>) {
          return Apollo.useLazyQuery<InitialChatQuery, InitialChatQueryVariables>(InitialChatDocument, baseOptions);
        }
export type InitialChatQueryHookResult = ReturnType<typeof useInitialChatQuery>;
export type InitialChatLazyQueryHookResult = ReturnType<typeof useInitialChatLazyQuery>;
export type InitialChatQueryResult = Apollo.QueryResult<InitialChatQuery, InitialChatQueryVariables>;
export const ReadMessagesDocument = gql`
    mutation readMessages($senderId: String!) {
  readMessages(senderId: $senderId) {
    _id
    read
  }
}
    `;
export type ReadMessagesMutationFn = Apollo.MutationFunction<ReadMessagesMutation, ReadMessagesMutationVariables>;

/**
 * __useReadMessagesMutation__
 *
 * To run a mutation, you first call `useReadMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessagesMutation, { data, loading, error }] = useReadMessagesMutation({
 *   variables: {
 *      senderId: // value for 'senderId'
 *   },
 * });
 */
export function useReadMessagesMutation(baseOptions?: Apollo.MutationHookOptions<ReadMessagesMutation, ReadMessagesMutationVariables>) {
        return Apollo.useMutation<ReadMessagesMutation, ReadMessagesMutationVariables>(ReadMessagesDocument, baseOptions);
      }
export type ReadMessagesMutationHookResult = ReturnType<typeof useReadMessagesMutation>;
export type ReadMessagesMutationResult = Apollo.MutationResult<ReadMessagesMutation>;
export type ReadMessagesMutationOptions = Apollo.BaseMutationOptions<ReadMessagesMutation, ReadMessagesMutationVariables>;
export const CreateMessageDocument = gql`
    mutation createMessage($input: CreateMessageInput!) {
  createMessage(input: $input) {
    ...Message
  }
}
    ${MessageFragmentDoc}`;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, baseOptions);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const MessagePageDocument = gql`
    query messagePage($input: SearchInput!, $currentUserId: String!, $relatedUserId: String!, $paging: CursorPagingInput) {
  messagePage(
    input: $input
    currentUserId: $currentUserId
    relatedUserId: $relatedUserId
    paging: $paging
  ) {
    items {
      ...Message
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${MessageFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useMessagePageQuery__
 *
 * To run a query within a React component, call `useMessagePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagePageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      currentUserId: // value for 'currentUserId'
 *      relatedUserId: // value for 'relatedUserId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useMessagePageQuery(baseOptions: Apollo.QueryHookOptions<MessagePageQuery, MessagePageQueryVariables>) {
        return Apollo.useQuery<MessagePageQuery, MessagePageQueryVariables>(MessagePageDocument, baseOptions);
      }
export function useMessagePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagePageQuery, MessagePageQueryVariables>) {
          return Apollo.useLazyQuery<MessagePageQuery, MessagePageQueryVariables>(MessagePageDocument, baseOptions);
        }
export type MessagePageQueryHookResult = ReturnType<typeof useMessagePageQuery>;
export type MessagePageLazyQueryHookResult = ReturnType<typeof useMessagePageLazyQuery>;
export type MessagePageQueryResult = Apollo.QueryResult<MessagePageQuery, MessagePageQueryVariables>;
export const HasMessageDocument = gql`
    query hasMessage {
  hasMessage
}
    `;

/**
 * __useHasMessageQuery__
 *
 * To run a query within a React component, call `useHasMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasMessageQuery(baseOptions?: Apollo.QueryHookOptions<HasMessageQuery, HasMessageQueryVariables>) {
        return Apollo.useQuery<HasMessageQuery, HasMessageQueryVariables>(HasMessageDocument, baseOptions);
      }
export function useHasMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasMessageQuery, HasMessageQueryVariables>) {
          return Apollo.useLazyQuery<HasMessageQuery, HasMessageQueryVariables>(HasMessageDocument, baseOptions);
        }
export type HasMessageQueryHookResult = ReturnType<typeof useHasMessageQuery>;
export type HasMessageLazyQueryHookResult = ReturnType<typeof useHasMessageLazyQuery>;
export type HasMessageQueryResult = Apollo.QueryResult<HasMessageQuery, HasMessageQueryVariables>;
export const ConfirmNftPurchaseNotificationDocument = gql`
    mutation confirmNftPurchaseNotification($transactionHash: String!) {
  confirmNftPurchase(transactionHash: $transactionHash) {
    notification {
      ...NotificationFull
    }
    asset {
      ...NftInfo
    }
  }
}
    ${NotificationFullFragmentDoc}
${NftInfoFragmentDoc}`;
export type ConfirmNftPurchaseNotificationMutationFn = Apollo.MutationFunction<ConfirmNftPurchaseNotificationMutation, ConfirmNftPurchaseNotificationMutationVariables>;

/**
 * __useConfirmNftPurchaseNotificationMutation__
 *
 * To run a mutation, you first call `useConfirmNftPurchaseNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmNftPurchaseNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmNftPurchaseNotificationMutation, { data, loading, error }] = useConfirmNftPurchaseNotificationMutation({
 *   variables: {
 *      transactionHash: // value for 'transactionHash'
 *   },
 * });
 */
export function useConfirmNftPurchaseNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmNftPurchaseNotificationMutation, ConfirmNftPurchaseNotificationMutationVariables>) {
        return Apollo.useMutation<ConfirmNftPurchaseNotificationMutation, ConfirmNftPurchaseNotificationMutationVariables>(ConfirmNftPurchaseNotificationDocument, baseOptions);
      }
export type ConfirmNftPurchaseNotificationMutationHookResult = ReturnType<typeof useConfirmNftPurchaseNotificationMutation>;
export type ConfirmNftPurchaseNotificationMutationResult = Apollo.MutationResult<ConfirmNftPurchaseNotificationMutation>;
export type ConfirmNftPurchaseNotificationMutationOptions = Apollo.BaseMutationOptions<ConfirmNftPurchaseNotificationMutation, ConfirmNftPurchaseNotificationMutationVariables>;
export const ConfirmNftListNotificationDocument = gql`
    mutation confirmNftListNotification($transactionHash: String!) {
  confirmNftList(transactionHash: $transactionHash) {
    notification {
      ...NotificationFull
    }
    asset {
      ...NftInfo
    }
  }
}
    ${NotificationFullFragmentDoc}
${NftInfoFragmentDoc}`;
export type ConfirmNftListNotificationMutationFn = Apollo.MutationFunction<ConfirmNftListNotificationMutation, ConfirmNftListNotificationMutationVariables>;

/**
 * __useConfirmNftListNotificationMutation__
 *
 * To run a mutation, you first call `useConfirmNftListNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmNftListNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmNftListNotificationMutation, { data, loading, error }] = useConfirmNftListNotificationMutation({
 *   variables: {
 *      transactionHash: // value for 'transactionHash'
 *   },
 * });
 */
export function useConfirmNftListNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmNftListNotificationMutation, ConfirmNftListNotificationMutationVariables>) {
        return Apollo.useMutation<ConfirmNftListNotificationMutation, ConfirmNftListNotificationMutationVariables>(ConfirmNftListNotificationDocument, baseOptions);
      }
export type ConfirmNftListNotificationMutationHookResult = ReturnType<typeof useConfirmNftListNotificationMutation>;
export type ConfirmNftListNotificationMutationResult = Apollo.MutationResult<ConfirmNftListNotificationMutation>;
export type ConfirmNftListNotificationMutationOptions = Apollo.BaseMutationOptions<ConfirmNftListNotificationMutation, ConfirmNftListNotificationMutationVariables>;
export const ConfirmNftCancelListNotificationDocument = gql`
    mutation confirmNftCancelListNotification($transactionHash: String!) {
  confirmNftCancelList(transactionHash: $transactionHash) {
    notification {
      ...NotificationFull
    }
    asset {
      ...NftInfo
    }
  }
}
    ${NotificationFullFragmentDoc}
${NftInfoFragmentDoc}`;
export type ConfirmNftCancelListNotificationMutationFn = Apollo.MutationFunction<ConfirmNftCancelListNotificationMutation, ConfirmNftCancelListNotificationMutationVariables>;

/**
 * __useConfirmNftCancelListNotificationMutation__
 *
 * To run a mutation, you first call `useConfirmNftCancelListNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmNftCancelListNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmNftCancelListNotificationMutation, { data, loading, error }] = useConfirmNftCancelListNotificationMutation({
 *   variables: {
 *      transactionHash: // value for 'transactionHash'
 *   },
 * });
 */
export function useConfirmNftCancelListNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmNftCancelListNotificationMutation, ConfirmNftCancelListNotificationMutationVariables>) {
        return Apollo.useMutation<ConfirmNftCancelListNotificationMutation, ConfirmNftCancelListNotificationMutationVariables>(ConfirmNftCancelListNotificationDocument, baseOptions);
      }
export type ConfirmNftCancelListNotificationMutationHookResult = ReturnType<typeof useConfirmNftCancelListNotificationMutation>;
export type ConfirmNftCancelListNotificationMutationResult = Apollo.MutationResult<ConfirmNftCancelListNotificationMutation>;
export type ConfirmNftCancelListNotificationMutationOptions = Apollo.BaseMutationOptions<ConfirmNftCancelListNotificationMutation, ConfirmNftCancelListNotificationMutationVariables>;
export const ConfirmNftUpdateListNotificationDocument = gql`
    mutation confirmNftUpdateListNotification($transactionHash: String!) {
  confirmNftUpdateList(transactionHash: $transactionHash) {
    notification {
      ...NotificationFull
    }
    asset {
      ...NftInfo
    }
  }
}
    ${NotificationFullFragmentDoc}
${NftInfoFragmentDoc}`;
export type ConfirmNftUpdateListNotificationMutationFn = Apollo.MutationFunction<ConfirmNftUpdateListNotificationMutation, ConfirmNftUpdateListNotificationMutationVariables>;

/**
 * __useConfirmNftUpdateListNotificationMutation__
 *
 * To run a mutation, you first call `useConfirmNftUpdateListNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmNftUpdateListNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmNftUpdateListNotificationMutation, { data, loading, error }] = useConfirmNftUpdateListNotificationMutation({
 *   variables: {
 *      transactionHash: // value for 'transactionHash'
 *   },
 * });
 */
export function useConfirmNftUpdateListNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmNftUpdateListNotificationMutation, ConfirmNftUpdateListNotificationMutationVariables>) {
        return Apollo.useMutation<ConfirmNftUpdateListNotificationMutation, ConfirmNftUpdateListNotificationMutationVariables>(ConfirmNftUpdateListNotificationDocument, baseOptions);
      }
export type ConfirmNftUpdateListNotificationMutationHookResult = ReturnType<typeof useConfirmNftUpdateListNotificationMutation>;
export type ConfirmNftUpdateListNotificationMutationResult = Apollo.MutationResult<ConfirmNftUpdateListNotificationMutation>;
export type ConfirmNftUpdateListNotificationMutationOptions = Apollo.BaseMutationOptions<ConfirmNftUpdateListNotificationMutation, ConfirmNftUpdateListNotificationMutationVariables>;
export const NotificationPageDocument = gql`
    query notificationPage($paging: CursorPagingInput, $skipCount: Boolean = false) {
  notificationPage(paging: $paging) {
    items {
      ...NotificationFull
    }
    count @skip(if: $skipCount)
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${NotificationFullFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useNotificationPageQuery__
 *
 * To run a query within a React component, call `useNotificationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPageQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      skipCount: // value for 'skipCount'
 *   },
 * });
 */
export function useNotificationPageQuery(baseOptions?: Apollo.QueryHookOptions<NotificationPageQuery, NotificationPageQueryVariables>) {
        return Apollo.useQuery<NotificationPageQuery, NotificationPageQueryVariables>(NotificationPageDocument, baseOptions);
      }
export function useNotificationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationPageQuery, NotificationPageQueryVariables>) {
          return Apollo.useLazyQuery<NotificationPageQuery, NotificationPageQueryVariables>(NotificationPageDocument, baseOptions);
        }
export type NotificationPageQueryHookResult = ReturnType<typeof useNotificationPageQuery>;
export type NotificationPageLazyQueryHookResult = ReturnType<typeof useNotificationPageLazyQuery>;
export type NotificationPageQueryResult = Apollo.QueryResult<NotificationPageQuery, NotificationPageQueryVariables>;
export const HasNotificationDocument = gql`
    query hasNotification {
  hasNotification
}
    `;

/**
 * __useHasNotificationQuery__
 *
 * To run a query within a React component, call `useHasNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasNotificationQuery(baseOptions?: Apollo.QueryHookOptions<HasNotificationQuery, HasNotificationQueryVariables>) {
        return Apollo.useQuery<HasNotificationQuery, HasNotificationQueryVariables>(HasNotificationDocument, baseOptions);
      }
export function useHasNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasNotificationQuery, HasNotificationQueryVariables>) {
          return Apollo.useLazyQuery<HasNotificationQuery, HasNotificationQueryVariables>(HasNotificationDocument, baseOptions);
        }
export type HasNotificationQueryHookResult = ReturnType<typeof useHasNotificationQuery>;
export type HasNotificationLazyQueryHookResult = ReturnType<typeof useHasNotificationLazyQuery>;
export type HasNotificationQueryResult = Apollo.QueryResult<HasNotificationQuery, HasNotificationQueryVariables>;
export const UpdateNotificationStatusDocument = gql`
    mutation updateNotificationStatus($input: UpdateNotificationInput!) {
  updateNotificationStatus(input: $input) {
    _id
    notificationStatus
  }
}
    `;
export type UpdateNotificationStatusMutationFn = Apollo.MutationFunction<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>;

/**
 * __useUpdateNotificationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationStatusMutation, { data, loading, error }] = useUpdateNotificationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>) {
        return Apollo.useMutation<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>(UpdateNotificationStatusDocument, baseOptions);
      }
export type UpdateNotificationStatusMutationHookResult = ReturnType<typeof useUpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationResult = Apollo.MutationResult<UpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>;
export const AddUserPaymentMethodStripeDocument = gql`
    mutation addUserPaymentMethodStripe($paymentMethodId: String!, $paymentMethodProvider: PaymentMethodProviderEnum, $email: String) {
  addUserPaymentMethodStripe(
    paymentMethodId: $paymentMethodId
    paymentMethodProvider: $paymentMethodProvider
    email: $email
  ) {
    ...UserPaymentMethod
  }
}
    ${UserPaymentMethodFragmentDoc}`;
export type AddUserPaymentMethodStripeMutationFn = Apollo.MutationFunction<AddUserPaymentMethodStripeMutation, AddUserPaymentMethodStripeMutationVariables>;

/**
 * __useAddUserPaymentMethodStripeMutation__
 *
 * To run a mutation, you first call `useAddUserPaymentMethodStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserPaymentMethodStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserPaymentMethodStripeMutation, { data, loading, error }] = useAddUserPaymentMethodStripeMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      paymentMethodProvider: // value for 'paymentMethodProvider'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddUserPaymentMethodStripeMutation(baseOptions?: Apollo.MutationHookOptions<AddUserPaymentMethodStripeMutation, AddUserPaymentMethodStripeMutationVariables>) {
        return Apollo.useMutation<AddUserPaymentMethodStripeMutation, AddUserPaymentMethodStripeMutationVariables>(AddUserPaymentMethodStripeDocument, baseOptions);
      }
export type AddUserPaymentMethodStripeMutationHookResult = ReturnType<typeof useAddUserPaymentMethodStripeMutation>;
export type AddUserPaymentMethodStripeMutationResult = Apollo.MutationResult<AddUserPaymentMethodStripeMutation>;
export type AddUserPaymentMethodStripeMutationOptions = Apollo.BaseMutationOptions<AddUserPaymentMethodStripeMutation, AddUserPaymentMethodStripeMutationVariables>;
export const RemoveUserPaymentMethodDocument = gql`
    mutation removeUserPaymentMethod($_id: String!) {
  removeUserPaymentMethod(_id: $_id) {
    ...UserPaymentMethod
  }
}
    ${UserPaymentMethodFragmentDoc}`;
export type RemoveUserPaymentMethodMutationFn = Apollo.MutationFunction<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>;

/**
 * __useRemoveUserPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveUserPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserPaymentMethodMutation, { data, loading, error }] = useRemoveUserPaymentMethodMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveUserPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>) {
        return Apollo.useMutation<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>(RemoveUserPaymentMethodDocument, baseOptions);
      }
export type RemoveUserPaymentMethodMutationHookResult = ReturnType<typeof useRemoveUserPaymentMethodMutation>;
export type RemoveUserPaymentMethodMutationResult = Apollo.MutationResult<RemoveUserPaymentMethodMutation>;
export type RemoveUserPaymentMethodMutationOptions = Apollo.BaseMutationOptions<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>;
export const UserPaymentMethodsDocument = gql`
    query userPaymentMethods($userId: String!, $paymentMethodStatus: [PaymentMethodStatusEnum!]!, $paymentMethodProvider: PaymentMethodProviderEnum) {
  userPaymentMethods(
    userId: $userId
    paymentMethodStatus: $paymentMethodStatus
    paymentMethodProvider: $paymentMethodProvider
  ) {
    ...UserPaymentMethod
  }
}
    ${UserPaymentMethodFragmentDoc}`;

/**
 * __useUserPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useUserPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentMethodsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodStatus: // value for 'paymentMethodStatus'
 *      paymentMethodProvider: // value for 'paymentMethodProvider'
 *   },
 * });
 */
export function useUserPaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>) {
        return Apollo.useQuery<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>(UserPaymentMethodsDocument, baseOptions);
      }
export function useUserPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>) {
          return Apollo.useLazyQuery<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>(UserPaymentMethodsDocument, baseOptions);
        }
export type UserPaymentMethodsQueryHookResult = ReturnType<typeof useUserPaymentMethodsQuery>;
export type UserPaymentMethodsLazyQueryHookResult = ReturnType<typeof useUserPaymentMethodsLazyQuery>;
export type UserPaymentMethodsQueryResult = Apollo.QueryResult<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>;
export const PublishPostDocument = gql`
    mutation publishPost($slug: String!) {
  publishPost(slug: $slug) {
    _id
    postStatus
  }
}
    `;
export type PublishPostMutationFn = Apollo.MutationFunction<PublishPostMutation, PublishPostMutationVariables>;

/**
 * __usePublishPostMutation__
 *
 * To run a mutation, you first call `usePublishPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPostMutation, { data, loading, error }] = usePublishPostMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublishPostMutation(baseOptions?: Apollo.MutationHookOptions<PublishPostMutation, PublishPostMutationVariables>) {
        return Apollo.useMutation<PublishPostMutation, PublishPostMutationVariables>(PublishPostDocument, baseOptions);
      }
export type PublishPostMutationHookResult = ReturnType<typeof usePublishPostMutation>;
export type PublishPostMutationResult = Apollo.MutationResult<PublishPostMutation>;
export type PublishPostMutationOptions = Apollo.BaseMutationOptions<PublishPostMutation, PublishPostMutationVariables>;
export const CreatePostDocument = gql`
    mutation createPost($postType: PostTypeEnum, $signedUrlCount: Int, $isOriginalImage: Boolean) {
  createPost(postType: $postType, isOriginalImage: $isOriginalImage) {
    ...PostFull
  }
}
    ${PostFullFragmentDoc}`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      postType: // value for 'postType'
 *      signedUrlCount: // value for 'signedUrlCount'
 *      isOriginalImage: // value for 'isOriginalImage'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const ReportPostDocument = gql`
    mutation reportPost($input: ReportPostInput!) {
  reportPost(input: $input)
}
    `;
export type ReportPostMutationFn = Apollo.MutationFunction<ReportPostMutation, ReportPostMutationVariables>;

/**
 * __useReportPostMutation__
 *
 * To run a mutation, you first call `useReportPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPostMutation, { data, loading, error }] = useReportPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportPostMutation(baseOptions?: Apollo.MutationHookOptions<ReportPostMutation, ReportPostMutationVariables>) {
        return Apollo.useMutation<ReportPostMutation, ReportPostMutationVariables>(ReportPostDocument, baseOptions);
      }
export type ReportPostMutationHookResult = ReturnType<typeof useReportPostMutation>;
export type ReportPostMutationResult = Apollo.MutationResult<ReportPostMutation>;
export type ReportPostMutationOptions = Apollo.BaseMutationOptions<ReportPostMutation, ReportPostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation updatePost($input: UpdatePostInput!) {
  updatePost(input: $input) {
    _id
    slug
    cid
  }
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, baseOptions);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const RemovePostDocument = gql`
    mutation removePost($slug: String!) {
  removePost(slug: $slug) {
    _id
    postStatus
  }
}
    `;
export type RemovePostMutationFn = Apollo.MutationFunction<RemovePostMutation, RemovePostMutationVariables>;

/**
 * __useRemovePostMutation__
 *
 * To run a mutation, you first call `useRemovePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePostMutation, { data, loading, error }] = useRemovePostMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRemovePostMutation(baseOptions?: Apollo.MutationHookOptions<RemovePostMutation, RemovePostMutationVariables>) {
        return Apollo.useMutation<RemovePostMutation, RemovePostMutationVariables>(RemovePostDocument, baseOptions);
      }
export type RemovePostMutationHookResult = ReturnType<typeof useRemovePostMutation>;
export type RemovePostMutationResult = Apollo.MutationResult<RemovePostMutation>;
export type RemovePostMutationOptions = Apollo.BaseMutationOptions<RemovePostMutation, RemovePostMutationVariables>;
export const PostPageDocument = gql`
    query postPage($input: PostPageInput, $paging: CursorPagingInput, $avatarSize: ImageSizeInput) {
  postPage(input: $input, paging: $paging) {
    items {
      ...PostPagePost
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${PostPagePostFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __usePostPageQuery__
 *
 * To run a query within a React component, call `usePostPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      paging: // value for 'paging'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function usePostPageQuery(baseOptions?: Apollo.QueryHookOptions<PostPageQuery, PostPageQueryVariables>) {
        return Apollo.useQuery<PostPageQuery, PostPageQueryVariables>(PostPageDocument, baseOptions);
      }
export function usePostPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostPageQuery, PostPageQueryVariables>) {
          return Apollo.useLazyQuery<PostPageQuery, PostPageQueryVariables>(PostPageDocument, baseOptions);
        }
export type PostPageQueryHookResult = ReturnType<typeof usePostPageQuery>;
export type PostPageLazyQueryHookResult = ReturnType<typeof usePostPageLazyQuery>;
export type PostPageQueryResult = Apollo.QueryResult<PostPageQuery, PostPageQueryVariables>;
export const PostInfoWithLoggedInUserDocument = gql`
    query postInfoWithLoggedInUser($slug: String!) {
  post(slug: $slug) {
    ...PostInfoWithLoggedInUser
  }
}
    ${PostInfoWithLoggedInUserFragmentDoc}`;

/**
 * __usePostInfoWithLoggedInUserQuery__
 *
 * To run a query within a React component, call `usePostInfoWithLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostInfoWithLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostInfoWithLoggedInUserQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePostInfoWithLoggedInUserQuery(baseOptions: Apollo.QueryHookOptions<PostInfoWithLoggedInUserQuery, PostInfoWithLoggedInUserQueryVariables>) {
        return Apollo.useQuery<PostInfoWithLoggedInUserQuery, PostInfoWithLoggedInUserQueryVariables>(PostInfoWithLoggedInUserDocument, baseOptions);
      }
export function usePostInfoWithLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostInfoWithLoggedInUserQuery, PostInfoWithLoggedInUserQueryVariables>) {
          return Apollo.useLazyQuery<PostInfoWithLoggedInUserQuery, PostInfoWithLoggedInUserQueryVariables>(PostInfoWithLoggedInUserDocument, baseOptions);
        }
export type PostInfoWithLoggedInUserQueryHookResult = ReturnType<typeof usePostInfoWithLoggedInUserQuery>;
export type PostInfoWithLoggedInUserLazyQueryHookResult = ReturnType<typeof usePostInfoWithLoggedInUserLazyQuery>;
export type PostInfoWithLoggedInUserQueryResult = Apollo.QueryResult<PostInfoWithLoggedInUserQuery, PostInfoWithLoggedInUserQueryVariables>;
export const PostDocument = gql`
    query post($slug: String!, $signedUrlCount: Int, $isEdit: Boolean!, $avatarSize: ImageSizeInput, $isOriginalImage: Boolean) {
  post(slug: $slug, isOriginalImage: $isOriginalImage) {
    ...PostDetail @skip(if: $isEdit)
    ...PostFull @include(if: $isEdit)
  }
}
    ${PostDetailFragmentDoc}
${PostFullFragmentDoc}`;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      signedUrlCount: // value for 'signedUrlCount'
 *      isEdit: // value for 'isEdit'
 *      avatarSize: // value for 'avatarSize'
 *      isOriginalImage: // value for 'isOriginalImage'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>) {
        return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
      }
export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
          return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>;
export const LikedPostPageDocument = gql`
    query likedPostPage($postTypes: [PostTypeEnum]!, $paging: CursorPagingInput, $avatarSize: ImageSizeInput) {
  likedPostPage(postTypes: $postTypes, paging: $paging) {
    items {
      _id
      postId
      userId
      post {
        ...PostPagePost
      }
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${PostPagePostFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useLikedPostPageQuery__
 *
 * To run a query within a React component, call `useLikedPostPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikedPostPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikedPostPageQuery({
 *   variables: {
 *      postTypes: // value for 'postTypes'
 *      paging: // value for 'paging'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useLikedPostPageQuery(baseOptions: Apollo.QueryHookOptions<LikedPostPageQuery, LikedPostPageQueryVariables>) {
        return Apollo.useQuery<LikedPostPageQuery, LikedPostPageQueryVariables>(LikedPostPageDocument, baseOptions);
      }
export function useLikedPostPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LikedPostPageQuery, LikedPostPageQueryVariables>) {
          return Apollo.useLazyQuery<LikedPostPageQuery, LikedPostPageQueryVariables>(LikedPostPageDocument, baseOptions);
        }
export type LikedPostPageQueryHookResult = ReturnType<typeof useLikedPostPageQuery>;
export type LikedPostPageLazyQueryHookResult = ReturnType<typeof useLikedPostPageLazyQuery>;
export type LikedPostPageQueryResult = Apollo.QueryResult<LikedPostPageQuery, LikedPostPageQueryVariables>;
export const MapTokensToPostPageDocument = gql`
    query mapTokensToPostPage($purchasedOnly: Boolean!, $showSensitiveContent: Boolean, $userId: String!, $tokenIds: [String]!, $nextOffset: String!, $hasNext: Boolean!, $avatarSize: ImageSizeInput) {
  mapTokensToPostPage(
    tokenIds: $tokenIds
    nextOffset: $nextOffset
    hasNext: $hasNext
    userId: $userId
    purchasedOnly: $purchasedOnly
    showSensitiveContent: $showSensitiveContent
  ) {
    items {
      ...PostPagePost
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${PostPagePostFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useMapTokensToPostPageQuery__
 *
 * To run a query within a React component, call `useMapTokensToPostPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapTokensToPostPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapTokensToPostPageQuery({
 *   variables: {
 *      purchasedOnly: // value for 'purchasedOnly'
 *      showSensitiveContent: // value for 'showSensitiveContent'
 *      userId: // value for 'userId'
 *      tokenIds: // value for 'tokenIds'
 *      nextOffset: // value for 'nextOffset'
 *      hasNext: // value for 'hasNext'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useMapTokensToPostPageQuery(baseOptions: Apollo.QueryHookOptions<MapTokensToPostPageQuery, MapTokensToPostPageQueryVariables>) {
        return Apollo.useQuery<MapTokensToPostPageQuery, MapTokensToPostPageQueryVariables>(MapTokensToPostPageDocument, baseOptions);
      }
export function useMapTokensToPostPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MapTokensToPostPageQuery, MapTokensToPostPageQueryVariables>) {
          return Apollo.useLazyQuery<MapTokensToPostPageQuery, MapTokensToPostPageQueryVariables>(MapTokensToPostPageDocument, baseOptions);
        }
export type MapTokensToPostPageQueryHookResult = ReturnType<typeof useMapTokensToPostPageQuery>;
export type MapTokensToPostPageLazyQueryHookResult = ReturnType<typeof useMapTokensToPostPageLazyQuery>;
export type MapTokensToPostPageQueryResult = Apollo.QueryResult<MapTokensToPostPageQuery, MapTokensToPostPageQueryVariables>;
export const UserInvoicePageDocument = gql`
    query userInvoicePage($userId: String!, $paging: CursorPagingInput, $includeWriter: Boolean = false, $includeStringifyed: Boolean = false, $avatarSize: ImageSizeInput) {
  userInvoicePage(userId: $userId, paging: $paging) {
    items {
      ...UserInvoice
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${UserInvoiceFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useUserInvoicePageQuery__
 *
 * To run a query within a React component, call `useUserInvoicePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInvoicePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInvoicePageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      paging: // value for 'paging'
 *      includeWriter: // value for 'includeWriter'
 *      includeStringifyed: // value for 'includeStringifyed'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useUserInvoicePageQuery(baseOptions: Apollo.QueryHookOptions<UserInvoicePageQuery, UserInvoicePageQueryVariables>) {
        return Apollo.useQuery<UserInvoicePageQuery, UserInvoicePageQueryVariables>(UserInvoicePageDocument, baseOptions);
      }
export function useUserInvoicePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInvoicePageQuery, UserInvoicePageQueryVariables>) {
          return Apollo.useLazyQuery<UserInvoicePageQuery, UserInvoicePageQueryVariables>(UserInvoicePageDocument, baseOptions);
        }
export type UserInvoicePageQueryHookResult = ReturnType<typeof useUserInvoicePageQuery>;
export type UserInvoicePageLazyQueryHookResult = ReturnType<typeof useUserInvoicePageLazyQuery>;
export type UserInvoicePageQueryResult = Apollo.QueryResult<UserInvoicePageQuery, UserInvoicePageQueryVariables>;
export const SubscribeWithStripeDocument = gql`
    mutation subscribeWithStripe($subscriptionPlanId: String!, $userPaymentMethodId: String, $paymentMethodId: String, $paymentMethodProvider: PaymentMethodProviderEnum, $email: String) {
  subscribeWithStripe(
    subscriptionPlanId: $subscriptionPlanId
    userPaymentMethodId: $userPaymentMethodId
    paymentMethodId: $paymentMethodId
    paymentMethodProvider: $paymentMethodProvider
    email: $email
  ) {
    success
    requiresAction
    paymentIntentClientSecret
  }
}
    `;
export type SubscribeWithStripeMutationFn = Apollo.MutationFunction<SubscribeWithStripeMutation, SubscribeWithStripeMutationVariables>;

/**
 * __useSubscribeWithStripeMutation__
 *
 * To run a mutation, you first call `useSubscribeWithStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeWithStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeWithStripeMutation, { data, loading, error }] = useSubscribeWithStripeMutation({
 *   variables: {
 *      subscriptionPlanId: // value for 'subscriptionPlanId'
 *      userPaymentMethodId: // value for 'userPaymentMethodId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      paymentMethodProvider: // value for 'paymentMethodProvider'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubscribeWithStripeMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeWithStripeMutation, SubscribeWithStripeMutationVariables>) {
        return Apollo.useMutation<SubscribeWithStripeMutation, SubscribeWithStripeMutationVariables>(SubscribeWithStripeDocument, baseOptions);
      }
export type SubscribeWithStripeMutationHookResult = ReturnType<typeof useSubscribeWithStripeMutation>;
export type SubscribeWithStripeMutationResult = Apollo.MutationResult<SubscribeWithStripeMutation>;
export type SubscribeWithStripeMutationOptions = Apollo.BaseMutationOptions<SubscribeWithStripeMutation, SubscribeWithStripeMutationVariables>;
export const PurchaseExpiredContentWithStripeDocument = gql`
    mutation purchaseExpiredContentWithStripe($subscriptionPlanId: String!, $userPaymentMethodId: String, $paymentMethodId: String, $postId: String!, $paymentMethodProvider: PaymentMethodProviderEnum, $email: String) {
  purchaseExpiredContentWithStripe(
    subscriptionPlanId: $subscriptionPlanId
    userPaymentMethodId: $userPaymentMethodId
    paymentMethodId: $paymentMethodId
    postId: $postId
    paymentMethodProvider: $paymentMethodProvider
    email: $email
  ) {
    success
    requiresAction
    paymentIntentClientSecret
  }
}
    `;
export type PurchaseExpiredContentWithStripeMutationFn = Apollo.MutationFunction<PurchaseExpiredContentWithStripeMutation, PurchaseExpiredContentWithStripeMutationVariables>;

/**
 * __usePurchaseExpiredContentWithStripeMutation__
 *
 * To run a mutation, you first call `usePurchaseExpiredContentWithStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseExpiredContentWithStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseExpiredContentWithStripeMutation, { data, loading, error }] = usePurchaseExpiredContentWithStripeMutation({
 *   variables: {
 *      subscriptionPlanId: // value for 'subscriptionPlanId'
 *      userPaymentMethodId: // value for 'userPaymentMethodId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      postId: // value for 'postId'
 *      paymentMethodProvider: // value for 'paymentMethodProvider'
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePurchaseExpiredContentWithStripeMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseExpiredContentWithStripeMutation, PurchaseExpiredContentWithStripeMutationVariables>) {
        return Apollo.useMutation<PurchaseExpiredContentWithStripeMutation, PurchaseExpiredContentWithStripeMutationVariables>(PurchaseExpiredContentWithStripeDocument, baseOptions);
      }
export type PurchaseExpiredContentWithStripeMutationHookResult = ReturnType<typeof usePurchaseExpiredContentWithStripeMutation>;
export type PurchaseExpiredContentWithStripeMutationResult = Apollo.MutationResult<PurchaseExpiredContentWithStripeMutation>;
export type PurchaseExpiredContentWithStripeMutationOptions = Apollo.BaseMutationOptions<PurchaseExpiredContentWithStripeMutation, PurchaseExpiredContentWithStripeMutationVariables>;
export const CancelUserSubscriptionPlanDocument = gql`
    mutation cancelUserSubscriptionPlan($userSubscriptionPlanId: String!) {
  cancelUserSubscriptionPlan(userSubscriptionPlanId: $userSubscriptionPlanId) {
    ...UserSubscriptionPlanAfterMutation
  }
}
    ${UserSubscriptionPlanAfterMutationFragmentDoc}`;
export type CancelUserSubscriptionPlanMutationFn = Apollo.MutationFunction<CancelUserSubscriptionPlanMutation, CancelUserSubscriptionPlanMutationVariables>;

/**
 * __useCancelUserSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useCancelUserSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserSubscriptionPlanMutation, { data, loading, error }] = useCancelUserSubscriptionPlanMutation({
 *   variables: {
 *      userSubscriptionPlanId: // value for 'userSubscriptionPlanId'
 *   },
 * });
 */
export function useCancelUserSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<CancelUserSubscriptionPlanMutation, CancelUserSubscriptionPlanMutationVariables>) {
        return Apollo.useMutation<CancelUserSubscriptionPlanMutation, CancelUserSubscriptionPlanMutationVariables>(CancelUserSubscriptionPlanDocument, baseOptions);
      }
export type CancelUserSubscriptionPlanMutationHookResult = ReturnType<typeof useCancelUserSubscriptionPlanMutation>;
export type CancelUserSubscriptionPlanMutationResult = Apollo.MutationResult<CancelUserSubscriptionPlanMutation>;
export type CancelUserSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<CancelUserSubscriptionPlanMutation, CancelUserSubscriptionPlanMutationVariables>;
export const ReactivateSubscriptionDocument = gql`
    mutation reactivateSubscription($userSubscriptionPlanId: String!) {
  reactivateSubscription(userSubscriptionPlanId: $userSubscriptionPlanId) {
    ...UserSubscriptionPlanAfterMutation
  }
}
    ${UserSubscriptionPlanAfterMutationFragmentDoc}`;
export type ReactivateSubscriptionMutationFn = Apollo.MutationFunction<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;

/**
 * __useReactivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useReactivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateSubscriptionMutation, { data, loading, error }] = useReactivateSubscriptionMutation({
 *   variables: {
 *      userSubscriptionPlanId: // value for 'userSubscriptionPlanId'
 *   },
 * });
 */
export function useReactivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>) {
        return Apollo.useMutation<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>(ReactivateSubscriptionDocument, baseOptions);
      }
export type ReactivateSubscriptionMutationHookResult = ReturnType<typeof useReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationResult = Apollo.MutationResult<ReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;
export const SubscriberPageDocument = gql`
    query subscriberPage($input: SubscriberPageInput!, $paging: CursorPagingInput, $includeUser: Boolean!, $includeWriter: Boolean!, $avatarSize: ImageSizeInput, $includeStringifyed: Boolean = false) {
  subscriberPage(input: $input, paging: $paging) {
    items {
      ...UserSubscriptionPlanFull
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${UserSubscriptionPlanFullFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useSubscriberPageQuery__
 *
 * To run a query within a React component, call `useSubscriberPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriberPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriberPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      paging: // value for 'paging'
 *      includeUser: // value for 'includeUser'
 *      includeWriter: // value for 'includeWriter'
 *      avatarSize: // value for 'avatarSize'
 *      includeStringifyed: // value for 'includeStringifyed'
 *   },
 * });
 */
export function useSubscriberPageQuery(baseOptions: Apollo.QueryHookOptions<SubscriberPageQuery, SubscriberPageQueryVariables>) {
        return Apollo.useQuery<SubscriberPageQuery, SubscriberPageQueryVariables>(SubscriberPageDocument, baseOptions);
      }
export function useSubscriberPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriberPageQuery, SubscriberPageQueryVariables>) {
          return Apollo.useLazyQuery<SubscriberPageQuery, SubscriberPageQueryVariables>(SubscriberPageDocument, baseOptions);
        }
export type SubscriberPageQueryHookResult = ReturnType<typeof useSubscriberPageQuery>;
export type SubscriberPageLazyQueryHookResult = ReturnType<typeof useSubscriberPageLazyQuery>;
export type SubscriberPageQueryResult = Apollo.QueryResult<SubscriberPageQuery, SubscriberPageQueryVariables>;
export const UserSubscriptionPageDocument = gql`
    query userSubscriptionPage($input: UserSubscriptionPageInput!, $paging: CursorPagingInput, $includeUser: Boolean!, $includeWriter: Boolean!, $avatarSize: ImageSizeInput, $includeStringifyed: Boolean = false) {
  userSubscriptionPage(input: $input, paging: $paging) {
    items {
      ...UserSubscriptionPlanFull
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${UserSubscriptionPlanFullFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useUserSubscriptionPageQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      paging: // value for 'paging'
 *      includeUser: // value for 'includeUser'
 *      includeWriter: // value for 'includeWriter'
 *      avatarSize: // value for 'avatarSize'
 *      includeStringifyed: // value for 'includeStringifyed'
 *   },
 * });
 */
export function useUserSubscriptionPageQuery(baseOptions: Apollo.QueryHookOptions<UserSubscriptionPageQuery, UserSubscriptionPageQueryVariables>) {
        return Apollo.useQuery<UserSubscriptionPageQuery, UserSubscriptionPageQueryVariables>(UserSubscriptionPageDocument, baseOptions);
      }
export function useUserSubscriptionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSubscriptionPageQuery, UserSubscriptionPageQueryVariables>) {
          return Apollo.useLazyQuery<UserSubscriptionPageQuery, UserSubscriptionPageQueryVariables>(UserSubscriptionPageDocument, baseOptions);
        }
export type UserSubscriptionPageQueryHookResult = ReturnType<typeof useUserSubscriptionPageQuery>;
export type UserSubscriptionPageLazyQueryHookResult = ReturnType<typeof useUserSubscriptionPageLazyQuery>;
export type UserSubscriptionPageQueryResult = Apollo.QueryResult<UserSubscriptionPageQuery, UserSubscriptionPageQueryVariables>;
export const CreateSubscriptionPlanDocument = gql`
    mutation createSubscriptionPlan($input: CreateSubscriptionPlanInput!, $includeStringifyed: Boolean = true) {
  createSubscriptionPlan(input: $input) {
    ...SubscriptionPlanFull
  }
}
    ${SubscriptionPlanFullFragmentDoc}`;
export type CreateSubscriptionPlanMutationFn = Apollo.MutationFunction<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>;

/**
 * __useCreateSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionPlanMutation, { data, loading, error }] = useCreateSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      includeStringifyed: // value for 'includeStringifyed'
 *   },
 * });
 */
export function useCreateSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>) {
        return Apollo.useMutation<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>(CreateSubscriptionPlanDocument, baseOptions);
      }
export type CreateSubscriptionPlanMutationHookResult = ReturnType<typeof useCreateSubscriptionPlanMutation>;
export type CreateSubscriptionPlanMutationResult = Apollo.MutationResult<CreateSubscriptionPlanMutation>;
export type CreateSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionPlanMutation, CreateSubscriptionPlanMutationVariables>;
export const RemoveSubscriptionPlanDocument = gql`
    mutation removeSubscriptionPlan($input: String!, $includeStringifyed: Boolean = true, $removeAllPosts: Boolean = false) {
  removeSubscriptionPlan(_id: $input, removeAllPosts: $removeAllPosts) {
    ...SubscriptionPlanFull
  }
}
    ${SubscriptionPlanFullFragmentDoc}`;
export type RemoveSubscriptionPlanMutationFn = Apollo.MutationFunction<RemoveSubscriptionPlanMutation, RemoveSubscriptionPlanMutationVariables>;

/**
 * __useRemoveSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useRemoveSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubscriptionPlanMutation, { data, loading, error }] = useRemoveSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      includeStringifyed: // value for 'includeStringifyed'
 *      removeAllPosts: // value for 'removeAllPosts'
 *   },
 * });
 */
export function useRemoveSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSubscriptionPlanMutation, RemoveSubscriptionPlanMutationVariables>) {
        return Apollo.useMutation<RemoveSubscriptionPlanMutation, RemoveSubscriptionPlanMutationVariables>(RemoveSubscriptionPlanDocument, baseOptions);
      }
export type RemoveSubscriptionPlanMutationHookResult = ReturnType<typeof useRemoveSubscriptionPlanMutation>;
export type RemoveSubscriptionPlanMutationResult = Apollo.MutationResult<RemoveSubscriptionPlanMutation>;
export type RemoveSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<RemoveSubscriptionPlanMutation, RemoveSubscriptionPlanMutationVariables>;
export const UpdateSubscriptionPlanDocument = gql`
    mutation updateSubscriptionPlan($input: UpdateSubscriptionPlanInput!, $includeStringifyed: Boolean = true) {
  updateSubscriptionPlan(input: $input) {
    ...SubscriptionPlanFull
  }
}
    ${SubscriptionPlanFullFragmentDoc}`;
export type UpdateSubscriptionPlanMutationFn = Apollo.MutationFunction<UpdateSubscriptionPlanMutation, UpdateSubscriptionPlanMutationVariables>;

/**
 * __useUpdateSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionPlanMutation, { data, loading, error }] = useUpdateSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      includeStringifyed: // value for 'includeStringifyed'
 *   },
 * });
 */
export function useUpdateSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionPlanMutation, UpdateSubscriptionPlanMutationVariables>) {
        return Apollo.useMutation<UpdateSubscriptionPlanMutation, UpdateSubscriptionPlanMutationVariables>(UpdateSubscriptionPlanDocument, baseOptions);
      }
export type UpdateSubscriptionPlanMutationHookResult = ReturnType<typeof useUpdateSubscriptionPlanMutation>;
export type UpdateSubscriptionPlanMutationResult = Apollo.MutationResult<UpdateSubscriptionPlanMutation>;
export type UpdateSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionPlanMutation, UpdateSubscriptionPlanMutationVariables>;
export const SubscriptionPlanPageDocument = gql`
    query subscriptionPlanPage($input: SearchInput, $includeStringifyed: Boolean!) {
  subscriptionPlanPage(input: $input) {
    items {
      ...SubscriptionPlanFull
    }
    count
  }
}
    ${SubscriptionPlanFullFragmentDoc}`;

/**
 * __useSubscriptionPlanPageQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlanPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlanPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlanPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *      includeStringifyed: // value for 'includeStringifyed'
 *   },
 * });
 */
export function useSubscriptionPlanPageQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionPlanPageQuery, SubscriptionPlanPageQueryVariables>) {
        return Apollo.useQuery<SubscriptionPlanPageQuery, SubscriptionPlanPageQueryVariables>(SubscriptionPlanPageDocument, baseOptions);
      }
export function useSubscriptionPlanPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlanPageQuery, SubscriptionPlanPageQueryVariables>) {
          return Apollo.useLazyQuery<SubscriptionPlanPageQuery, SubscriptionPlanPageQueryVariables>(SubscriptionPlanPageDocument, baseOptions);
        }
export type SubscriptionPlanPageQueryHookResult = ReturnType<typeof useSubscriptionPlanPageQuery>;
export type SubscriptionPlanPageLazyQueryHookResult = ReturnType<typeof useSubscriptionPlanPageLazyQuery>;
export type SubscriptionPlanPageQueryResult = Apollo.QueryResult<SubscriptionPlanPageQuery, SubscriptionPlanPageQueryVariables>;
export const TopPostsDocument = gql`
    query topPosts($avatarSize: ImageSizeInput) {
  topPosts {
    ...TopPost
  }
}
    ${TopPostFragmentDoc}`;

/**
 * __useTopPostsQuery__
 *
 * To run a query within a React component, call `useTopPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopPostsQuery({
 *   variables: {
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useTopPostsQuery(baseOptions?: Apollo.QueryHookOptions<TopPostsQuery, TopPostsQueryVariables>) {
        return Apollo.useQuery<TopPostsQuery, TopPostsQueryVariables>(TopPostsDocument, baseOptions);
      }
export function useTopPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopPostsQuery, TopPostsQueryVariables>) {
          return Apollo.useLazyQuery<TopPostsQuery, TopPostsQueryVariables>(TopPostsDocument, baseOptions);
        }
export type TopPostsQueryHookResult = ReturnType<typeof useTopPostsQuery>;
export type TopPostsLazyQueryHookResult = ReturnType<typeof useTopPostsLazyQuery>;
export type TopPostsQueryResult = Apollo.QueryResult<TopPostsQuery, TopPostsQueryVariables>;
export const TopTagsDocument = gql`
    query TopTags {
  topTags {
    ...TopTag
  }
}
    ${TopTagFragmentDoc}`;

/**
 * __useTopTagsQuery__
 *
 * To run a query within a React component, call `useTopTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopTagsQuery(baseOptions?: Apollo.QueryHookOptions<TopTagsQuery, TopTagsQueryVariables>) {
        return Apollo.useQuery<TopTagsQuery, TopTagsQueryVariables>(TopTagsDocument, baseOptions);
      }
export function useTopTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopTagsQuery, TopTagsQueryVariables>) {
          return Apollo.useLazyQuery<TopTagsQuery, TopTagsQueryVariables>(TopTagsDocument, baseOptions);
        }
export type TopTagsQueryHookResult = ReturnType<typeof useTopTagsQuery>;
export type TopTagsLazyQueryHookResult = ReturnType<typeof useTopTagsLazyQuery>;
export type TopTagsQueryResult = Apollo.QueryResult<TopTagsQuery, TopTagsQueryVariables>;
export const TopWritersDocument = gql`
    query topWriters($avatarSize: ImageSizeInput) {
  topWriters {
    ...TopWriterFull
  }
}
    ${TopWriterFullFragmentDoc}`;

/**
 * __useTopWritersQuery__
 *
 * To run a query within a React component, call `useTopWritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopWritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopWritersQuery({
 *   variables: {
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useTopWritersQuery(baseOptions?: Apollo.QueryHookOptions<TopWritersQuery, TopWritersQueryVariables>) {
        return Apollo.useQuery<TopWritersQuery, TopWritersQueryVariables>(TopWritersDocument, baseOptions);
      }
export function useTopWritersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopWritersQuery, TopWritersQueryVariables>) {
          return Apollo.useLazyQuery<TopWritersQuery, TopWritersQueryVariables>(TopWritersDocument, baseOptions);
        }
export type TopWritersQueryHookResult = ReturnType<typeof useTopWritersQuery>;
export type TopWritersLazyQueryHookResult = ReturnType<typeof useTopWritersLazyQuery>;
export type TopWritersQueryResult = Apollo.QueryResult<TopWritersQuery, TopWritersQueryVariables>;
export const UploadFilesDocument = gql`
    mutation uploadFiles($input: UploadFileInput) {
  uploadFiles(input: $input) {
    ...CustomUploadFull
  }
}
    ${CustomUploadFullFragmentDoc}`;
export type UploadFilesMutationFn = Apollo.MutationFunction<UploadFilesMutation, UploadFilesMutationVariables>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFilesMutation(baseOptions?: Apollo.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>) {
        return Apollo.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument, baseOptions);
      }
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = Apollo.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = Apollo.BaseMutationOptions<UploadFilesMutation, UploadFilesMutationVariables>;
export const UploadPageDocument = gql`
    query uploadPage($input: SearchInput!) {
  uploadPage(input: $input) {
    items {
      _id
      url
      fileType
      uploadType
      uploadStatus
      createdAt
      updatedAt
    }
    count
    page
    pageSize
  }
}
    `;

/**
 * __useUploadPageQuery__
 *
 * To run a query within a React component, call `useUploadPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPageQuery(baseOptions: Apollo.QueryHookOptions<UploadPageQuery, UploadPageQueryVariables>) {
        return Apollo.useQuery<UploadPageQuery, UploadPageQueryVariables>(UploadPageDocument, baseOptions);
      }
export function useUploadPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadPageQuery, UploadPageQueryVariables>) {
          return Apollo.useLazyQuery<UploadPageQuery, UploadPageQueryVariables>(UploadPageDocument, baseOptions);
        }
export type UploadPageQueryHookResult = ReturnType<typeof useUploadPageQuery>;
export type UploadPageLazyQueryHookResult = ReturnType<typeof useUploadPageLazyQuery>;
export type UploadPageQueryResult = Apollo.QueryResult<UploadPageQuery, UploadPageQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!, $avatarSize: ImageSizeInput, $bannerSize: ImageSizeInput) {
  updateUser(input: $input) {
    ...UserFull
  }
}
    ${UserFullFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      avatarSize: // value for 'avatarSize'
 *      bannerSize: // value for 'bannerSize'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserCreatorTierDocument = gql`
    mutation updateUserCreatorTier {
  updateUserCreatorTier {
    _id
    creatorType {
      tier
    }
  }
}
    `;
export type UpdateUserCreatorTierMutationFn = Apollo.MutationFunction<UpdateUserCreatorTierMutation, UpdateUserCreatorTierMutationVariables>;

/**
 * __useUpdateUserCreatorTierMutation__
 *
 * To run a mutation, you first call `useUpdateUserCreatorTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCreatorTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCreatorTierMutation, { data, loading, error }] = useUpdateUserCreatorTierMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateUserCreatorTierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserCreatorTierMutation, UpdateUserCreatorTierMutationVariables>) {
        return Apollo.useMutation<UpdateUserCreatorTierMutation, UpdateUserCreatorTierMutationVariables>(UpdateUserCreatorTierDocument, baseOptions);
      }
export type UpdateUserCreatorTierMutationHookResult = ReturnType<typeof useUpdateUserCreatorTierMutation>;
export type UpdateUserCreatorTierMutationResult = Apollo.MutationResult<UpdateUserCreatorTierMutation>;
export type UpdateUserCreatorTierMutationOptions = Apollo.BaseMutationOptions<UpdateUserCreatorTierMutation, UpdateUserCreatorTierMutationVariables>;
export const UpdateUserNotificationLastSeenDocument = gql`
    mutation updateUserNotificationLastSeen {
  udpateUserNotificationLastSeen {
    notificationLastSeen
  }
}
    `;
export type UpdateUserNotificationLastSeenMutationFn = Apollo.MutationFunction<UpdateUserNotificationLastSeenMutation, UpdateUserNotificationLastSeenMutationVariables>;

/**
 * __useUpdateUserNotificationLastSeenMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationLastSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationLastSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationLastSeenMutation, { data, loading, error }] = useUpdateUserNotificationLastSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateUserNotificationLastSeenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNotificationLastSeenMutation, UpdateUserNotificationLastSeenMutationVariables>) {
        return Apollo.useMutation<UpdateUserNotificationLastSeenMutation, UpdateUserNotificationLastSeenMutationVariables>(UpdateUserNotificationLastSeenDocument, baseOptions);
      }
export type UpdateUserNotificationLastSeenMutationHookResult = ReturnType<typeof useUpdateUserNotificationLastSeenMutation>;
export type UpdateUserNotificationLastSeenMutationResult = Apollo.MutationResult<UpdateUserNotificationLastSeenMutation>;
export type UpdateUserNotificationLastSeenMutationOptions = Apollo.BaseMutationOptions<UpdateUserNotificationLastSeenMutation, UpdateUserNotificationLastSeenMutationVariables>;
export const UpdateUserMessageLastSeenDocument = gql`
    mutation updateUserMessageLastSeen {
  udpateUserMessageLastSeen {
    messageLastSeen
  }
}
    `;
export type UpdateUserMessageLastSeenMutationFn = Apollo.MutationFunction<UpdateUserMessageLastSeenMutation, UpdateUserMessageLastSeenMutationVariables>;

/**
 * __useUpdateUserMessageLastSeenMutation__
 *
 * To run a mutation, you first call `useUpdateUserMessageLastSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMessageLastSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMessageLastSeenMutation, { data, loading, error }] = useUpdateUserMessageLastSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateUserMessageLastSeenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMessageLastSeenMutation, UpdateUserMessageLastSeenMutationVariables>) {
        return Apollo.useMutation<UpdateUserMessageLastSeenMutation, UpdateUserMessageLastSeenMutationVariables>(UpdateUserMessageLastSeenDocument, baseOptions);
      }
export type UpdateUserMessageLastSeenMutationHookResult = ReturnType<typeof useUpdateUserMessageLastSeenMutation>;
export type UpdateUserMessageLastSeenMutationResult = Apollo.MutationResult<UpdateUserMessageLastSeenMutation>;
export type UpdateUserMessageLastSeenMutationOptions = Apollo.BaseMutationOptions<UpdateUserMessageLastSeenMutation, UpdateUserMessageLastSeenMutationVariables>;
export const LinkWalletAccountDocument = gql`
    mutation linkWalletAccount($walletAccount: String!) {
  linkWalletAccount(walletAccount: $walletAccount) {
    _id
    walletAccount
  }
}
    `;
export type LinkWalletAccountMutationFn = Apollo.MutationFunction<LinkWalletAccountMutation, LinkWalletAccountMutationVariables>;

/**
 * __useLinkWalletAccountMutation__
 *
 * To run a mutation, you first call `useLinkWalletAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkWalletAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkWalletAccountMutation, { data, loading, error }] = useLinkWalletAccountMutation({
 *   variables: {
 *      walletAccount: // value for 'walletAccount'
 *   },
 * });
 */
export function useLinkWalletAccountMutation(baseOptions?: Apollo.MutationHookOptions<LinkWalletAccountMutation, LinkWalletAccountMutationVariables>) {
        return Apollo.useMutation<LinkWalletAccountMutation, LinkWalletAccountMutationVariables>(LinkWalletAccountDocument, baseOptions);
      }
export type LinkWalletAccountMutationHookResult = ReturnType<typeof useLinkWalletAccountMutation>;
export type LinkWalletAccountMutationResult = Apollo.MutationResult<LinkWalletAccountMutation>;
export type LinkWalletAccountMutationOptions = Apollo.BaseMutationOptions<LinkWalletAccountMutation, LinkWalletAccountMutationVariables>;
export const MeDocument = gql`
    query me($avatarSize: ImageSizeInput, $bannerSize: ImageSizeInput) {
  me {
    ...UserFull
    email
  }
}
    ${UserFullFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      avatarSize: // value for 'avatarSize'
 *      bannerSize: // value for 'bannerSize'
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserTypeDocument = gql`
    query userType($userId: String) {
  user(userId: $userId) {
    _id
    userType
  }
}
    `;

/**
 * __useUserTypeQuery__
 *
 * To run a query within a React component, call `useUserTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTypeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTypeQuery(baseOptions?: Apollo.QueryHookOptions<UserTypeQuery, UserTypeQueryVariables>) {
        return Apollo.useQuery<UserTypeQuery, UserTypeQueryVariables>(UserTypeDocument, baseOptions);
      }
export function useUserTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTypeQuery, UserTypeQueryVariables>) {
          return Apollo.useLazyQuery<UserTypeQuery, UserTypeQueryVariables>(UserTypeDocument, baseOptions);
        }
export type UserTypeQueryHookResult = ReturnType<typeof useUserTypeQuery>;
export type UserTypeLazyQueryHookResult = ReturnType<typeof useUserTypeLazyQuery>;
export type UserTypeQueryResult = Apollo.QueryResult<UserTypeQuery, UserTypeQueryVariables>;
export const UserBasicInfoDocument = gql`
    query userBasicInfo($accountName: String, $walletAccount: String, $userId: String, $avatarSize: ImageSizeInput) {
  user(accountName: $accountName, walletAccount: $walletAccount, userId: $userId) {
    ...UserBasicInfo
  }
}
    ${UserBasicInfoFragmentDoc}`;

/**
 * __useUserBasicInfoQuery__
 *
 * To run a query within a React component, call `useUserBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBasicInfoQuery({
 *   variables: {
 *      accountName: // value for 'accountName'
 *      walletAccount: // value for 'walletAccount'
 *      userId: // value for 'userId'
 *      avatarSize: // value for 'avatarSize'
 *   },
 * });
 */
export function useUserBasicInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserBasicInfoQuery, UserBasicInfoQueryVariables>) {
        return Apollo.useQuery<UserBasicInfoQuery, UserBasicInfoQueryVariables>(UserBasicInfoDocument, baseOptions);
      }
export function useUserBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBasicInfoQuery, UserBasicInfoQueryVariables>) {
          return Apollo.useLazyQuery<UserBasicInfoQuery, UserBasicInfoQueryVariables>(UserBasicInfoDocument, baseOptions);
        }
export type UserBasicInfoQueryHookResult = ReturnType<typeof useUserBasicInfoQuery>;
export type UserBasicInfoLazyQueryHookResult = ReturnType<typeof useUserBasicInfoLazyQuery>;
export type UserBasicInfoQueryResult = Apollo.QueryResult<UserBasicInfoQuery, UserBasicInfoQueryVariables>;
export const ProfileUserDocument = gql`
    query profileUser($accountName: String, $avatarSize: ImageSizeInput, $bannerSize: ImageSizeInput) {
  user(accountName: $accountName) {
    ...UserFull
    ...UserFollowCount
    channels {
      ...Channel
    }
  }
}
    ${UserFullFragmentDoc}
${UserFollowCountFragmentDoc}
${ChannelFragmentDoc}`;

/**
 * __useProfileUserQuery__
 *
 * To run a query within a React component, call `useProfileUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserQuery({
 *   variables: {
 *      accountName: // value for 'accountName'
 *      avatarSize: // value for 'avatarSize'
 *      bannerSize: // value for 'bannerSize'
 *   },
 * });
 */
export function useProfileUserQuery(baseOptions?: Apollo.QueryHookOptions<ProfileUserQuery, ProfileUserQueryVariables>) {
        return Apollo.useQuery<ProfileUserQuery, ProfileUserQueryVariables>(ProfileUserDocument, baseOptions);
      }
export function useProfileUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileUserQuery, ProfileUserQueryVariables>) {
          return Apollo.useLazyQuery<ProfileUserQuery, ProfileUserQueryVariables>(ProfileUserDocument, baseOptions);
        }
export type ProfileUserQueryHookResult = ReturnType<typeof useProfileUserQuery>;
export type ProfileUserLazyQueryHookResult = ReturnType<typeof useProfileUserLazyQuery>;
export type ProfileUserQueryResult = Apollo.QueryResult<ProfileUserQuery, ProfileUserQueryVariables>;
export const ProfileUserPostCountDocument = gql`
    query profileUserPostCount($accountName: String, $postTypes: [PostTypeEnum]) {
  user(accountName: $accountName) {
    _id
    ...UserPostCount
    ...UserSubscribeContentCount
  }
}
    ${UserPostCountFragmentDoc}
${UserSubscribeContentCountFragmentDoc}`;

/**
 * __useProfileUserPostCountQuery__
 *
 * To run a query within a React component, call `useProfileUserPostCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserPostCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserPostCountQuery({
 *   variables: {
 *      accountName: // value for 'accountName'
 *      postTypes: // value for 'postTypes'
 *   },
 * });
 */
export function useProfileUserPostCountQuery(baseOptions?: Apollo.QueryHookOptions<ProfileUserPostCountQuery, ProfileUserPostCountQueryVariables>) {
        return Apollo.useQuery<ProfileUserPostCountQuery, ProfileUserPostCountQueryVariables>(ProfileUserPostCountDocument, baseOptions);
      }
export function useProfileUserPostCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileUserPostCountQuery, ProfileUserPostCountQueryVariables>) {
          return Apollo.useLazyQuery<ProfileUserPostCountQuery, ProfileUserPostCountQueryVariables>(ProfileUserPostCountDocument, baseOptions);
        }
export type ProfileUserPostCountQueryHookResult = ReturnType<typeof useProfileUserPostCountQuery>;
export type ProfileUserPostCountLazyQueryHookResult = ReturnType<typeof useProfileUserPostCountLazyQuery>;
export type ProfileUserPostCountQueryResult = Apollo.QueryResult<ProfileUserPostCountQuery, ProfileUserPostCountQueryVariables>;
export const UserAccountNameDocument = gql`
    query userAccountName($input: String!) {
  userAccountName(accountName: $input) {
    validationStatus
    suggestions
  }
}
    `;

/**
 * __useUserAccountNameQuery__
 *
 * To run a query within a React component, call `useUserAccountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserAccountNameQuery(baseOptions: Apollo.QueryHookOptions<UserAccountNameQuery, UserAccountNameQueryVariables>) {
        return Apollo.useQuery<UserAccountNameQuery, UserAccountNameQueryVariables>(UserAccountNameDocument, baseOptions);
      }
export function useUserAccountNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAccountNameQuery, UserAccountNameQueryVariables>) {
          return Apollo.useLazyQuery<UserAccountNameQuery, UserAccountNameQueryVariables>(UserAccountNameDocument, baseOptions);
        }
export type UserAccountNameQueryHookResult = ReturnType<typeof useUserAccountNameQuery>;
export type UserAccountNameLazyQueryHookResult = ReturnType<typeof useUserAccountNameLazyQuery>;
export type UserAccountNameQueryResult = Apollo.QueryResult<UserAccountNameQuery, UserAccountNameQueryVariables>;
export const CreateUserPayoutDocument = gql`
    mutation createUserPayout($input: CreateUserPayoutInput!) {
  createUserPayout(input: $input) {
    ...UserPayout
  }
}
    ${UserPayoutFragmentDoc}`;
export type CreateUserPayoutMutationFn = Apollo.MutationFunction<CreateUserPayoutMutation, CreateUserPayoutMutationVariables>;

/**
 * __useCreateUserPayoutMutation__
 *
 * To run a mutation, you first call `useCreateUserPayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPayoutMutation, { data, loading, error }] = useCreateUserPayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserPayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserPayoutMutation, CreateUserPayoutMutationVariables>) {
        return Apollo.useMutation<CreateUserPayoutMutation, CreateUserPayoutMutationVariables>(CreateUserPayoutDocument, baseOptions);
      }
export type CreateUserPayoutMutationHookResult = ReturnType<typeof useCreateUserPayoutMutation>;
export type CreateUserPayoutMutationResult = Apollo.MutationResult<CreateUserPayoutMutation>;
export type CreateUserPayoutMutationOptions = Apollo.BaseMutationOptions<CreateUserPayoutMutation, CreateUserPayoutMutationVariables>;
export const UserPayoutPageDocument = gql`
    query userPayoutPage($userId: String!, $paging: CursorPagingInput) {
  userPayoutPage(userId: $userId, paging: $paging) {
    items {
      ...UserPayout
    }
    paging {
      ...CursorPagingInfo
    }
  }
}
    ${UserPayoutFragmentDoc}
${CursorPagingInfoFragmentDoc}`;

/**
 * __useUserPayoutPageQuery__
 *
 * To run a query within a React component, call `useUserPayoutPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPayoutPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPayoutPageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useUserPayoutPageQuery(baseOptions: Apollo.QueryHookOptions<UserPayoutPageQuery, UserPayoutPageQueryVariables>) {
        return Apollo.useQuery<UserPayoutPageQuery, UserPayoutPageQueryVariables>(UserPayoutPageDocument, baseOptions);
      }
export function useUserPayoutPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPayoutPageQuery, UserPayoutPageQueryVariables>) {
          return Apollo.useLazyQuery<UserPayoutPageQuery, UserPayoutPageQueryVariables>(UserPayoutPageDocument, baseOptions);
        }
export type UserPayoutPageQueryHookResult = ReturnType<typeof useUserPayoutPageQuery>;
export type UserPayoutPageLazyQueryHookResult = ReturnType<typeof useUserPayoutPageLazyQuery>;
export type UserPayoutPageQueryResult = Apollo.QueryResult<UserPayoutPageQuery, UserPayoutPageQueryVariables>;
export const UpdateAutoTransferDocument = gql`
    mutation updateAutoTransfer($receivePaymentMethodId: String!, $autoTransfer: Boolean!) {
  updateAutoTransfer(
    receivePaymentMethodId: $receivePaymentMethodId
    autoTransfer: $autoTransfer
  ) {
    ...UserReceivePaymentMethod
  }
}
    ${UserReceivePaymentMethodFragmentDoc}`;
export type UpdateAutoTransferMutationFn = Apollo.MutationFunction<UpdateAutoTransferMutation, UpdateAutoTransferMutationVariables>;

/**
 * __useUpdateAutoTransferMutation__
 *
 * To run a mutation, you first call `useUpdateAutoTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoTransferMutation, { data, loading, error }] = useUpdateAutoTransferMutation({
 *   variables: {
 *      receivePaymentMethodId: // value for 'receivePaymentMethodId'
 *      autoTransfer: // value for 'autoTransfer'
 *   },
 * });
 */
export function useUpdateAutoTransferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutoTransferMutation, UpdateAutoTransferMutationVariables>) {
        return Apollo.useMutation<UpdateAutoTransferMutation, UpdateAutoTransferMutationVariables>(UpdateAutoTransferDocument, baseOptions);
      }
export type UpdateAutoTransferMutationHookResult = ReturnType<typeof useUpdateAutoTransferMutation>;
export type UpdateAutoTransferMutationResult = Apollo.MutationResult<UpdateAutoTransferMutation>;
export type UpdateAutoTransferMutationOptions = Apollo.BaseMutationOptions<UpdateAutoTransferMutation, UpdateAutoTransferMutationVariables>;
export const CreateUserReceivePaymentMethodDocument = gql`
    mutation createUserReceivePaymentMethod($input: CreateUserReceivePaymentMethodInput!) {
  createUserReceivePaymentMethod(input: $input) {
    ...UserReceivePaymentMethod
  }
}
    ${UserReceivePaymentMethodFragmentDoc}`;
export type CreateUserReceivePaymentMethodMutationFn = Apollo.MutationFunction<CreateUserReceivePaymentMethodMutation, CreateUserReceivePaymentMethodMutationVariables>;

/**
 * __useCreateUserReceivePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreateUserReceivePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserReceivePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserReceivePaymentMethodMutation, { data, loading, error }] = useCreateUserReceivePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserReceivePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserReceivePaymentMethodMutation, CreateUserReceivePaymentMethodMutationVariables>) {
        return Apollo.useMutation<CreateUserReceivePaymentMethodMutation, CreateUserReceivePaymentMethodMutationVariables>(CreateUserReceivePaymentMethodDocument, baseOptions);
      }
export type CreateUserReceivePaymentMethodMutationHookResult = ReturnType<typeof useCreateUserReceivePaymentMethodMutation>;
export type CreateUserReceivePaymentMethodMutationResult = Apollo.MutationResult<CreateUserReceivePaymentMethodMutation>;
export type CreateUserReceivePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreateUserReceivePaymentMethodMutation, CreateUserReceivePaymentMethodMutationVariables>;
export const UpdateUserReceivePaymentMethodDocument = gql`
    mutation updateUserReceivePaymentMethod($input: UpdateUserReceivePaymentMethodInput!) {
  updateUserReceivePaymentMethod(input: $input) {
    ...UserReceivePaymentMethod
  }
}
    ${UserReceivePaymentMethodFragmentDoc}`;
export type UpdateUserReceivePaymentMethodMutationFn = Apollo.MutationFunction<UpdateUserReceivePaymentMethodMutation, UpdateUserReceivePaymentMethodMutationVariables>;

/**
 * __useUpdateUserReceivePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateUserReceivePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserReceivePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserReceivePaymentMethodMutation, { data, loading, error }] = useUpdateUserReceivePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserReceivePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserReceivePaymentMethodMutation, UpdateUserReceivePaymentMethodMutationVariables>) {
        return Apollo.useMutation<UpdateUserReceivePaymentMethodMutation, UpdateUserReceivePaymentMethodMutationVariables>(UpdateUserReceivePaymentMethodDocument, baseOptions);
      }
export type UpdateUserReceivePaymentMethodMutationHookResult = ReturnType<typeof useUpdateUserReceivePaymentMethodMutation>;
export type UpdateUserReceivePaymentMethodMutationResult = Apollo.MutationResult<UpdateUserReceivePaymentMethodMutation>;
export type UpdateUserReceivePaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateUserReceivePaymentMethodMutation, UpdateUserReceivePaymentMethodMutationVariables>;
export const UserReceivePaymentMethodsDocument = gql`
    query userReceivePaymentMethods($userId: String!) {
  userReceivePaymentMethods(userId: $userId) {
    ...UserReceivePaymentMethod
  }
}
    ${UserReceivePaymentMethodFragmentDoc}`;

/**
 * __useUserReceivePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useUserReceivePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReceivePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReceivePaymentMethodsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserReceivePaymentMethodsQuery(baseOptions: Apollo.QueryHookOptions<UserReceivePaymentMethodsQuery, UserReceivePaymentMethodsQueryVariables>) {
        return Apollo.useQuery<UserReceivePaymentMethodsQuery, UserReceivePaymentMethodsQueryVariables>(UserReceivePaymentMethodsDocument, baseOptions);
      }
export function useUserReceivePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserReceivePaymentMethodsQuery, UserReceivePaymentMethodsQueryVariables>) {
          return Apollo.useLazyQuery<UserReceivePaymentMethodsQuery, UserReceivePaymentMethodsQueryVariables>(UserReceivePaymentMethodsDocument, baseOptions);
        }
export type UserReceivePaymentMethodsQueryHookResult = ReturnType<typeof useUserReceivePaymentMethodsQuery>;
export type UserReceivePaymentMethodsLazyQueryHookResult = ReturnType<typeof useUserReceivePaymentMethodsLazyQuery>;
export type UserReceivePaymentMethodsQueryResult = Apollo.QueryResult<UserReceivePaymentMethodsQuery, UserReceivePaymentMethodsQueryVariables>;
export const EnabledPayoutProvidersDocument = gql`
    query enabledPayoutProviders {
  enabledPayoutProviders
}
    `;

/**
 * __useEnabledPayoutProvidersQuery__
 *
 * To run a query within a React component, call `useEnabledPayoutProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnabledPayoutProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnabledPayoutProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnabledPayoutProvidersQuery(baseOptions?: Apollo.QueryHookOptions<EnabledPayoutProvidersQuery, EnabledPayoutProvidersQueryVariables>) {
        return Apollo.useQuery<EnabledPayoutProvidersQuery, EnabledPayoutProvidersQueryVariables>(EnabledPayoutProvidersDocument, baseOptions);
      }
export function useEnabledPayoutProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnabledPayoutProvidersQuery, EnabledPayoutProvidersQueryVariables>) {
          return Apollo.useLazyQuery<EnabledPayoutProvidersQuery, EnabledPayoutProvidersQueryVariables>(EnabledPayoutProvidersDocument, baseOptions);
        }
export type EnabledPayoutProvidersQueryHookResult = ReturnType<typeof useEnabledPayoutProvidersQuery>;
export type EnabledPayoutProvidersLazyQueryHookResult = ReturnType<typeof useEnabledPayoutProvidersLazyQuery>;
export type EnabledPayoutProvidersQueryResult = Apollo.QueryResult<EnabledPayoutProvidersQuery, EnabledPayoutProvidersQueryVariables>;
export const UserWalletDocument = gql`
    query userWallet($startAt: String, $currency: CurrencyEnum, $includeDetail: Boolean = false) {
  userWallet(startAt: $startAt, currency: $currency) {
    ...UserWallet
  }
}
    ${UserWalletFragmentDoc}`;

/**
 * __useUserWalletQuery__
 *
 * To run a query within a React component, call `useUserWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWalletQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      currency: // value for 'currency'
 *      includeDetail: // value for 'includeDetail'
 *   },
 * });
 */
export function useUserWalletQuery(baseOptions?: Apollo.QueryHookOptions<UserWalletQuery, UserWalletQueryVariables>) {
        return Apollo.useQuery<UserWalletQuery, UserWalletQueryVariables>(UserWalletDocument, baseOptions);
      }
export function useUserWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWalletQuery, UserWalletQueryVariables>) {
          return Apollo.useLazyQuery<UserWalletQuery, UserWalletQueryVariables>(UserWalletDocument, baseOptions);
        }
export type UserWalletQueryHookResult = ReturnType<typeof useUserWalletQuery>;
export type UserWalletLazyQueryHookResult = ReturnType<typeof useUserWalletLazyQuery>;
export type UserWalletQueryResult = Apollo.QueryResult<UserWalletQuery, UserWalletQueryVariables>;