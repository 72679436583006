import {
  PostPage,
  UserSubscriptionPage,
  UserPayoutPage,
  MappedPostPage,
  UserInvoicePage,
  CommentPage,
} from "@/generated/generated-hooks";

import { chatPageMergeFn } from "./chatPageMergeFn";
import { cursorMergeFn } from "./cursorMergeFn";
import { followChannelPageMergeFn } from "./followChannelPageMergeFn";
import { followUserPageMergeFn } from "./followUserPageMergeFn";
import { likedPostPageMergeFn } from "./likedPostPageMergeFn";
import { messagePageMergeFn } from "./messagePageMergeFn";
import { notificationPageMergeFn } from "./notificationPageMergeFn";

export const typePolicies = {
  Query: {
    fields: {
      commentPage: {
        keyArgs: ["input"],
        merge: cursorMergeFn<CommentPage>(),
      },
      messagePage: {
        keyArgs: ["currentUserId", "relatedUserId"],
        merge: messagePageMergeFn,
      },
      chatPage: {
        keyArgs: false as const,
        merge: chatPageMergeFn,
      },
      userPaymentMethods: {
        keyArgs: false as const,
        merge: (_, incoming) => incoming,
      },
      followChannelPage: {
        keyArgs: ["input"],
        merge:
          followChannelPageMergeFn /* This is only applicable for current user getting a list of following channel, if this query is called by everyone in the future, just replace the whole mergeFn with cursorMergeFn */,
      },
      followUserPage: {
        keyArgs: ["userId", "writerId"],
        merge:
          followUserPageMergeFn /* This is only applicable for current user getting a list of following channel, if this query is called by everyone in the future, just replace the whole mergeFn with cursorMergeFn */,
      },
      subscriberPage: {
        keyArgs: ["input"],
        merge: cursorMergeFn<UserSubscriptionPage>(),
      },
      userSubscriptionPage: {
        keyArgs: ["input"],
        merge: cursorMergeFn<UserSubscriptionPage>(),
      },
      userPayoutPage: {
        keyArgs: ["userId"],
        merge: cursorMergeFn<UserPayoutPage>(),
      },
      userInvoicePage: {
        keyArgs: ["userId"],
        merge: cursorMergeFn<UserInvoicePage>(),
      },
      postPage: {
        keyArgs: ["input"],
        merge: cursorMergeFn<PostPage>(),
      },
      likedPostPage: {
        keyArgs: ["postType"],
        merge: likedPostPageMergeFn,
      },
      notificationPage: {
        keyArgs: false as const,
        merge: notificationPageMergeFn,
      },
      mapTokensToPostPage: {
        keyArgs: ["userId", "purchasedOnly", "showSensitiveContent"],
        merge: cursorMergeFn<MappedPostPage>(),
      },
    },
  },
};
