import { datadogLogs } from "@datadog/browser-logs";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const { env, project, service } = getPublicConfig();

export function initDatadogBrowserLogger() {
  datadogLogs.init({
    service: project.projectSlug,
    clientToken: service.datadog.clientToken,
    site: "datadoghq.com",
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    env: env.vercelEnv,
    version: project.version,
  });
}
