import clsx from "clsx";
import debounce from "lodash.debounce";
import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import React, { useCallback, useEffect } from "react";

import { isMdDown, useBreakpoint } from "@/features/common/hooks/useBreakpoint";
import { getPublicConfig } from "@/helpers/getPublicConfig";

const Footer = dynamic(
  () => import("@/features/common/components/navigation/Footer"),
  { ssr: false },
);

const DesktopHeader = dynamic(
  () => import("@/features/common/components/navigation/desktop/DesktopHeader"),
  { ssr: false },
);

const BottomAppBar = dynamic(
  () => import("@/features/common/components/navigation/mobile/BottomAppBar"),
  { ssr: false },
);

const MobileHeader = dynamic(
  () => import("@/features/common/components/navigation/mobile/MobileHeader"),
  { ssr: false },
);

type Props = {
  children: React.ReactNode;
  showFooter?: boolean;
  margin?: string;
};

const config = getPublicConfig();

const SiteLayout: React.FC<Props> = ({
  children,
  margin = "my-16 md:my-0",
  showFooter = false,
}) => {
  const breakpoint = useBreakpoint();
  const mdDown = isMdDown(breakpoint);

  const checkScrollBeyond = useCallback(() => {
    debounce(() => {
      const html = document.querySelector("html");
      const maxScrollY = html.scrollHeight - html.clientHeight + 300;
      if (window.scrollY > maxScrollY)
        window.scrollTo({ top: maxScrollY, behavior: "smooth" });
    }, 200);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollBeyond);
    return () => {
      window.removeEventListener("scroll", checkScrollBeyond);
    };
  }, [checkScrollBeyond]);

  return (
    <>
      <NextSeo {...config.seo.defaultConfig} />

      {/* fix height h-16 */}
      <nav
        className="fixed md:sticky p-2 top-0 flex w-full justify-center items-center bg-white shadow z-50 h-16"
        id="top-bar"
      >
        {mdDown ? <MobileHeader /> : <DesktopHeader />}
      </nav>

      <div className={clsx("flex flex-col flex-1 space-y-2", margin)}>
        <div className="flex-1">{children}</div>
        {!mdDown && showFooter && <Footer />}
      </div>
      {/* fix height h-16 */}
      {mdDown && <BottomAppBar />}
    </>
  );
};

export default SiteLayout;
