import { RouteName } from "@/consts";
import { getPublicConfig } from "@/helpers/getPublicConfig";

type GetChannelPathArgs = {
  accountName: string;
  channelSlug: string;
};

export const getChannelPath = (args: GetChannelPathArgs) => {
  const { accountName, channelSlug } = args;
  return `${RouteName.ProfileUser}${accountName}${RouteName.Channel}/${channelSlug}`;
};

export const getAbsoluteChannelPath = (args: GetChannelPathArgs) => {
  const { domain } = getPublicConfig();
  const { accountName, channelSlug } = args;
  return `${domain}${RouteName.ProfileUser}${accountName}${RouteName.Channel}/${channelSlug}`;
};

export const getUserProfilePath = (accountName: string) =>
  `${RouteName.ProfileUser}${accountName}`;
export const getAbsoluteUserProfilePath = (accountName: string) => {
  const { domain } = getPublicConfig();
  return `${domain}${RouteName.ProfileUser}${accountName}`;
};

export const getPostPath = (slug: string) => `${RouteName.Post}/${slug}`;
export const getCreatePostPath = (slug: string) =>
  `${RouteName.NewPost}/${slug}`;

export const getAbsolutePostPath = (slug: string) => {
  const { domain } = getPublicConfig();
  return `${domain}${RouteName.Post}/${slug}`;
};

export const getAbsoluteTagPath = (value: string) => {
  const { domain } = getPublicConfig();
  return `${domain}${RouteName.Tag}/${value}`;
};

type EtherscanUrlType = "transaction" | "contract" | "token" | "block";

export const getIpfsUri = (cid: string) => {
  return `ipfs://${cid}/metadata.json`;
};

export const getEtherscanUrl = (
  address: string,
  type: EtherscanUrlType = "transaction",
) => {
  const {
    service: {
      etherscan: { rinkeby, mainnet },
    },
    isProduction,
  } = getPublicConfig();

  const host = isProduction ? mainnet : rinkeby;
  const urlPrefix = getUrlPrefix(type);
  return `${host}/${urlPrefix}/${address}`;
};

const getUrlPrefix = (type: EtherscanUrlType) => {
  switch (type) {
    case "transaction":
      return "tx";
    case "contract":
      return "address";
    case "token":
      return "token";
    case "block":
      return "block";
    default:
      return "address";
  }
};
