import * as Sentry from "@sentry/nextjs";
import { useState } from "react";

type LoginMethod = "twitter" | "google" | "facebook" | "email" | "anonymous";

enum FirebaseErrorCode {
  AccountExistsWithDifferentCredentials = "auth/account-exists-with-different-credential",
  PopupClosedByUser = "auth/popup-closed-by-user",
}

export function useSignInHelper() {
  const [errorCode, setErrorCode] = useState();
  const [authInProgress, setAuthInProgress] = useState(false);

  const onStart = () => setAuthInProgress(true);
  const getOnFailed = (method: LoginMethod) => (error) => {
    if (
      error.code === FirebaseErrorCode.AccountExistsWithDifferentCredentials ||
      error.code === FirebaseErrorCode.PopupClosedByUser
    )
      return setErrorCode(error.code);

    Sentry.captureException(error, {
      tags: { method },
    });
  };
  const onFinally = () => setAuthInProgress(false);

  return {
    authInProgress,
    errorCode,
    setErrorCode,
    onStart,
    getOnFailed,
    onFinally,
  };
}
