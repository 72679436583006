import { Dialog, Transition, TransitionClasses } from "@headlessui/react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef, FC, ReactNode } from "react";

export const FullScreenDialog: FC<{
  open: boolean;
  className?: string;
  closeButton?: ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
  transitionClassess?: TransitionClasses;
  header?: ReactNode;
}> = ({
  open,
  children,
  header,
  transitionClassess = {
    enter: "ease-out duration-300",
    enterFrom: "opacity-0 translate-x-0 scale-95",
    enterTo: "opacity-100 scale-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100 scale-100",
    leaveTo: "opacity-0 scale-95",
  },
}) => {
  let refDiv = useRef(null);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        className="fixed inset-0 z-modal"
        onClose={() => {}}
      >
        <Transition.Child as={Fragment} {...transitionClassess}>
          <div className="w-full h-full flex flex-col bg-white">
            {header && (
              <div className="relative flex h-16 justify-center items-center shadow z-10">
                {header}
              </div>
            )}
            <div className="flex-1 min-h-0">{children}</div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

type DialogBackButtonProps = {
  onClick: () => void;
};

export const DialogBackButton: React.FC<DialogBackButtonProps> = (props) => {
  const { onClick } = props;

  return (
    <span onClick={onClick} className="absolute left-4 text-gray-300">
      <ChevronLeftIcon className="w-5 h-5" />
    </span>
  );
};
