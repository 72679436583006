import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import * as Sentry from "@sentry/nextjs";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { GetServerSidePropsContext } from "next";

import { createIsomorphLink } from "./helpers/createIsomorphLink";
import { createIsomorphLinkSSR } from "./helpers/createIsomorphLinkSSR";
import { typePolicies } from "./typePolicies";

let apolloClient: ApolloClient<NormalizedCacheObject>;

const retryLink = new RetryLink({
  delay: { initial: 1000 },
  attempts: {
    max: 3,
    retryIf(error, operation) {
      return [
        "profileUserPostCount",
        "subscriptionPlanPage",
        "postPage",
      ].includes(operation.operationName);
    },
  },
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    Sentry.captureException(graphQLErrors, {
      tags: { fn: "ApolloClient.graphQLErrors" },
    });
  }

  if (networkError) {
    Sentry.captureException(networkError, {
      tags: { fn: "ApolloClient.networkError" },
    });
  }
});

function createApolloClient(ctx?: GetServerSidePropsContext<any>) {
  const ssrMode = typeof window === "undefined";
  return new ApolloClient({
    ssrMode,
    link: retryLink.concat(
      errorLink.concat(
        ssrMode ? createIsomorphLinkSSR(ctx) : createIsomorphLink(ctx),
      ),
    ),
    cache: new InMemoryCache({ typePolicies }),
  });
}

export function initializeApollo(
  initialState: any = null,
  ctx?: GetServerSidePropsContext<any>,
) {
  const _apolloClient = apolloClient ?? createApolloClient(ctx);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });
    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}
