import { Dialog, Transition } from "@headlessui/react";
import dynamic from "next/dynamic";
import React, { Fragment, useCallback, useEffect, useRef } from "react";

import { DialogEnum } from "@/consts";
import { DialogCloseButton } from "@/features/common/components/DialogCloseButton";
import { useUIContext } from "@/features/common/hooks/UIContext/useUIContext";
import { useUserContext } from "@/features/user/contexts/useUserContext";
import { useSignInHelper } from "@/features/user/hooks/signIn/useSignInHelper";

const AuthenticationView = dynamic(
  () => import("../AuthenticationView").then((mod) => mod.AuthenticationView),
  { ssr: false },
);

type AuthenticationDialogProps = {
  open: boolean;
  dialogEnum: DialogEnum;
};

export const AuthenticationDialog: React.FC<AuthenticationDialogProps> = (
  props,
) => {
  const { open, dialogEnum } = props;
  const { setOpen } = useUIContext();
  const { user } = useUserContext();
  const { authInProgress, setErrorCode } = useSignInHelper();

  const onClose = useCallback(() => {
    setOpen(false);
    setErrorCode(null);
  }, [setErrorCode, setOpen]);

  useEffect(() => {
    if (!user || !open) return;
    onClose();
  }, [user, open, onClose]);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-modal inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity z-[-1]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block align-middle h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div className="max-w-450 inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 align-middle w-full relative">
              <div className="absolute top-0 right-0" ref={cancelButtonRef}>
                <DialogCloseButton
                  className="p-4"
                  onClick={onClose}
                  disabled={authInProgress}
                />
              </div>

              <AuthenticationView
                onCloseDialog={onClose}
                dialogEnum={dialogEnum}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
