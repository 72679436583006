import { Dialog } from "@headlessui/react";
import { useTranslation } from "next-i18next";
import React, { FC, ReactNode } from "react";

import { OutlinedButton } from "@/features/common/components/button/OutlinedButton";

type StandardConfirmDialogViewProps = {
  title: ReactNode;
  message: ReactNode;
  loading?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const StandardConfirmDialogView: FC<StandardConfirmDialogViewProps> = ({
  title,
  message,
  hideCancelButton,
  hideConfirmButton,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  className = "py-7 px-2 md:px-14",
  loading,
  ...attributes
}) => {
  const { t } = useTranslation("common");
  return (
    <div className={className} {...attributes}>
      <Dialog.Title
        as="h3"
        className="flex justify-center text-2xl  text-gray-900"
      >
        {title}
      </Dialog.Title>

      <div className="mt-5 text-base  text-gray-900 text-center">{message}</div>

      <div className="w-full flex justify-center mt-5">
        {!hideCancelButton && (
          <OutlinedButton
            variant="secondary"
            buttonProps={{ onClick: onCancel }}
            utilityClassName="min-w-100"
          >
            {cancelLabel ?? t("common:cancel")}
          </OutlinedButton>
        )}

        {!hideConfirmButton && (
          <OutlinedButton
            variant="primary"
            buttonProps={{ onClick: onConfirm, disabled: loading }}
            utilityClassName="ml-3.5 min-w-100"
          >
            {confirmLabel ?? t("common:confirm")}
          </OutlinedButton>
        )}
      </div>
    </div>
  );
};
