import firebase from "firebase/app";
import jwt_decode from "jwt-decode";

import { UserInCookie } from "./type";

export const mapUserData = async (user: any): Promise<UserInCookie | null> => {
  const { uid, email } = user;
  const token = await firebase.auth().currentUser.getIdToken();
  const decoded = jwt_decode<{ exp: number }>(token);

  const expiryDate = new Date(decoded.exp * 1000);

  return {
    id: uid,
    email,
    token,
    expiryDate,
  };
};
