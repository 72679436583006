import { FieldMergeFunction } from "@apollo/client";

import { MessagePage } from "@/generated/generated-hooks";

// @todo update the merge function to support polling
export const messagePageMergeFn: FieldMergeFunction<MessagePage> = (
  existing,
  incoming,
) => {
  const existingItems = existing?.items ?? [];

  const incomingItems = [...(incoming?.items ?? [])].reverse();

  const mergedItems = [...incomingItems, ...existingItems];

  // console.log("messagePageMerge", {
  //   existingItems,
  //   incomingItems,
  //   mergedItems,
  // });

  return { ...incoming, items: mergedItems };
};
