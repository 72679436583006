import { TFunction } from "next-i18next";

import { StepsDialogProps } from "@/features/nft/components/StepsDialog";
import { TransactionActionTypeEnum } from "@/generated/generated-hooks";
import { getEtherscanUrl } from "@/helpers/common/path";

export type GetTransactionStepArgs = {
  t: TFunction;
  transactionHash: string;
  type: TransactionActionTypeEnum;
};

export const getTransactionStep = ({
  t,
  transactionHash,
  type,
}: GetTransactionStepArgs): StepsDialogProps["steps"][0] => {
  const href = getEtherscanUrl(transactionHash, "transaction");

  const getTitle = () => {
    switch (type) {
      case TransactionActionTypeEnum.Mint:
        return t("common:nft.createNft");
      case TransactionActionTypeEnum.Buy:
        return t("common:nft.buyCollectible");
      case TransactionActionTypeEnum.List:
        return t("common:nft.putOnSale");
      case TransactionActionTypeEnum.CancelList:
        return t("common:nft.cancelList");
      case TransactionActionTypeEnum.GenerateIpfsCid:
        return t("common:nft.generatingCid");
      case TransactionActionTypeEnum.UpdateListedPrice:
        return t("common:nft.updateListedPrice");
      case TransactionActionTypeEnum.UpdateTokenCid:
        return t("common:nft.updateCid");
      case TransactionActionTypeEnum.ApproveAll:
        return t("common:nft.approveForAll");
      default:
        return "";
    }
  };

  /* Getting Description for list event in creating single collectible */
  const getDescription = () => {
    switch (type) {
      case TransactionActionTypeEnum.List:
        return t("common:nft.putOnSaleDescription");
      default:
        return "";
    }
  };

  const title = getTitle();

  return {
    title,
    description: (
      <div className="truncate">
        {transactionHash ? (
          <a target="_blank" rel="noopener noreferrer" href={href}>
            {transactionHash}
          </a>
        ) : (
          getDescription()
        )}
      </div>
    ),
  };
};
