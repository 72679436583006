import firebase from "firebase/app";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import { RemoveScroll } from "react-remove-scroll";

import { AnalyticsEventEnum } from "@/consts/AnalyticsEventEnum";
import {
  FullScreenDialog,
  DialogBackButton,
} from "@/features/common/components/layout/FullScreenDialog";
import { StandardDialog } from "@/features/common/components/layout/StandardDialog";
import { isMdDown, useBreakpoint } from "@/features/common/hooks/useBreakpoint";
import { DetailPostPlaceholder } from "@/features/post/components/DetailPostPlaceholder";

const DynamicPostDetail = dynamic(
  () =>
    import("@/features/post/components/postDetail/PostDetail").then(
      (mod) => mod.PostDetail,
    ),
  { loading: () => <DetailPostPlaceholder /> },
);

export const PostDialog = () => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const breakpoint = useBreakpoint();
  const mdDown = isMdDown(breakpoint);

  const onClose =
    (
      analyticsEvent:
        | AnalyticsEventEnum.PostDialogClose
        | AnalyticsEventEnum.PostDialogCloseMobile,
    ) =>
    () => {
      firebase.analytics().logEvent(analyticsEvent);
      const { preview, ...query } = router.query;
      router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      );
    };

  const open = !!router.query.preview;

  if (mdDown) {
    return (
      <FullScreenDialog
        open={open}
        header={
          <>
            <DialogBackButton
              onClick={onClose(AnalyticsEventEnum.PostDialogCloseMobile)}
            />
            <span className="text-xl  text-gray-900">
              {t("common:postLabel")}
            </span>
            <div
              id="PostDialog-fullScreenDialogUtilityButtonContainer"
              className="absolute right-4 text-gray-300"
            />
          </>
        }
      >
        <RemoveScroll className="py-4 h-full overflow-auto">
          <DynamicPostDetail />
        </RemoveScroll>
      </FullScreenDialog>
    );
  }

  return (
    <StandardDialog
      open={open}
      className="max-w-screen-md"
      showCloseButton={true}
      onClose={onClose(AnalyticsEventEnum.PostDialogClose)}
    >
      <div className="py-4">
        <DynamicPostDetail />
      </div>
    </StandardDialog>
  );
};
