import dynamic from "next/dynamic";
import { createContext, useContext, useMemo, useReducer } from "react";

import { CreatorApplicationDialogViewProps } from "@/features/application/components/CreatorApplicationDialogView";
import { StandardDialog } from "@/features/common/components/layout/StandardDialog";
import { Eth721CancelListDialogViewProps } from "@/features/nft/components/Eth721CancelListDialogView";
import { Eth721ListDialogViewProps } from "@/features/nft/components/Eth721ListDialogView";
import { Eth721PurchaseDialogViewProps } from "@/features/nft/components/Eth721PurchaseDialogView";
import { TransactionEvent } from "@/features/nft/components/StepsDialogView";
import {
  SubscriptionPlanDialogView,
  SubscriptionPlanDialogViewProps,
} from "@/features/subscription/components/SubscriptionPlanDialogView";

const Eth721PurchaseDialogView = dynamic(
  () => import("@/features/nft/components/Eth721PurchaseDialogView"),
  { ssr: false },
);

const Eth721ListDialogView = dynamic(
  () => import("@/features/nft/components/Eth721ListDialogView"),
  { ssr: false },
);

const Eth721CancelListDialogView = dynamic(
  () => import("@/features/nft/components/Eth721CancelListDialogView"),
  { ssr: false },
);

const StepsDialogView = dynamic(
  () => import("@/features/nft/components/StepsDialogView"),
  { ssr: false },
);

const CreatorApplicationDialogView = dynamic(
  () =>
    import("@/features/application/components/CreatorApplicationDialogView"),
  { ssr: false },
);

enum DialogActionEnum {
  OpenDialog = "OPEN_DIALOG",
  CloseDialog = "CLOSE_DIALOG",
}

export enum DialogViewEnum {
  PurchaseDialog = "PURCHASE_DIALOG",
  ListDialog = "LIST_DIALOG",
  CancelListDialog = "CANCEL_LIST_DIALOG",
  StepsDialog = "STEPS_DIALOG",
  CreatorApplicationDialog = "CREATOR_APPLICATION_DIALOG",
  SensitiveContentDialog = "SENSITIVE_CONTENT_DIALOG",
  SubscriptionPlanDialog = "SUBSCRIPTION_PLAN_DIALOG",
}

/* Dialog payload */
type EthPurchaseDialogPayloadType = {
  view: DialogViewEnum.PurchaseDialog;
} & Pick<Eth721PurchaseDialogViewProps, "tokenId" | "onConfirm">;

type SubscriptionPlanDialogPayloadType = {
  view: DialogViewEnum.SubscriptionPlanDialog;
} & Omit<SubscriptionPlanDialogViewProps, "onClose">;

type CreatorApplicationDialogPayloadType = {
  view: DialogViewEnum.CreatorApplicationDialog;
} & Pick<CreatorApplicationDialogViewProps, "redirectToProfilePage">;

type EthListDialogPayloadType = {
  view: DialogViewEnum.ListDialog;
} & Pick<Eth721ListDialogViewProps, "tokenId" | "onConfirm" | "variant">;

type EthCancelListDialogPayloadType = {
  view: DialogViewEnum.CancelListDialog;
} & Pick<Eth721CancelListDialogViewProps, "onConfirm">;

type EthStepsDialogPayloadType = {
  view: DialogViewEnum.StepsDialog;
  currentStep: number;
  transactionEvents?: TransactionEvent[];
};

type OpenDialogViewPayloadType = (
  | EthPurchaseDialogPayloadType
  | EthStepsDialogPayloadType
  | EthListDialogPayloadType
  | EthCancelListDialogPayloadType
  | CreatorApplicationDialogPayloadType
  | SubscriptionPlanDialogPayloadType
) & { className?: string };

/* Reducer Action */
type DialogActionType =
  | {
      type: DialogActionEnum.CloseDialog;
    }
  | {
      type: DialogActionEnum.OpenDialog;
      payload: OpenDialogViewPayloadType;
    };

type DialogStateType = {
  open: boolean;
  view: DialogViewEnum;
  className?: string;
} & Omit<
  Partial<EthPurchaseDialogPayloadType> &
    Partial<EthListDialogPayloadType> &
    Partial<EthCancelListDialogPayloadType> &
    Partial<EthStepsDialogPayloadType> &
    Partial<CreatorApplicationDialogPayloadType> &
    Partial<SubscriptionPlanDialogPayloadType>,
  "view"
>;

const DialogReducer = (state: DialogStateType, action: DialogActionType) => {
  switch (action.type) {
    case DialogActionEnum.CloseDialog:
      return {
        ...state,
        open: false,
      };

    case DialogActionEnum.OpenDialog: {
      const { payload } = action;
      return { ...state, ...payload, open: true };
    }
    default:
      return state;
  }
};

const DialogInitialState: DialogStateType = {
  open: false,
  view: DialogViewEnum.PurchaseDialog,
};

interface DialogContextProps {
  closeDialog?: () => void;
  openDialog?: <T extends OpenDialogViewPayloadType>(payload: T) => void;
}

const DialogContext = createContext<DialogContextProps>({});
/* 
TODO:
  if some dialogs may open under a double dialog condition,
  the Dialog will have to be separated as a standalone component and provide a hierarchy for those dialogs to prevent focus trap error.
  ref: https://github.com/tailwindlabs/headlessui/issues/493#issuecomment-841165213
*/
export const DialogProvider = (props) => {
  const [dialog, dispatch] = useReducer(DialogReducer, DialogInitialState);

  const closeDialog = () => dispatch({ type: DialogActionEnum.CloseDialog });
  const openDialog = <T extends OpenDialogViewPayloadType>(payload: T) =>
    dispatch({ type: DialogActionEnum.OpenDialog, payload });

  const value = useMemo(() => {
    return { openDialog, closeDialog };
  }, []);

  const getClassName = () => {
    if (dialog.className) return dialog.className;

    if (dialog.view === DialogViewEnum.SubscriptionPlanDialog)
      return "max-w-screen-lg";
    if (dialog.view === DialogViewEnum.CreatorApplicationDialog)
      return "max-w-2xl";

    return "max-w-md";
  };

  return (
    <>
      <StandardDialog open={dialog.open} className={getClassName()}>
        {dialog.view === DialogViewEnum.PurchaseDialog && (
          <Eth721PurchaseDialogView
            tokenId={dialog.tokenId}
            onClose={closeDialog}
            onConfirm={dialog.onConfirm}
          />
        )}
        {dialog.view === DialogViewEnum.ListDialog && (
          <Eth721ListDialogView
            variant={dialog.variant}
            tokenId={dialog.tokenId}
            onClose={closeDialog}
            onConfirm={dialog.onConfirm}
          />
        )}
        {dialog.view === DialogViewEnum.CancelListDialog && (
          <Eth721CancelListDialogView
            onClose={closeDialog}
            onConfirm={dialog.onConfirm}
          />
        )}

        {dialog.view === DialogViewEnum.StepsDialog && (
          <StepsDialogView
            currentStep={dialog.currentStep}
            transactionEvents={dialog.transactionEvents}
          />
        )}
        {dialog.view === DialogViewEnum.CreatorApplicationDialog && (
          <CreatorApplicationDialogView
            onClose={closeDialog}
            redirectToProfilePage={dialog.redirectToProfilePage}
          />
        )}
        {dialog.view === DialogViewEnum.SubscriptionPlanDialog && (
          <SubscriptionPlanDialogView
            onClose={closeDialog}
            post={dialog.post}
            writer={dialog.writer}
            channel={dialog.channel}
          />
        )}
      </StandardDialog>

      <DialogContext.Provider value={value} {...props} />
    </>
  );
};

export function useDialog() {
  return useContext(DialogContext);
}
