import { useTranslation } from "next-i18next";
import { EventType } from "opensea-js";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { useSnackbar } from "@/features/common/hooks/snackbar/useSnackbar";
import {
  StepsDialog,
  StepsDialogProps,
} from "@/features/nft/components/StepsDialog";
import { useNftContext } from "@/features/nft/hooks/useNft";
import { TransactionActionTypeEnum } from "@/generated/generated-hooks";

import { getTransactionStep } from "../getTransactionStep";

type TransactionStatus = "" | "created" | "confirmed" | "denied" | "failed";

type TransactionEvent = {
  transactionHash: string;
  status: TransactionStatus;
};

interface OpenSeaPortEventContextProps {
  setActionType?: Dispatch<SetStateAction<TransactionActionTypeEnum>>;
}

const OpenSeaPortEventContext = createContext<OpenSeaPortEventContextProps>({});

/** @puropose Listen to Transactino event triggered by using opensea sdk */
export const OpenSeaPortEventProvider = (props) => {
  const { t } = useTranslation("common");
  const { openSnackbar } = useSnackbar();
  const { seaport } = useNftContext();

  const [actionType, setActionType] = useState<TransactionActionTypeEnum>(
    TransactionActionTypeEnum.Buy,
  );

  const [transaction, setTransaction] = useState<TransactionEvent>({
    transactionHash: "",
    status: "",
  });

  const [dialog, setDialog] = useState<Omit<StepsDialogProps, "onClose">>({
    open: false,
    steps: [],
    currentStep: 0,
  });

  const closeDialog = () =>
    setDialog((s) => ({
      ...s,
      open: false,
    }));

  useEffect(() => {
    if (!seaport) return;
    const commonHandler =
      (status: TransactionStatus) =>
      ({ transactionHash, event }) =>
        setTransaction({ transactionHash, status });

    const confirmHandler = ({ transactionHash, event }) => {
      // Only reset your exchange UI if we're finishing an order fulfillment or cancellation
      if (event == EventType.MatchOrders || event == EventType.CancelOrder) {
        setTransaction({ transactionHash, status: "confirmed" });
      }
    };

    seaport.addListener(EventType.TransactionCreated, commonHandler("created"));
    seaport.addListener(EventType.TransactionConfirmed, confirmHandler);
    seaport.addListener(EventType.TransactionDenied, commonHandler("denied"));
    seaport.addListener(EventType.TransactionFailed, commonHandler("failed"));

    return () => {
      seaport.removeAllListeners();
    };
  }, [seaport]);

  useEffect(() => {
    if (!transaction.transactionHash) return;

    const reset = () => {
      closeDialog();
      setTransaction({ transactionHash: "", status: "" });
    };

    const { status, transactionHash } = transaction;
    if (status === "created") {
      setDialog((s) => ({
        open: true,
        steps: [getTransactionStep({ t, transactionHash, type: actionType })],
        currentStep: 0,
      }));
      return;
    }
    if (status === "denied") {
      reset();
      return openSnackbar({
        message: t("common:nft.errors.userDeclineTransaction"),
        severity: "error",
      });
    }

    if (status === "failed") {
      reset();
      return openSnackbar({
        message: t("common:nft.errors.transactionFail"),
        severity: "error",
      });
    }

    if (status === "confirmed") {
      setDialog((s) => ({
        ...s,
        currentStep: 1,
      }));
      reset();
      return openSnackbar({
        message: t("common:nft.transactionSuccess"),
        severity: "success",
      });
    }
  }, [transaction, openSnackbar, t, actionType]);

  return (
    <>
      <StepsDialog {...dialog} onClose={closeDialog} />
      <OpenSeaPortEventContext.Provider
        value={{
          setActionType,
        }}
        {...props}
      />
    </>
  );
};

export function useOpenSeaPortEvent() {
  return useContext(OpenSeaPortEventContext);
}
