import { AvatarVariant } from "@/features/user/components";

export function convertVariantToDimensions(variant: AvatarVariant) {
  switch (variant) {
    case "mobileNavigation":
      return { height: 30, width: 30 };
    case "comment":
    case "siteTopNavigation":
      return { height: 36, width: 36 };
    case "message":
      return { height: 45, width: 45 };
    case "list":
      return { height: 50, width: 50 };
    case "postPageBasicInformation":
      return { height: 60, width: 60 };
    case "chatList":
      return { height: 65, width: 65 };
    case "followList":
      return { height: 75, width: 75 };
    case "topWriter":
      return { height: 100, width: 100 };
    case "mobileProfileAvatar":
      return { height: 110, width: 110 };
    case "normal":
      return { height: 230, width: 230 };
    case "large":
      return { height: 350, width: 350 };
    default:
      return { height: 230, width: 230 };
  }
}
