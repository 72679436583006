import { createContext, useContext, FC } from "react";

const StandardDialogContext = createContext<{
  closeDialog: () => void;
}>({
  closeDialog: () => {},
});

export const StandardDialogContextProvider: FC<{ onClose: () => void }> = (
  props,
) => {
  return (
    <StandardDialogContext.Provider value={{ closeDialog: props.onClose }}>
      {props.children}
    </StandardDialogContext.Provider>
  );
};

export const useStandardDialogContext = () => useContext(StandardDialogContext);
