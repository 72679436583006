import React, { FC } from "react";

type Porps = {
  variant?: "outlined";
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const View: FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props: Porps) => {
  return <div {...props}>{props.children}</div>;
};
