import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { DialogEnum, RouteName } from "@/consts";
import { AuthenticationDialog } from "@/features/auth/refactored/AuthenticationDialog";
import { ReportPostDialogProps } from "@/features/post/components/ReportPostDialog";

const ReportPostDialog = dynamic(
  () =>
    import("@/features/post/components/ReportPostDialog").then(
      (mod) => mod.ReportPostDialog,
    ),
  { ssr: false },
);

interface UIContextProps {
  setReportPostDialog?: React.Dispatch<
    React.SetStateAction<ReportPostDialogProps>
  >;
  openDialog?: (dialogMode: DialogEnum) => void;
  closeDialog?: () => void;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const UIContext = createContext<UIContextProps>({});

const loadReportDialog = (route: string) => {
  return (
    route === RouteName.Home ||
    route === RouteName.PostRoute ||
    route === RouteName.ChannelRoute ||
    route === RouteName.ProfileUserRoute
  );
};

export const UIContextProvider = (props) => {
  const { route } = useRouter();

  const [reportPostDialog, setReportPostDialog] =
    useState<ReportPostDialogProps>({});
  const [dialogMode, setDialogMode] = useState<DialogEnum>(null);
  const [open, setOpen] = useState<boolean>(false);
  const canLoadPostReportDialog = loadReportDialog(route);

  return (
    <UIContext.Provider
      value={{
        setReportPostDialog,
        openDialog: (dialogMode: DialogEnum) => {
          setOpen(true);
          setDialogMode(dialogMode);
        },
        closeDialog: () => {
          setOpen(false);
          setDialogMode(null);
        },
        setOpen,
      }}
    >
      {props.children}

      {canLoadPostReportDialog && (
        <ReportPostDialog
          {...reportPostDialog}
          onClose={() => setReportPostDialog((s) => ({ ...s, open: false }))}
        />
      )}

      <AuthenticationDialog
        open={
          open &&
          (dialogMode === DialogEnum.Login ||
            dialogMode === DialogEnum.Register)
        }
        dialogEnum={dialogMode}
      />
    </UIContext.Provider>
  );
};

export function useUIContext() {
  return useContext(UIContext);
}
