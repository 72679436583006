import { red } from "@material-ui/core/colors";
import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const muiThemeObject: ThemeOptions = {
  palette: {
    grey: {
      "100": "#F6F6F6",
      "200": "#E5E5E5",
      "300": "#E0E0E0",
      "400": "#C4C4C4",
      "600": "#9E9E9E",
      "700": "#F1F1F1",
      "800": "#616161",
      "900": "#828282",
    },
    primary: {
      main: "#0AB4B1",
    },
    secondary: {
      main: "#828282",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FFF",
    },
    text: {
      // #9E9E9E grey[500]
      // #E1E0E1 grey[300]
      secondary: "#9E9E9E",
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
      disableRipple: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
    MuiTab: {
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
    },
    MuiRadio: {
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: { color: "black", backgroundColor: "white" },
    },
    MuiButton: {
      root: {
        height: 30,
        fontSize: "0.875rem",
        fontWeight: 500,
        borderRadius: "2.5em",
        justifyContent: "center",
        textTransform: "none",
        lineHeight: 1.3,
      },
      outlined: {
        color: "#9E9E9E",
      },
      sizeLarge: {
        width: 185,
      },
      sizeSmall: {
        width: 120,
      },
      containedPrimary: {
        color: "#FFFFFF",
        padding: "5px 15px",
      },
      startIcon: {
        marginRight: 6,
      },
    },
    MuiChip: {
      label: {
        color: "white",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "1rem",
      },
    },
  },
};

export const muiThemeTypographyObjectZh: TypographyOptions = {
  fontFamily: "'Lato', 'Noto Sans TC', sans-serif",
};

export const muiThemeTypographyObjectEn: TypographyOptions = {
  fontFamily: "'Poppins', 'Noto Sans TC', sans-serif",
};

// Create a theme instance.
const theme = createMuiTheme(muiThemeObject);

export default theme;
