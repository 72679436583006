import { ApolloLink } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import firebase from "firebase/app";
import { GetServerSidePropsContext } from "next";

export function createIsomorphLink(ctx?: GetServerSidePropsContext<any>) {
  return authLink.concat(
    ApolloLink.split(
      (operation) => operation.getContext().batch === true,
      new BatchHttpLink({ uri: "/api/graphql", credentials: "same-origin" }),
      createUploadLink({
        uri: "/api/graphql",
        credentials: "same-origin",
      }),
    ),
  );
}

const authLink = setContext(async (_, { headers }) => {
  const token = await getCurrentUserToken();
  if (token)
    return {
      headers: {
        ...headers,
        authorization: token ?? "",
      },
    };

  return {
    headers: {
      ...headers,
    },
  };
});

const getCurrentUserToken = async () => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken();
    return token;
  } else {
    return null;
  }
};
