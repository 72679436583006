import { Dialog, Transition, TransitionEvents } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment, useRef, FC, ReactNode } from "react";

import { DialogCloseButton } from "@/features/common/components/DialogCloseButton";

export const StandardDialog: FC<{
  open: boolean;
  className?: string;
  closeButton?: ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
  initialFocus?: boolean;
  transitionEvents?: TransitionEvents;
}> = ({
  open,
  children,
  className = "max-w-md",
  onClose = () => {},
  closeButton = (
    <div className="absolute top-6 right-6 z-10">
      <DialogCloseButton onClick={onClose} />
    </div>
  ),
  showCloseButton = false,
  initialFocus = true,
  transitionEvents = {},
}) => {
  let refDiv = useRef(null);

  return (
    <Transition appear show={open} as={Fragment} {...transitionEvents}>
      <Dialog
        initialFocus={initialFocus ? refDiv : undefined}
        className="fixed inset-0 z-modal"
        onClose={onClose}
      >
        <div
          className="flex justify-center items-center h-full px-4"
          ref={refDiv}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-900 bg-opacity-80 cursor-pointer"
              onClick={onClose}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={clsx(
                "relative flex flex-col w-full my-8 text-left align-middle transition-all bg-white shadow-xl rounded-md max-h-90",
                className,
              )}
            >
              {showCloseButton ? closeButton : null}

              <div className="max-h-full overflow-auto">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
