import firebase from "firebase/app";
import dynamic from "next/dynamic";
import React, { useState } from "react";

import { AnalyticsEventEnum } from "@/consts/AnalyticsEventEnum";
import { StandardDialogContextProvider } from "@/features/common/hooks/user/useStandardDialogContext";
import {
  ChannelFragment,
  PostPagePostFragment,
  SubscriptionPlanFullFragment,
  UserBasicInfoFragment,
} from "@/generated/generated-hooks";

const ProfileSubscriptionPlanDialogView = dynamic(
  () =>
    import(
      "@/features/subscription/components/ProfileSubscriptionPlanDialogView"
    ),
  { ssr: false },
);

const ProfileSubscriptionPlanDetailDialogView = dynamic(
  () =>
    import(
      "@/features/subscription/components/ProfileSubscriptionPlanDetailDialogView"
    ),
  { ssr: false },
);

export type SubscriptionPlanDialogViewProps = {
  writer: UserBasicInfoFragment;
  channel: ChannelFragment | undefined;
  post: PostPagePostFragment | undefined;
  onClose: () => void;
};

type RelatedPostInfo = {
  postId: string | undefined;
  postMonth: string | undefined;
  canUnlockPost: boolean;
};

type DialogView = {
  view: "overall" | "detail";
  activeSubscriptionPlan?: SubscriptionPlanFullFragment;
  relatedPostInfo?: RelatedPostInfo;
};

export const SubscriptionPlanDialogView: React.FC<SubscriptionPlanDialogViewProps> =
  ({ writer, channel, onClose, post }) => {
    const [dialogView, setDialogView] = useState<DialogView>({
      view: "overall",
    });

    const onCloseWithEventLog = () => {
      firebase
        .analytics()
        .logEvent(AnalyticsEventEnum.SubscriptionPlanDialogClose, {
          postId: post?._id,
          postSlug: post?.slug,
        });

      onClose();
    };

    const onClickNextStep = (
      subscriptionPlan: SubscriptionPlanFullFragment,
      relatedPostInfo: RelatedPostInfo,
    ) => {
      firebase
        .analytics()
        .logEvent(AnalyticsEventEnum.SubscriptionPlanDialogNext, {
          postId: post?._id,
          postSlug: post?.slug,
        });

      setDialogView((s) => ({
        ...s,
        view: "detail",
        activeSubscriptionPlan: subscriptionPlan,
        relatedPostInfo,
      }));
    };

    const onClickBack = () => {
      firebase
        .analytics()
        .logEvent(AnalyticsEventEnum.SubscriptionPlanDialogBack, {
          postId: post?._id,
          postSlug: post?.slug,
        });

      setDialogView((s) => ({
        ...s,
        view: "overall",
        activeSubscriptionPlan: undefined,
        relatedPostInfo: undefined,
      }));
    };

    if (dialogView.view === "detail") {
      return (
        <StandardDialogContextProvider onClose={onCloseWithEventLog}>
          <ProfileSubscriptionPlanDetailDialogView
            postId={dialogView.relatedPostInfo.postId}
            postMonth={dialogView.relatedPostInfo.postMonth}
            canUnlockPost={dialogView.relatedPostInfo.canUnlockPost}
            subscriptionPlan={dialogView.activeSubscriptionPlan}
            onClickBack={onClickBack}
          />
        </StandardDialogContextProvider>
      );
    }

    return (
      <ProfileSubscriptionPlanDialogView
        post={post}
        writer={writer}
        currentChannel={post?.channel ?? channel}
        onClose={onCloseWithEventLog}
        onClickNextStep={onClickNextStep}
      />
    );
  };
