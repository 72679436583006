import { Trans, useTranslation } from "next-i18next";
import { OpenSeaPort } from "opensea-js/lib/seaport";
import { Network } from "opensea-js/lib/types";
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Eth } from "web3-eth/types";

import { StandardConfirmDialogView } from "@/features/common/components/layout/StandardConfirmDialogView";
import { StandardDialog } from "@/features/common/components/layout/StandardDialog";
import TopReminderBanner, {
  TopReminderBannerType,
} from "@/features/common/components/tailwind/TopReminderBanner";
import { useFeatureEnabled } from "@/features/common/hooks/useFeatureEnabled";
import { useMetaMaskWallet } from "@/features/nft/hooks/useMetaMaskWallet";
import { useUserContext } from "@/features/user/contexts/useUserContext";
import { FeatureNameEnum } from "@/generated/generated-hooks";
import { getPublicConfig } from "@/helpers/getPublicConfig";

interface NftContextProps {
  isMetaMaskInstalled?: boolean;
  connectOrOnBoard?: () => void;
  isSameNetwork?: boolean;
  seaport?: OpenSeaPort;
  isSameWallet?: boolean;
  activeAccount?: string;
  eth?: Eth;
  isAccountRequested?: boolean;
}

const NftContext = createContext<NftContextProps>({});

export function NftProvider(props) {
  const {
    service: { infuraNetwork },
  } = getPublicConfig();
  const [seaport, setSeaport] = useState(null);

  const {
    activeAccount,
    isSameNetwork,
    isAccountRequested,
    loading,
    isMetaMaskInstalled,
    installMetaMask,
    connectMetaMask,
  } = useMetaMaskWallet();

  const [eth, setEth] = useState<Eth | null>(null);

  const { t } = useTranslation("common");
  const { user: currentUser } = useUserContext();
  const { isProduction } = getPublicConfig();
  const siteNetwork = isProduction
    ? t("common:nft.mainnet")
    : t("common:nft.testnet");
  const { isEnabled } = useFeatureEnabled();
  const isNftEnabled = isEnabled(FeatureNameEnum.Nft);

  /* dialog */
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
  });

  /* only this function will be exposed */
  const connectOrOnBoard = () => {
    if (!isMetaMaskInstalled) {
      setDialog({
        open: true,
        title: t("common:nft.onboardTitle"),
        message: t("common:nft.onboardMessage"),
        onConfirm: installMetaMask,
      });
      return;
    }
    if (!activeAccount) {
      setDialog({
        open: true,
        title: t("common:nft.connectTitle"),
        message: t("common:nft.connectMessage"),
        onConfirm: connectMetaMask(() =>
          setDialog((s) => ({ ...s, open: false })),
        ),
      });
      return;
    }
  };

  const onClose = () => setDialog((s) => ({ ...s, open: false }));

  //   // if the current detected account matches with curentUser's walletAccount --> set isSameWallet true
  const isSameWallet = useMemo(() => {
    return (
      isSameNetwork &&
      activeAccount &&
      currentUser &&
      currentUser.walletAccount &&
      activeAccount === currentUser.walletAccount
    );
  }, [activeAccount, currentUser, isSameNetwork]);

  /* eth provider */
  useEffect(() => {
    const initEthInstance = async () => {
      const Eth = (await import("web3-eth")).default as any;

      const provider =
        Eth.givenProvider ?? new Eth.providers.WebsocketProvider(infuraNetwork);
      const eth = new Eth(provider);
      setEth(eth);
    };

    initEthInstance();
  }, [seaport, infuraNetwork]);

  /* opensea */
  useEffect(() => {
    if (!!seaport || !eth?.currentProvider) return;

    const getNetworkName = () => {
      const { isProduction } = getPublicConfig();
      return isProduction ? Network.Main : Network.Rinkeby;
    };

    const initOpenSea = async () => {
      const networkName = getNetworkName();
      const OpenSeaPort = (await import("opensea-js")).OpenSeaPort;
      const openSea = new OpenSeaPort(eth.currentProvider, {
        networkName,
      });
      setSeaport(openSea);
    };
    initOpenSea();
  }, [seaport, infuraNetwork, eth?.currentProvider]);

  // create errorMessages list
  const errorMessages: ReactNode[] = useMemo(() => {
    let msgs: ReactNode[] = [];
    if (!activeAccount || !currentUser) return [];
    if (!isSameNetwork && siteNetwork) {
      msgs.push(t("common:nft.networkMismatch", { network: siteNetwork }));
    }

    /* wallet mismatch, account used by others and no wallet record found for cuurent user */
    if (!isSameWallet && !currentUser?.walletAccount) {
      msgs.push(t("common:nft.walletUsedByOthers"));
    }

    if (!isSameWallet && !!currentUser?.walletAccount) {
      msgs.push(
        <Trans
          i18nKey="common:nft.walletMismatch"
          values={{ walletAccount: currentUser.walletAccount }}
          components={{
            truncate: <div className="truncate" />,
          }}
        />,
      );
    }
    return msgs;
  }, [currentUser, isSameNetwork, isSameWallet, siteNetwork, t, activeAccount]);

  return (
    <>
      <StandardDialog open={dialog.open} className="max-w-screen-sm">
        <StandardConfirmDialogView
          className="p-10"
          title={dialog.title}
          message={dialog.message}
          loading={loading}
          onConfirm={dialog.onConfirm}
          onCancel={onClose}
        />
      </StandardDialog>

      {isNftEnabled && <NFTErrorBanner errorMessages={errorMessages} />}
      <NftContext.Provider
        value={{
          eth,
          isMetaMaskInstalled,
          connectOrOnBoard,
          activeAccount,
          isSameNetwork,
          seaport,
          isSameWallet,
          isAccountRequested,
        }}
        {...props}
      />
    </>
  );
}
export function useNftContext() {
  return useContext(NftContext);
}

const NFTErrorBanner: FC<{ errorMessages: ReactNode[] }> = (props) => {
  const { t } = useTranslation("common");
  const { errorMessages } = props;
  if (errorMessages.length === 0) return null;

  return (
    <TopReminderBanner
      header={t("common:nft.errorBannerHeader", {
        errorNum: errorMessages.length,
      })}
      type={TopReminderBannerType.warning}
      listItems={errorMessages}
    />
  );
};
