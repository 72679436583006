import add from "date-fns/add";
import format from "date-fns/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import getDay from "date-fns/getDay";
import isThisWeek from "date-fns/isThisWeek";
import isThisYear from "date-fns/isThisYear";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import enUS from "date-fns/locale/en-US";
import zhTW from "date-fns/locale/zh-TW";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";

import { Optional } from "@/features/common/type";
import { useUserContext } from "@/features/user/contexts/useUserContext";
import { utcToTargetZonedTime } from "@/helpers/common/dateTimeHelper";
import { formatDistanceEnUs } from "@/helpers/date/formatDistanceEnUs";
import { formatDistanceZhTw } from "@/helpers/date/formatDistanceZhTw";
import { setLocaleCookie } from "@/helpers/user/locale/localeCookies";

import { FormatArgs, FormatDistanceToNowStrictArgs } from "./type";

const locales = { zh: zhTW, en: enUS };
const customFormatDistance = { zh: formatDistanceZhTw, en: formatDistanceEnUs };
type FormatStrKey = "sameWeek" | "sameYear" | "differentYear";
type OptionalFormatStrKey = "yesterday";
type FormatStrMap = Record<FormatStrKey, string> &
  Optional<Record<OptionalFormatStrKey, string>> & { today?: string };

interface I18NProviderProps {
  formatWithLocale: null | ((args: FormatArgs) => string);
  formatDistanceToNowStrictWithLocale:
    | null
    | ((args: FormatDistanceToNowStrictArgs) => string);
  getChatDateTimeString:
    | null
    | ((date: Date, formatStrMap: FormatStrMap) => string);
  dateFnsLocale: Locale;
  ready: boolean;
}
const I18NContext = createContext<I18NProviderProps>({
  formatWithLocale: null,
  formatDistanceToNowStrictWithLocale: null,
  getChatDateTimeString: null,
  dateFnsLocale: zhTW,
  ready: false,
});

export const I18NProvider: React.FC = (props) => {
  const { user } = useUserContext();
  const { locale, push, asPath } = useRouter();
  // const [currentLocale, setCurrentLocale] = useState<string>(
  //   user.locale ? user.locale : locale
  // );

  const dateFnsLocale = locales[locale];

  const formatWithLocale = (args: FormatArgs) => {
    const { date, formatStr = "PP", options, toUtc = true } = args;
    const targetDate = toUtc ? utcToTargetZonedTime(date) : date;
    return format(targetDate, formatStr, {
      ...options,
      locale: dateFnsLocale,
    });
  };

  /* if short 1m, 1d etc, use custom formatDistance to replace the original one */
  const formatDistanceToNowStrictWithLocale = (
    args: FormatDistanceToNowStrictArgs,
  ) => {
    const { date, options, short } = args;
    if (!short)
      return formatDistanceToNowStrict(date, {
        ...options,
        locale: dateFnsLocale,
      });
    const str = formatDistanceToNowStrict(date, {
      ...options,
      locale: {
        ...dateFnsLocale,
        formatDistance: customFormatDistance[locale],
      },
    });
    return str;
  };

  const getChatDateTimeString = (date: Date, formatStrMap: FormatStrMap) => {
    const { sameWeek, sameYear, differentYear, yesterday, today } =
      formatStrMap;
    const isTodayMsg = isToday(date);

    if (today && isTodayMsg) return today;

    if (isTodayMsg)
      return formatDistanceToNowStrictWithLocale({ date, short: true });

    if (yesterday && isYesterday(date)) return yesterday;

    const isThisWeekMsg = isThisWeek(date, {
      weekStartsOn: getWeekStartsOn(),
    });
    const isThisYearMsg = isThisYear(date);

    const formatStr = isThisWeekMsg
      ? sameWeek
      : isThisYearMsg
      ? sameYear
      : differentYear;

    return formatWithLocale({
      toUtc: false,
      date,
      formatStr,
    });
  };

  useEffect(() => {
    if (!user?.locale) return;
    const userLocale = user?.locale.toLocaleLowerCase();
    if (userLocale === locale) return;
    setLocaleCookie(userLocale);
    push(asPath, asPath, { locale: userLocale });
  }, [locale, user?.locale, asPath, push]);

  const ready =
    !!formatWithLocale &&
    !!formatDistanceToNowStrictWithLocale &&
    !!getChatDateTimeString;

  return (
    <I18NContext.Provider
      value={{
        formatWithLocale,
        formatDistanceToNowStrictWithLocale,
        getChatDateTimeString,
        dateFnsLocale,
        ready,
      }}
    >
      {props.children}
    </I18NContext.Provider>
  );
};

const getWeekStartsOn = () => {
  return getDay(add(new Date(), { days: 1 }));
};

export const useI18NContext = () => useContext(I18NContext);
