import { FieldMergeFunction } from "@apollo/client";

import { CursorPagingInfo } from "@/generated/generated-hooks";

type CursorBasedData = {
  items: any[];
  paging: CursorPagingInfo;
};

export const cursorMergeFn =
  <T extends CursorBasedData>(): FieldMergeFunction<T> =>
  (existing, incoming, { args }) => {
    const existingItems = existing?.items ?? [];
    const incomingItems = incoming?.items ?? [];

    const mergedItems = [...existingItems, ...incomingItems];

    return { ...incoming, items: mergedItems };
  };
