import { parse } from "cookie";
import cookies from "js-cookie";

import { UserInCookie } from "./type";

const TOKEN_NAME = "auth";
const COOKIE_EXPIRY_DAYS = 1;

export const getUserFromCookie = (): UserInCookie => {
  const cookie = cookies.get(TOKEN_NAME);
  if (!cookie) {
    return;
  }
  return JSON.parse(cookie);
};

export const setUserCookie = (user: UserInCookie) => {
  const expires = user.expiryDate ?? COOKIE_EXPIRY_DAYS;

  cookies.set(TOKEN_NAME, user, {
    // firebase id tokens expire in one hour
    // set cookie expiry to match
    expires,
  });
};

export const removeUserCookie = () => cookies.remove(TOKEN_NAME);

/**
 * @descritions functions for server
 */
export function parseCookies(req) {
  // For API Routes we don't need to parse the cookies.
  if (req.cookies) return req.cookies;

  // For pages we do need to parse the cookies.
  const cookie = req.headers?.cookie;
  return parse(cookie || "");
}

export function getTokenCookie(req) {
  const cookies = parseCookies(req);
  return cookies[TOKEN_NAME];
}

export function getLoginSession(req) {
  if (!req) return { token: null, ssrUserId: null };
  const token = getTokenCookie(req);
  if (!token) return { token: null, ssrUserId: null };

  const tmp = JSON.parse(token);
  return { token: tmp?.token, ssrUserId: tmp?.id };

  // Validate the expiration date of the session
  // if (Date.now() < expiresAt) {
  //   return session;
  // }
}
